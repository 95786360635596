/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { WppButton, Icon, WppGrid } from 'buildingBlocks';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { DEFAULT_MIN_ALLOCATION, DEFAULT_MAX_ALLOCATION, DEFAULT_GROUP_CONFIG, DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_CONFIG, DEFAULT_PG_GROUP_ID, BudgetOptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationResponse, BudgetGroup, GroupSettings as GroupSettingsType, TooltipsDescriptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { useAsyncEffect } from 'utils/functionHelpers';
import { User } from 'utils/types';
import BudgetGroups from './BudgetGroups';
import { BUDGET_MANAGEMENT_STYLES } from './style';
import { getFlightStates, getAllNonAttachedLineItems, getBudgetParentData, filterForValidFlights, useBudgetGroupOptionsFetcher, getAttachedLineItems, getAllBudgetGroupLineItems } from './utils';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type GroupSettingsProps = {
  budgetAllocationState: BudgetAllocationResponse
  tooltips: TooltipsDescriptions
  hasChildFlightsData: boolean
};

const GroupSettings = ({
  budgetAllocationState,
  tooltips,
  hasChildFlightsData,
}: GroupSettingsProps) => {
  const {
    wizardFormValues: {
      attachFlightsStep,
      goalSelectionStep,
      strategyTypeSelectionStep: {
        strategyType: {
          id: strategyTypeId,
        },
      },
      strategyConfigurationStep,
      strategyConfirmationStep,
    },
  } = useStrategyWizardContext();
  const user = useSelector<GlobalState>((state) => state.login.user) as User;

  const { setValue, trigger, control } = useFormContext<StrategyConfigurationStep>();
  const formValues = _.get(strategyConfigurationStep, 'groupSettings', {});
  const budgetGroups = useWatch({ name: 'groupSettings', defaultValue: formValues, control }) as GroupSettingsType;
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });

  let updatedBudgetGroupsState = { ...budgetGroups };
  const budgetData = _.get(budgetAllocationState, 'data');
  const attachedFlights = _.get(attachFlightsStep, 'attachedFlights');
  const flightStates = getFlightStates(attachFlightsStep);
  const { validFlights: flightsToPopulate, invalidFlights } = flightStates;
  // groupedOptions to render FormOptions grouped by DSP
  const {
    groupedOptions,
    allActiveChildOptions,
  } = useBudgetGroupOptionsFetcher(budgetData, flightsToPopulate, flightStates);

  const isCrossPlatformOptimization = isCrossPlatformStrategyType(strategyTypeId);
  const hasNMSPermission = checkPermissions(user, Permission.nmsConsolidatedIo);

  const defaultCurrency = _.get(attachFlightsStep, 'defaultCurrency');
  const {
    lifeTimeBudget,
    requiredDailyValue,
    interval,
    remainingSpendDays,
    flightExtType,
    flightTimezone,
  } = getBudgetParentData(isCrossPlatformOptimization, goalSelectionStep, budgetData, attachedFlights);

  const filteredGroupSettings = _.omit(updatedBudgetGroupsState, [DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID]);
  const budget = _.get(goalSelectionStep, 'budget');

  const attachedLineItemsCount = _.reduce(filteredGroupSettings, (sum: number, group: BudgetGroup) => {
    // using intersection to handle possible clones for singleDSP flow
    // eslint-disable-next-line no-param-reassign
    sum += _.size(_.intersection(group.childExtIds, _.map(allActiveChildOptions, 'extId')));
    return sum;
  }, 0);

  const nonAttachedLineItemsCount = _.size(allActiveChildOptions) - attachedLineItemsCount >= 0
    ? _.size(allActiveChildOptions) - attachedLineItemsCount
    : 0;

  const addBudgetGroup = () => {
    // handles edge case of when user goes to step 5 and goes back to step 4 to add/remove budgetGroups
    const budgetGroupId = _.size(filteredGroupSettings)
    // eslint-disable-next-line newline-per-chained-call
      ? _.chain(filteredGroupSettings).keys().last().toNumber().value() + 1
      : _.size(filteredGroupSettings) + 1;
    const newBudgetGroup = {
      groupName: `Untitled Group ${budgetGroupId}`,
      min: DEFAULT_MIN_ALLOCATION,
      max: DEFAULT_MAX_ALLOCATION,
      childExtIds: [],
      budgetOptimization: true,
      allocationStrategy: null,
    };
    setValue('groupSettings', { ...budgetGroups, [budgetGroupId]: { ...newBudgetGroup } });
  };

  useAsyncEffect(async () => {
    // remove invalid flights on initial load
    const cleanUpForm = async () => {
      const budgetGroupsClone = { ...updatedBudgetGroupsState };
      // don't want to be adding defaultGroups into groupSettings if there aren't any budgetGroups
      if (_.size(budgetGroupsClone)) {
        const { nonAttachedReg: nonAttachedRegLineItemExtIds, nonAttachedPG: nonAttachedPGExtIds } = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);
        _.forEach(budgetGroupsClone, (group: BudgetGroup, groupId: string) => {
          let validFlights = filterForValidFlights(group.childExtIds, invalidFlights, allActiveChildOptions);
          if (groupId === DEFAULT_GROUP_ID) {
            validFlights = nonAttachedRegLineItemExtIds;
          }
          if (groupId === DEFAULT_PG_GROUP_ID) {
            validFlights = nonAttachedPGExtIds;
          }
          group.childExtIds = validFlights;
          // re/calculate aggDelivery if it doesn't exist initially, to turn event pacing aggDelivery to currency if revType enabled,
          // or include clone spend if intelligentChildObjects is enabled
          if ((!group.aggDelivery || !!_.size(budget) || intelligentChildObjectsEnabled) && groupId !== DEFAULT_GROUP_ID && groupId !== DEFAULT_PG_GROUP_ID) {
            const groupLineItems = getAttachedLineItems(group.childExtIds, allActiveChildOptions);
            const groupLIsToCalculateDelivery = intelligentChildObjectsEnabled
              ? getAllBudgetGroupLineItems(attachedFlights, groupLineItems, budgetData)
              : groupLineItems;
            const deliveryObj = getTotalDelivered(budgetData, [interval], budget, groupLIsToCalculateDelivery);
            const delivery = _.head(_.values(deliveryObj)) ?? 0;
            group.aggDelivery = group.budgetOptimization ? delivery : 0;
            const hasPGLineAttached = _.some(groupLineItems, 'isProgrammaticGuaranteed');
            // incase older strategies had PG lines attached, we reset what budgetOptimization should be
            group.budgetOptimization = hasPGLineAttached ? false : group.budgetOptimization;
            group.allocationStrategy = hasPGLineAttached ? BudgetOptions.ignore.value : _.get(group, 'allocationStrategy', null);
          }
        });

        const newGroupSettings = _.pickBy(budgetGroupsClone, (group) => _.size(group.childExtIds));
        // update form with defaultGroup when user doesn't create a budget group and syncs defaultGroup when flights change
        const updatedGroupSettings = {
          ...newGroupSettings,
          ...(_.size(nonAttachedRegLineItemExtIds) && {
            [DEFAULT_GROUP_ID]: {
              ...DEFAULT_GROUP_CONFIG,
              childExtIds: nonAttachedRegLineItemExtIds,
            } }),
          ...((_.size(nonAttachedPGExtIds) && hasNMSPermission) && {
            [DEFAULT_PG_GROUP_ID]: {
              ...DEFAULT_PG_GROUP_CONFIG,
              childExtIds: nonAttachedPGExtIds,
            },
          }),
        };
        // handle issue with budgetGroups not being in sync with updatedGroupSettings
        updatedBudgetGroupsState = updatedGroupSettings;
        await setValue('groupSettings', { ...updatedGroupSettings });
      }
    };
    await cleanUpForm();
  }, [budgetAllocationState]);

  useEffect(() => {
    if (_.size(filteredGroupSettings)) {
      const { nonAttachedReg, nonAttachedPG } = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);
      if (hasNMSPermission) {
        let groupSettingsToUpdate = { ...updatedBudgetGroupsState };
        if (_.size(nonAttachedReg)) {
          // creates defaultGroup as long as there are line items that haven't been attached to a budget group
          groupSettingsToUpdate = {
            ...groupSettingsToUpdate,
            [DEFAULT_GROUP_ID]: { ...DEFAULT_GROUP_CONFIG, childExtIds: nonAttachedReg },
          };
        } else {
          groupSettingsToUpdate = _.omit(groupSettingsToUpdate, DEFAULT_GROUP_ID);
        }
        if (_.size(nonAttachedPG)) {
          groupSettingsToUpdate = {
            ...groupSettingsToUpdate,
            [DEFAULT_PG_GROUP_ID]: { ...DEFAULT_PG_GROUP_CONFIG, childExtIds: nonAttachedPG },
          };
        } else {
          groupSettingsToUpdate = _.omit(groupSettingsToUpdate, DEFAULT_PG_GROUP_ID);
        }
        setValue('groupSettings', { ...groupSettingsToUpdate });
      } else {
        // eslint-disable-next-line no-lonely-if
        if (nonAttachedLineItemsCount) {
          // if users don't have NMS permission, PG lines become a part of defaultGroup
          const updatedGroupSettings = {
            ...updatedBudgetGroupsState,
            defaultGroup: { ...DEFAULT_GROUP_CONFIG, childExtIds: _.concat(nonAttachedReg, nonAttachedPG) },
          };
          setValue('groupSettings', updatedGroupSettings);
        } else {
          // removes defaultGroup when there are NO stray line items
          setValue('groupSettings', _.omit(updatedBudgetGroupsState, DEFAULT_GROUP_ID));
        }
      }
    } else {
      // ensure groupSettings is empty if there are no budgetGroups added
      setValue('groupSettings', {});
    }
  }, [nonAttachedLineItemsCount]);

  useEffect(() => {
    // handles validation for when user removes budget group
    trigger('groupSettings');
  }, [budgetGroups]);

  return (
    <WppGrid container fullWidth item all={24} style={budgetGroupStyle.bugdetGroupGridStyle}>
      <BudgetGroups
        groupedOptions={groupedOptions}
        lifeTimeBudget={lifeTimeBudget}
        requiredDailyValue={requiredDailyValue}
        tooltips={tooltips}
        interval={interval}
        strategyName={strategyConfirmationStep?.name}
        isCrossPlatformOptimization={isCrossPlatformOptimization}
        defaultCurrency={defaultCurrency}
        remainingSpendDays={remainingSpendDays}
        allActiveChildOptions={allActiveChildOptions}
        budgetGroups={budgetGroups}
        flightExtType={flightExtType}
        flightTimezone={flightTimezone}
        hasNMSPermission={hasNMSPermission}
      />
      <WppGrid container item all={24} fullWidth style={budgetGroupStyle.btnErrorContainer}>
        {(!hasChildFlightsData)
          ? (<ErrorMessage errorText="No Active Line Items" />)
          : (
            <WppButton size="s" variant="secondary" onClick={addBudgetGroup}>
              <Icon name="plus" />
              Add Budget Management Group
            </WppButton>
          )}
      </WppGrid>
    </WppGrid>
  );
};

export default GroupSettings;
