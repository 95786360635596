import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

const FLIGHT_SYNC_STYLES: { [key: string]: CSSProperties } = {
  labelStyles: {
    marginBottom: SPACING[8],
  },
  idFieldForm: {
    width: 'inherit',
  },
  idField: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    fontFamily: 'Gilroy',
    border: '1px solid rgba(34,36,38,.15)',
    borderRadius: 4,
  },
  uploadMessage: {
    margin: '4px 0px 8px 2px',
  },
  submitBtn: {
    marginTop: SPACING[8],
  },
  syncButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default FLIGHT_SYNC_STYLES;
