import _ from 'lodash';
import { sanitize } from 'dompurify';
import moment from 'moment';
import { RESULTS_LIMIT, SELECT_ALL_OPTION } from 'constantsBase';
import { AlgorithmType, Message, MessageContext } from 'utils/copilotAPI';
import { formatAlgoTypeName } from 'utils/hooks/useAlgoTypeFetcher';
import {
  SUBMIT_FORM,
  SUBMIT_FORM_COMPLETED,
  SUBMIT_FORM_FAILED,
  FETCH_MESSAGE,
  FETCH_MESSAGE_COMPLETED,
  FETCH_MESSAGE_FAILED,
  CLEAR_FORM_STATE,
  PRIORITIES,
  FORM_FIELDS,
  ALGORITHM_TYPE,
  ADMIN_MESSAGE,
} from './constants';
import { getAlgoTypeIds, getPublishOption, getUTCDateTime } from './utils';
import { SubmitFunctionParams } from './types';

const fetchMessageCompleted = (loadedMessageData) => ({
  type: FETCH_MESSAGE_COMPLETED,
  payload: loadedMessageData,
});

export const fetchMessageFailed = (fetchErrorMsg) => ({
  type: FETCH_MESSAGE_FAILED,
  payload: fetchErrorMsg,
});

export const clearFormState = () => ({
  type: CLEAR_FORM_STATE,
});

const getMessageById = async (id) => {
  const msgCxtResp = await MessageContext.get({ where: { message: id, limit: RESULTS_LIMIT }, populate: ['message'] });
  const msgCxt = _.head(msgCxtResp.data) as any;
  const algoTypeIds = new Set(_.compact(_.split(msgCxt.contextId, ',')));
  let filteredAlgoTypes = [];
  const startDateToUse = msgCxt.message.startDate ? moment(msgCxt.message.startDate).utc() : moment(msgCxt.message.createdAt);
  const endDateToUse = msgCxt.message.endDate ? moment(msgCxt.message.endDate).utc() : null;

  if (_.size(algoTypeIds)) {
    const where = { active: 1 };
    const sort = 'id ASC';
    const allAlgoTypes = await AlgorithmType.get({ where, limit: RESULTS_LIMIT, sort });
    const formattedAlgoTypes = _.map(allAlgoTypes.data, (aT) => ({ ...aT, name: formatAlgoTypeName(aT) }));
    filteredAlgoTypes = _.filter(formattedAlgoTypes, (aT) => algoTypeIds.has(_.toString(aT.id)));
  }

  const forUi = {
    ...msgCxt.message,
    algoTypes: filteredAlgoTypes,
    startDate: startDateToUse,
    startTime: startDateToUse,
    endDate: endDateToUse,
    hasEndDate: !!msgCxt.message.endDate,
    priority: _.find(PRIORITIES, ['value', msgCxt.message.priority]) ?? {},
    publishOption: getPublishOption(msgCxt.createdAt, msgCxt.message.startDate),
  };
  return { ..._.pick(forUi, FORM_FIELDS), messageContextId: msgCxt.id };
};

export const fetchMessage = async (id, dispatch) => {
  dispatch({ type: FETCH_MESSAGE });
  try {
    const msg = await getMessageById(id);
    dispatch(fetchMessageCompleted(msg));
  } catch (e) {
    dispatch(fetchMessageFailed(e.message));
  }
};

export const submitFormCompleted = (saved) => ({
  type: SUBMIT_FORM_COMPLETED,
  payload: saved,
});

export const submitFormFailed = (msg) => ({
  type: SUBMIT_FORM_FAILED,
  payload: msg,
});

export const submit = async ({
  formValues,
  msgId,
  messageContextId,
  userId,
  prevAlgoTypes,
  navigate,
  dispatch,
  handleModalClose,
}: SubmitFunctionParams): Promise<void> => {
  dispatch({ type: SUBMIT_FORM });
  try {
    const { algoTypes, startDate, startTime, hasEndDate, endDate, subject, content, priority } = formValues;
    const startDateToUse = getUTCDateTime(startDate, startTime);
    const endDateToUse = hasEndDate ? moment(endDate).utc().toISOString() : null;
    const cleanSubject = sanitize(subject);
    const cleanContent = sanitize(content);

    const hasSelectedAll = _.find(algoTypes, ['name', SELECT_ALL_OPTION.name]) !== undefined;

    const algoTypeUpdated = !_.isEqual(algoTypes, prevAlgoTypes);
    const algoTypesToUse = getAlgoTypeIds(hasSelectedAll, algoTypes);

    if (msgId && algoTypeUpdated) {
      const updatedRecord = {
        contextId: algoTypesToUse,
        contextType: ALGORITHM_TYPE,
      };
      await MessageContext.put(messageContextId, updatedRecord);
    }

    const valuesToOmit = ['startTime', 'endTime', 'hasEndDate', 'priority', 'algoTypes', 'publishOption'];

    const postBody = {
      message: {
        ..._.omit(formValues, valuesToOmit),
        priority: priority.value,
        subject: cleanSubject,
        content: cleanContent,
        startDate: startDateToUse,
        endDate: endDateToUse,
        createdBy: userId,
        updatedBy: userId,
        category: ADMIN_MESSAGE,
      },
      contextType: ALGORITHM_TYPE,
      contextId: algoTypesToUse,
    };

    const savedContext = msgId
      ? await Message.put(msgId.toString(), postBody.message)
      : await MessageContext.post(postBody);

    dispatch(submitFormCompleted({ ...postBody.message, id: savedContext.data.id }));
    handleModalClose();
    navigate('/messages');
  } catch (e) {
    console.log({ e });
    dispatch(submitFormFailed(e.message));
    handleModalClose();
    navigate('/messages');
  }
};
