import _ from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dimmer, Loader } from 'buildingBlocks';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { SegmentRecencyForm } from 'containers/StrategyWizard/types';
import { assertUnreachable, SegmentGroup } from 'utils/types';
import { useInitialSRDataFetcher, CurrentSegRecState } from './hooks';
import SRForm from './components/SRForm';

export type SegmentRecencyProps = {
  strategyId: number
  initialValues: { startingMaxBid: number, startingMinBid: number, maxDays: number, segmentGroups: Array<SegmentGroup> },
};

export const SegmentRecency = (props: SegmentRecencyProps) => {
  const { strategyId, initialValues } = props;
  const {
    wizardFormValues: {
      attachFlightsStep: { member, attachedFlights },
      goalSelectionStep: { goal },
      strategyTypeSelectionStep: { strategyType },
    },
  } = useStrategyWizardContext();

  const { setValue } = useFormContext<SegmentRecencyForm>();

  const srState = useInitialSRDataFetcher(initialValues.segmentGroups, member, attachedFlights, strategyType, strategyId, setValue, goal.type);
  const [count, setCount] = useState<number>(_.size(initialValues.segmentGroups) - 1);

  switch (srState.kind) {
    case CurrentSegRecState.initial:
    case CurrentSegRecState.loading:
      return (
        <Dimmer active page>
          <Loader>Segment Recency module is loading.</Loader>
        </Dimmer>
      );
    case CurrentSegRecState.hasData: {
      const { segments, attachedLIs } = srState.data;
      return (
        <SRForm
          segments={segments}
          attachedLIs={attachedLIs}
          strategyId={strategyId}
          count={count}
          setCount={setCount}
        />
      );
    }
    case CurrentSegRecState.error:
      throw new Error(srState.error);
    default:
      assertUnreachable(srState);
  }
  return null;
};

export default SegmentRecency;
