import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { USER_LANDING_LINK } from '../constants';
import { sideModalFooter } from '../style';

type Props = {
  disableSave: boolean
  onUserProfile?: boolean
  isNewUser?: boolean
};

const Footer = ({ disableSave, onUserProfile, isNewUser }: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <WppButton
        variant="primary"
        type="submit"
        disabled={disableSave}
        id="user-detail-save-btn"
      >
        {isNewUser ? 'Save and Request Access' : 'Save'}
      </WppButton>
      <WppModal open={openModal}>
        <h3 slot="header">Unsaved Changes</h3>
        <WppTypography tag="p" type="s-body" slot="body">Are you sure you want to leave without saving? Your changes will not be saved.</WppTypography>
        <div slot="actions" style={sideModalFooter}>
          <Link to={onUserProfile ? '/' : USER_LANDING_LINK}>
            <WppButton variant="secondary" size="s" onClick={handleCloseModal}>Leave Without Saving</WppButton>
          </Link>
          <WppButton variant="primary" size="s" onClick={handleCloseModal}>Continue Editing</WppButton>
        </div>
      </WppModal>
      <WppButton
        variant="secondary"
        id="user-detail-cancel-btn"
        onClick={handleOpenModal}
      >
        Cancel
      </WppButton>
    </>
  );
};

export default Footer;
