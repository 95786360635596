import React from 'react';
import { Icon, WppGrid, WppInlineMessage } from 'buildingBlocks';
import { ApproverValidationMode, Mode } from '../types';
import { bottomPadding } from '../style';

export const EmailStatusMessage = ({ sendEmailErrorMsg }: { sendEmailErrorMsg: string }) => (
  <WppGrid container fullWidth style={bottomPadding}>
    <WppGrid item all={12}>
      {sendEmailErrorMsg
        ? <WppInlineMessage size="s" message={sendEmailErrorMsg} type="warning" />
        : <WppInlineMessage size="s" message="Success! Your manager will be notified." type="success" />}
    </WppGrid>
  </WppGrid>
);

type ValidationMessageProps = {
  validatingApprover: boolean
  validationMode: ApproverValidationMode
  mode: Mode
};

export const ValidationMessage = ({ validatingApprover, validationMode, mode }: ValidationMessageProps) => {
  if (mode === Mode.view) {
    return null;
  }
  if (validatingApprover) {
    return (<Icon loading name="spinner" />);
  }
  if (validationMode === ApproverValidationMode.valid) {
    return (<Icon name="check" color="green" />);
  }
  if (validationMode === ApproverValidationMode.invalid) {
    return (<Icon name="close" color="red" />);
  }
  return null;
};

type EmailValidationMessageProps = {
  email: string
  validatingEmail: boolean
  emailValidated: boolean
  mode: Mode
};

export const EmailValidationMessage = ({ email, validatingEmail, emailValidated, mode }: EmailValidationMessageProps) => {
  if (mode !== Mode.create || !email) {
    return null;
  }
  if (validatingEmail) {
    return (<Icon loading name="spinner" />);
  }
  if (emailValidated) {
    return (<Icon name="check" color="green" />);
  }
  return (<Icon name="close" color="red" />);
};
