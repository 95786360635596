import { Dispatch } from 'redux';
import { NavigateFunction } from 'react-router';
import { PossibleMoment } from 'utils/dateTime';
import { AlgorithmType } from 'utils/types';

export enum PublishOptions {
  immediately,
  oneHour,
  custom,
}

type SelectAllOption = {
  name: string
  ids?: Array<number>
};

export type MessageAlgoType = AlgorithmType | SelectAllOption;

export type MessageFormObj = {
  algoTypes: Array<MessageAlgoType>
  subject: string
  content: string
  startDate: PossibleMoment
  startTime: string
  hasExpirationDate: boolean
  isDismissible: boolean
  priority: Priority | {}
  publishOption: PublishOptions
  endDate?: PossibleMoment
};

export type Option = {
  text: string
  value: string
};

export type Priority = {
  name: string
  value: number
};

export type TriggerButtonProps = {
  onClick: React.MouseEventHandler<HTMLWppButtonElement>
  disabled?: boolean
};

export type FormValues = {
  algoTypes: { name: string }[]
  startDate: string
  startTime: string
  hasEndDate: boolean
  endDate: string | null
  subject: string
  content: string
  priority: { value: string }
};

export type SubmitFunctionParams = {
  formValues: FormValues
  msgId: string | number
  messageContextId: string | null
  userId: string | number
  prevAlgoTypes: { name: string }[]
  navigate: NavigateFunction
  dispatch: Dispatch
  handleModalClose: () => void
};
