import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { WppGrid, WppLabel } from 'buildingBlocks';
import { SET_USER_BID_MOD_RMVL_CONSENT } from 'containers/StrategyWizard/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { getBrandFlightCandidates, getFlightCandidates } from 'containers/StrategyWizard/steps/AttachFlights/actions';
import { modalSessionFlightsInfoInitialState, OptimizationType, SelectedTab } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { configuringLineItemStratCheck, configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { ModalSessionFlightsInfoType } from 'containers/StrategyWizard/types';
import { getSyncExternalTypeByDSP, parentExtTypeByExtType } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { Microservices } from 'utils/copilotAPI';
import { membersForFeature, Permission } from 'utils/featureFlags';
import { usePrevious } from 'utils/hooks/usePrevious';
import { StrategyType, FetchState, Flight, User, FlightDisplayName } from 'utils/types';
import { AttachFlightsModalTable } from './AttachFlightsModalTable';
import { BulkUploader } from './BulkUploader';
import FlightCandidateFormField from './FlightCandidateFormField';
import FlightSyncComponent from './FlightSyncComponent';
import FlightMenuSelector from './FlightMenuSelector';
import FlightSyncResponse from './FlightSyncResponse';

const getLabelDisplayName = (selectedOptType: OptimizationType, flightDisplayName: FlightDisplayName) => {
  switch (selectedOptType) {
    case (OptimizationType.campaign):
      return flightDisplayName.single;
    case (OptimizationType.lineItem):
      return flightDisplayName.multiple;
    case (OptimizationType.crossPlatform):
    default:
      return 'Objects';
  }
};

type AttachFlightsProps = {
  selectedOptType: OptimizationType
  flightDisplayName: FlightDisplayName
  modalSessionFlightsInfo: ModalSessionFlightsInfoType
  setModalSessionFlightsInfo: (x: any) => void
  sessionAttachFlights: Array<Flight>
  setSessionAttachFlights: (x: any) => void
  defaultCurrencyId: number | null
  setDefaultCurrencyId: (x: any) => void
  selectedStrategyType?: StrategyType
  strategyId?: number
};

const AttachFlights = (props: AttachFlightsProps) => {
  const {
    selectedOptType,
    flightDisplayName,
    selectedStrategyType,
    strategyId,
    modalSessionFlightsInfo,
    setModalSessionFlightsInfo,
    sessionAttachFlights,
    setSessionAttachFlights,
    defaultCurrencyId,
    setDefaultCurrencyId,
  } = props;

  const modalSessionFlightsInfoRef = useRef<ModalSessionFlightsInfoType>();
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.selectLineItemCampaign);
  const configuringHigherLevelStrat = !configuringLineItemStratCheck(selectedOptType);
  const configuringCrossPlatformStrat = configuringCrossPlatformStratCheck(selectedOptType);
  const prevSessionAttachFlights = usePrevious(sessionAttachFlights);
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const validMembers = membersForFeature(user, Permission.accessStrategyWizard);

  const { dispatch } = useStrategyWizardContext();
  const attachedFlights = useWatch({ name: 'attachedFlights' });
  const optimizationLevel = useWatch({ name: 'optimizationLevel' });
  const advertiser = useWatch({ name: 'advertiser' });
  const member = useWatch({ name: 'member' });
  const brand = useWatch({ name: 'brand' });
  const { syncFlightsStatus } = modalSessionFlightsInfo;

  useEffect(() => {
    // update modalSessionFlightsInfo state ref everytime modalSessionFlightsInfo is updated
    // ref is needed to access the current state as setModalSessionFlightsInfo is called multiple times in onSyncFlights
    modalSessionFlightsInfoRef.current = modalSessionFlightsInfo;
  }, [modalSessionFlightsInfo]);

  useEffect(() => {
    setSelectedTab(SelectedTab.selectLineItemCampaign);
  }, [optimizationLevel]);

  useEffect(() => {
    if (member && advertiser && (!brand || !configuringCrossPlatformStrat)) {
      getFlightCandidates(
        attachedFlights,
        {
          advertiserId: _.get(advertiser, 'id'),
          memberId: _.get(member, 'id'),
          strategyTypeId: strategyId && _.get(selectedStrategyType, 'id'),
        },
        modalSessionFlightsInfoInitialState,
        setModalSessionFlightsInfo,
        strategyId,
        _.get(optimizationLevel, 'externalTypeIds'),
        configuringCrossPlatformStrat,
      );
    }
    /* resetting removal consent here to handle edge case where user went to step 5,
    then came back to step 4 and added a flight with warnings */
    dispatch({ type: SET_USER_BID_MOD_RMVL_CONSENT, payload: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertiser, brand]);

  useEffect(() => {
    if (brand && configuringCrossPlatformStrat) {
      getBrandFlightCandidates(
        attachedFlights,
        _.get(brand, 'id'),
        defaultCurrencyId,
        modalSessionFlightsInfo,
        setModalSessionFlightsInfo,
        // @ts-ignore validMembers can sometimes be false
        validMembers,
        _.get(member, 'id'),
        _.get(advertiser, 'id'),
      );
    }
    setSelectedTab(SelectedTab.selectLineItemCampaign);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, member, advertiser]);

  // specific to cross-platform strategies
  // if no flights are attached on the form - reset default currency (if in create mode) and
  // refetch candidates when flights are attached then removed within the same modal session
  useEffect(() => {
    if (
      prevSessionAttachFlights && !_.isEmpty(prevSessionAttachFlights) && _.isEmpty(sessionAttachFlights)
      && _.isEmpty(attachedFlights) && defaultCurrencyId && brand && configuringCrossPlatformStrat
    ) {
      if (_.isNil(strategyId)) {
        setDefaultCurrencyId(null);
      }
      getBrandFlightCandidates(
        attachedFlights,
        brand.id,
        strategyId ? defaultCurrencyId : null,
        modalSessionFlightsInfo,
        setModalSessionFlightsInfo,
        // @ts-ignore validMembers can sometimes be false
        validMembers,
        _.get(member, 'id'),
        _.get(advertiser, 'id'),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionAttachFlights]);

  const onSyncFlights = async () => {
    const externalType = getSyncExternalTypeByDSP(member.dsp as number) as number;
    await setModalSessionFlightsInfo({
      ...modalSessionFlightsInfo,
      syncFlightsStatus: { kind: FetchState.loading },
    });
    try {
      const syncResponse = await Microservices.runService(
        {
          member_ext_id: member.externalId,
          advertiser_ext_id: advertiser.externalId,
          external_type: externalType,
        },
        'sync_by_advertiser',
      );

      const { data } = syncResponse;
      if (data.error) {
        setModalSessionFlightsInfo({
          ...modalSessionFlightsInfoRef.current,
          syncFlightsStatus: {
            kind: FetchState.error,
            error: data.error,
          },
        });
      } else {
        const parentExtType = parentExtTypeByExtType[externalType];
        await getFlightCandidates(
          attachedFlights,
          {
            advertiserId: advertiser.id,
            memberId: member.id,
            strategyTypeId: strategyId && _.get(selectedStrategyType, 'id'),
          },
          { ...modalSessionFlightsInfoRef.current,
            syncFlightsStatus: {
              kind: FetchState.success,
              result: _.size(data[parentExtType].adds).toString(),
            },
          },
          setModalSessionFlightsInfo,
          strategyId,
          [parentExtType],
          configuringCrossPlatformStrat,
          defaultCurrencyId,
        );
      }
    } catch (error) {
      setModalSessionFlightsInfo({
        ...modalSessionFlightsInfoRef.current,
        syncFlightsStatus: {
          kind: FetchState.error,
          error: 'Could not sync flights',
        },
      });
    }
  };

  return (
    <>
      <WppGrid container item all={24} fullWidth style={MODAL_STYLES.advertiseFormStyle}>
        <>
          {configuringHigherLevelStrat ? (
            <div style={MODAL_STYLES.attachAdvertiseContainer}>
              <WppLabel
                htmlFor="attachedFlights"
                typography="s-strong"
                config={{
                  text: `Attach ${getLabelDisplayName(selectedOptType, flightDisplayName)}`,
                }}
                style={MODAL_STYLES.attachFormFeildLabel}
              />
              {(configuringHigherLevelStrat && (!configuringCrossPlatformStrat || advertiser)) && (
                <FlightSyncComponent
                  flightDisplayName={flightDisplayName}
                  status={syncFlightsStatus}
                  onClick={onSyncFlights}
                />
              )}
            </div>
          ) : (
            <FlightMenuSelector
              menuItemOneName={`Attach ${flightDisplayName?.multiple ?? 'Line Items'}`}
              menuItemTwoName={SelectedTab.bulkUploader}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === SelectedTab.selectLineItemCampaign && (
            <>
              <FlightCandidateFormField
                selectedOptType={selectedOptType}
                externalTypeIds={optimizationLevel?.externalTypeIds ?? []}
                flightDisplayName={flightDisplayName}
                strategyId={strategyId}
                strategyTypeId={_.get(selectedStrategyType, 'id')}
                modalSessionFlightsInfo={modalSessionFlightsInfo}
                setModalSessionFlightsInfo={setModalSessionFlightsInfo}
                sessionAttachFlights={sessionAttachFlights}
                setSessionAttachFlights={setSessionAttachFlights}
                defaultCurrencyId={defaultCurrencyId}
                setDefaultCurrencyId={setDefaultCurrencyId}
                // @ts-ignore validMembers can sometimes be false
                validMembers={validMembers}
              />
              {configuringHigherLevelStrat && (
                <FlightSyncResponse syncFlightsStatus={syncFlightsStatus} flightDisplayName={flightDisplayName} />
              )}
            </>
          )}
          {selectedTab === SelectedTab.bulkUploader && (
            <WppGrid style={{ padding: '0 0 .4rem' }} all={12}>
              <BulkUploader
                advertiserId={advertiser?.id}
                memberId={member?.id}
                externalTypeIds={optimizationLevel?.externalTypeIds}
                flightDisplayName={flightDisplayName}
                modalSessionFlightsInfo={modalSessionFlightsInfo}
                setModalSessionFlightsInfo={setModalSessionFlightsInfo}
                strategyId={strategyId}
                strategyTypeId={_.get(selectedStrategyType, 'id')}
                sessionAttachFlights={sessionAttachFlights}
                setSessionAttachFlights={setSessionAttachFlights}
              />
            </WppGrid>
          )}
        </>
      </WppGrid>
      <WppGrid item all={24}>
        <AttachFlightsModalTable
          selectedOptType={selectedOptType}
          flightDisplayName={flightDisplayName}
          modalSessionFlightsInfo={modalSessionFlightsInfo}
          setModalSessionFlightsInfo={setModalSessionFlightsInfo}
          sessionAttachFlights={sessionAttachFlights}
          setSessionAttachFlights={setSessionAttachFlights}
        />
      </WppGrid>
    </>
  );
};

export default AttachFlights;
