import { CSSProperties } from 'react';
import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const slash: CSSProperties = {
  color: 'grey',
  fontWeight: 'normal',
  fontSize: '80%',
  margin: '0 0.3em',
};

export const jobDetailContainer: CSSProperties = {
  padding: '13px 16px',
};

export const detailsButtonMargin: CSSProperties = {
  marginTop: SPACING[16],
};

export const modalButtonAlignment: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const breadCrumBackLink: CSSProperties = {
  marginLeft: SPACING[4],
  lineHeight: '15px',
};

export const breadCrumContainer: CSSProperties = {
  display: 'flex',
  marginBottom: SPACING[8],
};

export const headerStyle: CSSProperties = {
  display: 'flex',
  gap: SPACING[12],
};

export const headerTagStyle: CSSProperties = {
  marginTop: SPACING[8],
};
