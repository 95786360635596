import _ from 'lodash';
import React, { useRef, useState, useEffect, CSSProperties, MutableRefObject, SetStateAction, Dispatch } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Loader, WppActionButton, WppInput, WppListItem, WppSegmentedControl, WppSegmentedControlItem, WppSelect, WppTypography } from 'buildingBlocks';
import { GOAL_TYPES, GOAL_TYPES_TO_STRATEGY_TYPES, RevenueType } from 'constantsBase';
import { CLEAR_AWG_CONFIG } from 'containers/StrategyWizard/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { useFetchDspSpecificChildren } from 'containers/StrategyWizard/utils';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { GoalType, InputChangeEventDetail, SelectChangeEventDetail, WppInputCustomEvent, WppSelectCustomEvent } from 'utils/types';
import MultiGoalView from './MultiGoalView';
import { GOAL_TYPES_FOR_STRATEGY_WIZARD, GoalTypeSearchCriteria } from '../constants';
import { GOAL_SECTION_STYLES } from '../styles';
import { filterGoals, isAWGGoalType, populateBaseGoalTypes } from '../utils';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type GoalSelectionProps = {
  initialValues: WizardFormGoalSelection
  isHigherLevelOptimizationLevel: boolean
  awgRef: MutableRefObject<boolean>
  confirmedGoal: string
  setConfirmedGoal: Dispatch<SetStateAction<string>>
};

const GoalSelection = (props: GoalSelectionProps) => {
  const { confirmedGoal, setConfirmedGoal, initialValues, isHigherLevelOptimizationLevel, awgRef } = props;
  const { dispatch } = useStrategyWizardContext();
  const {
    successEventFilter, setGoalSuccessEventFilter, revenueTypeEnabled, isCrossPlatformOptimization,
    strategyId, dsp, user, firstFlightExtId, hasAmznFlights,
    wizardFormValues: {
      attachFlightsStep: { optimizationLevel, member },
      goalSelectionStep: { budget: contextBudget },
      budgetAllocationState,
      strategyTypeSelectionStep,
    },
    isCampaignOptimization,
  } = useGoalSectionContext();
  const selectedStratTypeId = _.get(strategyTypeSelectionStep, 'strategyType.id') as number;

  const { reset, setValue } = useFormContext<WizardFormGoalSelection>();
  const goalType = useWatch({ name: 'goal.type' });
  const goalTarget = useWatch({ name: 'goal.target' });
  const customGoal = useWatch({ name: 'customGoal' });
  const budgetSettings = useWatch({ name: 'budgetSettings' });
  const budgetConfig = useWatch({ name: 'budget' });

  const [searchValue, setSearchValue] = useState<string>('');
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [focusSearchInput, setFocusSearchInput] = useState<boolean>(false);
  const [baseGoalTypes, setBaseGoalTypes] = useState<{ [key: string]: GoalType }>({});
  const [visibleGoals, setVisibleGoals] = useState<{ [key: string]: GoalType }>({});
  const [goalTypeFilter, setGoalTypeFilter] = useState<GoalTypeSearchCriteria>(GoalTypeSearchCriteria.standard);
  const flightExtType = _.head(_.get(optimizationLevel, 'externalTypeIds')) as number;

  // cannot call hooks in callbacks or else I would put this call in the filter fn for only YouTube goal types
  const budgetConfigFlightExtId = _.findKey(budgetConfig, 'outcome');
  const revenueType = useWatch({ name: `budget[${budgetConfigFlightExtId}].outcome` }) as RevenueType;
  const revenueOutcomeType = useWatch({ name: 'revenueOutcomeType' });
  const dspSpecificChildrenState = useFetchDspSpecificChildren(dsp, budgetAllocationState, firstFlightExtId);
  const showRevMarginGoal = revenueTypeEnabled && isCrossPlatformOptimization;
  const isImpactGoal = _.isEqual(goalType, GOAL_TYPES.impactOutcome.value);
  const isAwgOrImpact = isAWGGoalType(goalType) || isImpactGoal;

  useEffect(() => {
    const getGT = () => {
      let baseGT = populateBaseGoalTypes(
        user,
        flightExtType,
        // @ts-ignore
        _.get(dspSpecificChildrenState, 'data', []),
        optimizationLevel,
        _.pick(member, ['externalId', 'businessModel']),
        isCrossPlatformOptimization,
        hasAmznFlights,
      );
      // in Edit mode, show only goals tied to the strategyTypeId
      if (strategyId) {
        baseGT = _.pickBy(baseGT, (goal: GoalType) => _.includes(GOAL_TYPES_TO_STRATEGY_TYPES[goal.value], selectedStratTypeId));
      }
      setBaseGoalTypes(baseGT);
      const filteredGoals = filterGoals(baseGT, {
        goalType: goalTypeFilter,
        successEvent: successEventFilter,
        revenueTypeEnabled,
        isCrossPlatformOptimization,
        isCampaignOptimization,
        hasAmznFlights,
        flightExtType,
        user,
      });
      setVisibleGoals(filteredGoals);
    };
    getGT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dspSpecificChildrenState.kind]);

  useEffect(() => {
    if (_.size(customGoal?.formula) || isAwgOrImpact) {
      setValue('goal.target', goalTarget);
      if (!isImpactGoal) {
        // repopulate goal.type with awgGoalType that was selected
        setValue('goal.type', GOAL_TYPES.awgCreateYourOwn.value);
      }
      setConfirmedGoal(!isImpactGoal ? GOAL_TYPES.awgCreateYourOwn.value : GOAL_TYPES.impactOutcome.value);
    } else {
      reset({ ...initialValues, budgetSettings });
      setConfirmedGoal(initialValues.goal.type);
    }
    if (_.get(GOAL_TYPES_FOR_STRATEGY_WIZARD, [initialValues.goal.type, 'isCustom']) || isAwgOrImpact) {
      setGoalTypeFilter(GoalTypeSearchCriteria.advanced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.goal.type]);

  useEffect(() => {
    // Hide filters when user starts typing search
    if (_.size(searchValue) && showFilters) {
      setShowFilters(false);
    }
    // Show filters when user resets filters
    if (!_.size(searchValue) && !showFilters) {
      setShowFilters(true);
    }
  }, [searchValue, showFilters]);

  useEffect(() => {
    // in edit case, want confirmedGoal to be what goalType is initially
    if (_.size(contextBudget) && _.size(customGoal)) {
      setConfirmedGoal(goalType);
    }
    const scrollPos = window.scrollY;
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: successEventFilter, revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization, hasAmznFlights });
    setVisibleGoals(filteredGoals);
    window.scrollTo({ top: scrollPos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter, successEventFilter, revenueType, revenueTypeEnabled, revenueOutcomeType]);

  useEffect(() => {
    if (revenueTypeEnabled && !isAwgOrImpact) {
      // setting GoalTypeSearchCriteria to standard when selected goal is not Awg or impact when revenue toggle on
      setGoalTypeFilter(GoalTypeSearchCriteria.standard);
    }
    if (
      !revenueTypeEnabled && _.get(GOAL_TYPES, `${confirmedGoal}.isCustom`, false)) {
      setValue('goal.type', confirmedGoal);
      // setting GoalTypeSearchCriteria to advanced when selected goal an advanced goal when revnue toggle off
      setGoalTypeFilter(GoalTypeSearchCriteria.advanced);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueTypeEnabled]);

  useEffect(() => {
    setGoalSuccessEventFilter('all');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueOutcomeType, goalTypeFilter]);

  useEffect(() => {
    setSearchValue('');
  }, [successEventFilter]);

  useEffect(() => {
    const filteredGoals = filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization, hasAmznFlights });
    setVisibleGoals(filteredGoals);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goalTypeFilter]);

  const onSearchChange = (_evt: WppInputCustomEvent<InputChangeEventDetail>, { value }) => {
    setSearchValue(value);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, textSearch: value, revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization, hasAmznFlights }));
  };

  const searchRef = useRef(null);
  useEffect(() => {
    onSearchChange(null, { value: '' });
    if (searchRef.current) {
      searchRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusSearchInput]);

  const resetSearch = () => {
    setConfirmedGoal(null);
    setFocusSearchInput(!focusSearchInput);
  };

  const changeGoal = () => {
    if (_.size(customGoal)) {
      dispatch({ type: CLEAR_AWG_CONFIG });
      setValue('goal.target', null);
      setValue('metricsConfig', null);
      // setting customGoal to null directly doesn't work as expected
      setValue('customGoal.formula', null);
      setValue('customGoal.metrics', null);
      setValue('customGoal.name', null);
      setValue('customGoal.value', null);
      setValue('customGoal.optimizationDirection', null);
    }
    setConfirmedGoal(null);
    setVisibleGoals(filterGoals(baseGoalTypes, { goalType: goalTypeFilter, successEvent: 'all', revenueTypeEnabled, flightExtType, user, isCrossPlatformOptimization, isCampaignOptimization, hasAmznFlights }));
  };

  const singleGoalView = !_.isNil(confirmedGoal) && _.has(visibleGoals, confirmedGoal);

  const visibleGoalSuccessEvents = _.chain(
    showRevMarginGoal ? visibleGoals : baseGoalTypes,
  )
    .filter((gT) => (goalTypeFilter === GoalTypeSearchCriteria.advanced ? gT.isCustom : !gT.isCustom))
    .map('successEvent')
    .uniq()
    .sortBy(_.identity)
    .value();

  const showAdvancedGoals = _.some(baseGoalTypes, 'isCustom');

  const renderGoalCards = () => {
    // for Tactic Optimization Level, there is no call to budgetAllocationState
    if (!isHigherLevelOptimizationLevel || dspSpecificChildrenState.kind === PossibleStates.hasData || isCrossPlatformOptimization) {
      return (
        <MultiGoalView
          visibleGoals={singleGoalView ? _.pick(visibleGoals, confirmedGoal) : visibleGoals}
          confirmedGoal={confirmedGoal}
          setConfirmedGoal={setConfirmedGoal}
          resetSearch={resetSearch}
          initialValues={initialValues}
          goalTypeFilter={goalTypeFilter}
          awgRef={awgRef}
        />
      );
    }
    switch (dspSpecificChildrenState.kind) {
      case PossibleStates.initial:
      case PossibleStates.loading:
        return (<Loader size="large" style={GOAL_SECTION_STYLES.loader} inline />);
      case PossibleStates.error:
      default:
        return (
          <div style={GOAL_SECTION_STYLES.noVisibleGoals}>
            <p>Error fetching goals. Please try again later.</p>
          </div>
        );
    }
  };

  return (
    <div style={GOAL_SECTION_STYLES.container as CSSProperties}>
      <div style={GOAL_SECTION_STYLES.goalConfigAndReviewStyle}>
        <WppTypography type="xl-heading" tag="p">
          Goals
        </WppTypography>
        <WppTypography type="s-body" tag="p">
          Configure and review the selected goal.
        </WppTypography>
      </div>
      {singleGoalView ? (
        <div style={GOAL_SECTION_STYLES.editGoal as CSSProperties}>
          <WppActionButton onClick={changeGoal} className="changegoal" tabIndex={0}>
            Change Goal
          </WppActionButton>
        </div>
      ) : (
        <div style={GOAL_SECTION_STYLES.wppsearchContainer}>
          <WppInput
            name="searchGoals"
            ref={searchRef}
            className="searchBarGoalsStrategyWizard"
            placeholder="Search goals"
            value={searchValue}
            type="search"
            size="s"
            onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => onSearchChange(event, { value: event.detail.value })}
          />
          {showFilters && (
            <>
              <WppSegmentedControl
                value={goalTypeFilter}
                size="s"
                style={GOAL_SECTION_STYLES.wppGoalSegment}
                className="goalTypeSegment"
              >
                <WppSegmentedControlItem
                  onClick={() => setGoalTypeFilter(GoalTypeSearchCriteria.standard)}
                  value={GoalTypeSearchCriteria.standard}
                >
                  <WppTypography className="wppTypogrphySegmentColor" type="s-midi" tag="p">{_.capitalize(GoalTypeSearchCriteria.standard)}</WppTypography>
                </WppSegmentedControlItem>
                <WppSegmentedControlItem
                  disabled={!showAdvancedGoals}
                  onClick={() => setGoalTypeFilter(GoalTypeSearchCriteria.advanced)}
                  value={GoalTypeSearchCriteria.advanced}
                >
                  <WppTypography className="wppTypogrphySegmentColor" type="s-midi" tag="p">{_.capitalize(GoalTypeSearchCriteria.advanced)}</WppTypography>
                </WppSegmentedControlItem>
              </WppSegmentedControl>
              <WppSelect
                onWppChange={(
                  event: WppSelectCustomEvent<SelectChangeEventDetail>,
                ) => {
                  setGoalSuccessEventFilter(event.detail.value);
                  setSearchValue('');
                }}
                size="s"
                value={successEventFilter}
                style={GOAL_SECTION_STYLES.wppGoalSelect}
              >
                <WppListItem value="all" key="all">
                  <p slot="label">All</p>
                </WppListItem>
                {visibleGoalSuccessEvents.map((successEvent) => (
                  <WppListItem value={successEvent} key={successEvent}>
                    <p slot="label">{successEvent}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </>
          )}
        </div>
      )}
      {renderGoalCards()}
    </div>
  );
};

export default GoalSelection;
