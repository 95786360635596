import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WppTableBody, WppTableBodyRow, WppTableBodyCell, WppTypography, WppTag } from 'buildingBlocks';
import { dateTimeFormatter } from 'utils/dateTime';
import { Job, TimeZoneValue } from 'containers/Jobs/types';
import { jobStatusText, jobStatusColor, jobStatusVariant } from 'containers/Jobs/utils';
import { GlobalState, RootState } from 'reducers';
import { UserSettings } from 'utils/types';
import { jobTypeLabel } from './style';

interface BodyProps {
  selectedTimezone: TimeZoneValue
}

const Body = (props: BodyProps) => {
  const { jobs } = useSelector((state: RootState) => state.jobs);
  const { selectedTimezone } = props;
  const userSettings = useSelector<GlobalState>((state) => state.login.settings) as UserSettings;
  const userTimeZone = selectedTimezone === TimeZoneValue.utc ? null : _.get(userSettings, 'config.timeZone');
  return (
    <WppTableBody>
      {_.map(jobs, (job: Job) => (
        <WppTableBodyRow key={job.id}>
          <WppTableBodyCell>
            <Link to={`/jobs/${job.id}`}><WppTypography tag="p" type="s-body" style={jobTypeLabel}>{job.id}</WppTypography></Link>
            <WppTag label={job.type} variant="neutral" />
          </WppTableBodyCell>
          {!_.has(job, 'member')
            ? <WppTableBodyCell />
            : (
              <WppTableBodyCell>
                <WppTypography tag="p" type="s-body">{job.member.name}&nbsp;({job.member.externalId})</WppTypography>
              </WppTableBodyCell>
            )}
          <WppTableBodyCell><WppTypography tag="p" type="s-body">{dateTimeFormatter.timeZoneBasedDateTime(selectedTimezone, job.createdAt, userTimeZone)}</WppTypography></WppTableBodyCell>
          <WppTableBodyCell><WppTypography tag="p" type="s-body">{dateTimeFormatter.timeZoneBasedDateTime(selectedTimezone, job.updatedAt, userTimeZone)}</WppTypography></WppTableBodyCell>
          <WppTableBodyCell>
            <WppTag label={jobStatusText(job)} variant={jobStatusVariant(job)} categoricalColorIndex={jobStatusColor(job)} />
          </WppTableBodyCell>
        </WppTableBodyRow>
      ))}
    </WppTableBody>
  );
};

export default Body;
