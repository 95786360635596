/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { GlobalState } from 'reducers';
import { WppIconChevron, WppBreadcrumb, WppTypography, WppButton } from 'buildingBlocks';
import { Permission, featureEnabled, MEMBER, checkPermissions } from 'utils/featureFlags';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import PageNotFound from 'containers/404';
import { useAsyncEffect } from 'utils/functionHelpers';
import { PossibleStates } from 'utils/hooks/useFetcher';
import { User } from 'utils/types';
import { resetAll, getUser } from './actions';
import { ButtonProps, Mode, RenderUserAfterApproverLoadsProps } from './types';
import { USER_LANDING_LINK } from './constants';
import { useDoesCurrentUserExist } from './utils';
import { breadCrumContainer, breadCrumBackLink, headerStyle, headerContainer, pageLoaderStyle } from './style';
import PageLoader from './components/PageLoader';
import UserPage from './components/UserPage';

const REQUIRED_PERMISSIONS = [Permission.manageUserRoles, Permission.adminUsers];
const REQUIRED_PERMISSIONS_TO_CREATE = [Permission.adminUsers];

const ShowHideEditButton = ({ mode, setMode }: ButtonProps) => {
  if (mode === Mode.edit || mode === Mode.create) {
    return (<></>);
  }
  return (
    <WppButton
      variant="secondary"
      size="m"
      onClick={() => setMode(Mode.edit)}
      id="user-detail-save-btn"
      tabIndex={0}
    >
      Edit
    </WppButton>
  );
};

const RenderUserAfterApproverLoads = ({
  currUserExists,
  onCreateNewUser,
  userId,
  mode,
  setMode,
  isLoggedInUser,
  userData,
  onUserProfile,
  userDoesntExistAndNotCreateMode,
  isNewUser,
  email,
  navigate,
}: RenderUserAfterApproverLoadsProps) => {
  if ((currUserExists && email) || (onCreateNewUser || isNewUser)) {
    return (
      <UserPage
        userId={userId}
        mode={mode}
        setMode={setMode}
        isLoggedInUser={isLoggedInUser}
        onCreateNewUser={onCreateNewUser}
        userData={userData}
        onUserProfile={onUserProfile}
        isNewUser={isNewUser}
        navigate={navigate}
      />
    );
  } if (userDoesntExistAndNotCreateMode) {
    return (<PageNotFound />);
  }
  return (<PageLoader />);
};

const PermissionUser = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const { email, approver } = useSelector<GlobalState>((rootState) => rootState.user) as User;
  const fromReviewRequestEmail = _.includes(location.search, 'ra=1');

  const onCreateNewUser = location.pathname === '/users/create';
  const [mode, setMode] = useState<Mode>(onCreateNewUser ? Mode.create : Mode.view);
  const onUserProfile = location.pathname === '/userprofile';
  const isNewUser = onUserProfile && _.isEmpty(user.roles);
  const userId = onUserProfile ? user.id : +params.userId;

  const userFetcher = useDoesCurrentUserExist(userId);
  const isLoggedInUser = user?.id === userId || onUserProfile;

  const handleRouteChange = () => {
    navigate(USER_LANDING_LINK);
  };

  useAsyncEffect(async () => {
    dispatch(resetAll());
    if (fromReviewRequestEmail || isNewUser) {
      setMode(Mode.edit);
    } else if (!onCreateNewUser) {
      setMode(Mode.view);
    }
    await getUser(userId, featureEnabled(user, Permission.manageUserRoles, MEMBER.ANY), dispatch);
  }, [userId, params.path, user, onCreateNewUser, fromReviewRequestEmail]);

  const permissionsToViewUser = (uId: number) => {
    const noUserId = _.isNil(uId) || _.isNaN(uId);
    if (isNewUser) {
      return [];
    }
    if (noUserId) {
      return REQUIRED_PERMISSIONS_TO_CREATE;
    }
    return REQUIRED_PERMISSIONS;
  };

  const userPageName = (onCreateNewUser: boolean, isLoggedInUser: boolean) => {
    if (onCreateNewUser) {
      return 'Create User';
    }
    if (isLoggedInUser) {
      return 'My Profile';
    }
    return 'User Profile';
  };

  switch (userFetcher.kind) {
    case (PossibleStates.hasData): {
      const currUserExists = ((userFetcher.kind === PossibleStates.hasData) && !_.isEmpty(userFetcher.data)) || isLoggedInUser;
      const userDoesntExistAndNotCreateMode = !(currUserExists || onCreateNewUser);
      const renderUserProps = {
        currUserExists,
        onCreateNewUser,
        approver,
        userId,
        mode,
        setMode,
        isLoggedInUser,
        userData: _.head(userFetcher.data) as User,
        onUserProfile,
        userDoesntExistAndNotCreateMode,
        isNewUser,
        email,
        navigate,
      };
      return (
        <WppPermissionPageTemplate
          title="User"
          name={!userDoesntExistAndNotCreateMode && userPageName(onCreateNewUser, isLoggedInUser)}
          permissions={permissionsToViewUser(userId)}
          allPermissions={false}
          subNavStyle={{ paddingLeft: '50px' }}
          unauthorizedProps={{
            button: {
              url: USER_LANDING_LINK,
              content: 'Go back to users listing page',
            },
          }}
          customHeader={(
            <div style={headerContainer}>
              <div style={breadCrumContainer}>
                <WppIconChevron direction="left" size="s" />
                <WppBreadcrumb
                  data-testid="breadCrumb"
                  items={[{
                    label: 'Back to Users',
                    path: USER_LANDING_LINK,
                  }]}
                  onWppChange={handleRouteChange}
                  style={breadCrumBackLink}
                />
              </div>
              <div style={headerStyle}>
                <WppTypography tag="p" type="3xl-heading">{!userDoesntExistAndNotCreateMode && userPageName(onCreateNewUser, isLoggedInUser)}</WppTypography>
                <span>
                  {(checkPermissions(user, REQUIRED_PERMISSIONS, MEMBER.ANY, false) && !userDoesntExistAndNotCreateMode)
                    ? <ShowHideEditButton mode={mode} setMode={setMode} />
                    : null}
                </span>
              </div>
            </div>
          )}
          withoutLayout
        >
          {RenderUserAfterApproverLoads(renderUserProps)}
        </WppPermissionPageTemplate>
      );
    }
    default:
      return (
        <div style={pageLoaderStyle}>
          <PageLoader />
        </div>
      );
  }
};

export default PermissionUser;
