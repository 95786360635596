import _ from 'lodash';
import React from 'react';
import { WppActionButton, WppGrid, WppInlineMessage, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { BudgetGroup, BudgetInterval, GroupSettings } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { displayAllocationRange, displayDailyDelivery, displayLifetimeDelivery } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/utils';
import { displayCurrencyOrImps } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BudgetSetting } from 'containers/StrategyWizard/types';
import { TRUNCATION_LIMIT } from 'constantsBase';
import { truncate } from 'utils/formattingUtils';
import { Currency } from 'utils/types';
import { STRATEGY_CONFIRMATION_STYLES } from '../styles';
import { displayDescription } from '../utils';

const { column } = strategyWizardStyles;
const { budgetHeaderSection, budgetTableSectionStyle, budgetOpt, allocationRange, dailyDelivery, lifetimeDelivery, confirmStepInlineMessage } = STRATEGY_CONFIRMATION_STYLES;

type BudgetGroupRowProps = {
  group: BudgetGroup
  requiredDailyValue: number
  remainingSpendDays: number
  budgetMetric: string
  hasNMSPermission: boolean
};

const BudgetGroupRow = ({
  group: { groupName, min, max, aggDelivery, budgetOptimization },
  requiredDailyValue,
  remainingSpendDays,
  budgetMetric,
  hasNMSPermission,
}: BudgetGroupRowProps) => (
  <WppTableBody>
    <WppTableBodyRow style={{ height: '60px' }}>
      <WppTableBodyCell>
        <WppTypography type="s-body" tag="p" title={groupName}>{truncate(groupName, TRUNCATION_LIMIT)}</WppTypography>
      </WppTableBodyCell>
      {hasNMSPermission && (
        <WppTableBodyCell>
          <WppTypography type="s-body" tag="p">{budgetOptimization ? 'ON' : 'OFF'}</WppTypography>
        </WppTableBodyCell>
      )}
      <WppTableBodyCell>
        <WppTypography type="s-body" tag="p">{budgetOptimization ? displayAllocationRange(min, max) : 'N/A'}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell>
        <WppTypography type="s-body" tag="p">{displayDailyDelivery(requiredDailyValue, min, max, budgetMetric)}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell>
        <WppTypography type="s-body" tag="p">{displayLifetimeDelivery(requiredDailyValue, min, max, budgetMetric, remainingSpendDays, aggDelivery)}</WppTypography>
      </WppTableBodyCell>
    </WppTableBodyRow>
  </WppTableBody>
);

type BudgetManagementProps = {
  goToStep?: () => void
  groupSettings: GroupSettings
  defaultCurrency: Currency
  isCrossPlatformOptimization: boolean
  interval: BudgetInterval | BudgetSetting
  remainingSpendDays: number
  requiredDailyValue: number
  hasNMSPermission: boolean
};

const BudgetManagement = ({
  goToStep,
  groupSettings,
  defaultCurrency,
  isCrossPlatformOptimization,
  interval,
  remainingSpendDays,
  requiredDailyValue,
  hasNMSPermission,
}: BudgetManagementProps) => {
  const groupSize = _.size(groupSettings);

  const budgetMetric = isCrossPlatformOptimization
    ? defaultCurrency.code
    : displayCurrencyOrImps((interval as BudgetInterval));

  return (
    <WppGrid item all={24}>
      <div style={wppBodyContainer}>
        <div style={budgetHeaderSection}>
          <WppTypography type="xl-heading">Budget Management</WppTypography>
          <WppActionButton onClick={goToStep}>Edit</WppActionButton>
        </div>
        <div className="confirm-wpp-table" style={budgetTableSectionStyle}>
          <WppTable>
            <WppTableHeader>
              <WppTableHeaderRow key={column.value}>
                <WppTableHeaderCell style={hasNMSPermission ? STRATEGY_CONFIRMATION_STYLES.groupName : { width: '662px' }}>
                  <WppTypography type="s-strong" tag="p">Budget Group</WppTypography>
                </WppTableHeaderCell>
                {hasNMSPermission && (
                  <WppTableHeaderCell style={budgetOpt}>
                    <WppTypography type="s-strong" tag="p">Budget Optimization</WppTypography>
                  </WppTableHeaderCell>
                )}
                <WppTableHeaderCell style={allocationRange}>
                  <WppTypography type="s-strong" tag="p">Allocation Range</WppTypography>
                </WppTableHeaderCell>
                <WppTableHeaderCell style={dailyDelivery}>
                  <WppTypography type="s-strong" tag="p">Daily Delivery</WppTypography>
                </WppTableHeaderCell>
                <WppTableHeaderCell style={lifetimeDelivery}>
                  <WppTypography type="s-strong" tag="p">Lifetime Delivery</WppTypography>
                </WppTableHeaderCell>
              </WppTableHeaderRow>
            </WppTableHeader>
            {_.map(groupSettings, (group: BudgetGroup, groupKey: string) => (
              <BudgetGroupRow
                key={groupKey}
                group={group}
                requiredDailyValue={requiredDailyValue}
                remainingSpendDays={remainingSpendDays}
                budgetMetric={budgetMetric}
                hasNMSPermission={hasNMSPermission}
              />
            ))}
          </WppTable>
        </div>
        <WppInlineMessage
          size="s"
          message={displayDescription(groupSize, 'Group')}
          type="information"
          style={confirmStepInlineMessage}
        />
      </div>
    </WppGrid>
  );
};

export default BudgetManagement;
