import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { roleType, DSP } from 'constantsBase';
import { Form, ObjectMultiDropdown, ObjectDropdown, WppGrid, WppButton } from 'buildingBlocks';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { Member } from 'utils/types';
import { addButton } from '../style';
import { MemberRoleState, UserForm } from '../types';

type Props = {
  selectedRoles: Array<{}>
  selectedMembers: Array<Member>
  onAddMemberRoles: Function
  onRegionAdd: Function
  disabled: boolean
  memberRoleState: MemberRoleState
};

const MemberRoleSelector = ({
  selectedRoles,
  selectedMembers,
  onAddMemberRoles,
  onRegionAdd,
  disabled,
  memberRoleState,
}: Props) => {
  const { roleOptions, membersPerRoles, regions } = memberRoleState;
  const { control } = useFormContext<UserForm>();
  return (
    <>
      <WppGrid container fullWidth>
        <WppGrid item all={12}>
          <Form.Field>
            <label htmlFor="Roles">Roles</label>
            <Controller
              name="selectedRoles"
              control={control}
              render={({ field }) => (
                // @ts-ignore find a way to get the props to work with rhf controller
                <ObjectMultiDropdown
                  name="selectedRoles"
                  options={roleOptions}
                  keyFn={(obj: { name: string }) => obj.name}
                  placeholder="Choose role(s)"
                  selection
                  search={{ searchType: 'local' }}
                  disabled={disabled}
                  {...field}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={12}>
          <Form.Field>
            <label htmlFor="Region">Region</label>
            <Controller
              name="selectedRegion"
              control={control}
              render={() => (
                <ObjectDropdown
                  name="selectedRegion"
                  options={regions}
                  keyFn={(obj: { name: string }) => obj.name}
                  onChange={onRegionAdd}
                  selectOnBlur={false}
                  placeholder="Fill member by region"
                  disabled={_.isEmpty(selectedRoles) || !_.find(selectedRoles, { type: roleType.member }) || disabled}
                  selection
                  search={{ searchType: 'local' }}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth>
        <WppGrid item all={16}>
          <Form.Field>
            <label htmlFor="Members">Members</label>
            <Controller
              name="selectedMembers"
              control={control}
              render={({ field }) => (
                // @ts-ignore find a way to get the props to work with rhf controller
                <ObjectMultiDropdown
                  name="selectedMembers"
                  options={getOptionsWithDspIcon(membersPerRoles)}
                  placeholder="Choose member(s)"
                  selection
                  search={{
                    searchType: 'local',
                    onSearchChange: searchByNameOrExtId(selectedMembers),
                  }}
                  disabled={_.isEmpty(selectedRoles) || !_.find(selectedRoles, { type: roleType.member }) || disabled}
                  renderLabel={(label) => ({
                    content: label.content,
                  })}
                  keyFn={(mem: Member) => `${mem.displayName
                    ? `${DSP.getById(mem.dsp as number).code} - ${mem.displayName}`
                    : `${DSP.getById(mem.dsp as number).code} - ${mem.name}`}`}
                  {...field}
                />
              )}
            />
          </Form.Field>
        </WppGrid>
        <WppGrid item all={8}>
          <WppButton
            variant="secondary"
            disabled={disabled}
            onClick={() => onAddMemberRoles()}
            style={addButton}
            size="m"
          >
            Add User Roles
          </WppButton>
        </WppGrid>
      </WppGrid>
    </>
  );
};

export default MemberRoleSelector;
