/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React from 'react';
import { WppSideModal, WppTypography, WppProgressIndicator } from 'buildingBlocks';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { MODAL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import WeightsModalContent from './WeightsModalContent';
import WeightsModalContentProvider from '../contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';
import ModalFooter from '../ModalFooter';

const ERROR_MSG = 'Error loading budget optimization wizard. Please try again later.';

type WeightsModalProps = {
  metrics: Array<string>
};

const WeightsModal = ({ metrics }: WeightsModalProps) => {
  const { awgWeightsModalOpen, sessionMetrics, handleModalClose } = useWeightsSectionContext();
  const { wizardFormValues: { budgetAllocationState } } = useStrategyWizardContext();

  const renderModalContent = () => {
    const budgetAllocationStateStatus = _.get(budgetAllocationState, 'kind');
    switch (budgetAllocationStateStatus) {
      case (Status.error):
        return (
          <div slot="body" style={AWG_WEIGHTS_MODAL_STYLES.errorContainer}>
            <ErrorMessage
              errorText={ERROR_MSG}
              style={AWG_WEIGHTS_MODAL_STYLES.errorMessage}
            />
          </div>
        );
      case (Status.hasData):
        return (
          <WeightsModalContentProvider sessionMetrics={sessionMetrics}>
            <div slot="body">
              <WeightsModalContent metrics={metrics} />
            </div>
            <div slot="actions">
              <ModalFooter />
            </div>
          </WeightsModalContentProvider>
        );
      case (Status.initial):
      case (Status.loading):
      default:
        return (
          <div slot="body" style={MODAL_STYLES.weightModalProgress}>
            <WppProgressIndicator variant="circle" width={25} />
          </div>
        );
    }
  };

  return (
    <WppSideModal
      open={awgWeightsModalOpen}
      onWppSideModalClose={handleModalClose}
      size="2xl"
      className="awgWeightsModal"
    >
      <WppTypography slot="header" tag="p" type="2xl-heading">Weighting</WppTypography>
      {renderModalContent()}
    </WppSideModal>
  );
};

export default WeightsModal;
