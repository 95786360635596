import React from 'react';
import { useSelector } from 'react-redux';
import { WppPagination } from 'buildingBlocks';
import { PaginationChangeEventDetail, Range } from 'utils/types';
import { ELEMENTS_PER_PAGE_OPTIONS } from 'containers/Jobs/constants';
import { RootState } from 'reducers';

interface PaginationProps {
  setRangeDispatch: (range: Range) => void
}

const Pagination = ({ setRangeDispatch }: PaginationProps) => {
  const { jobsCount } = useSelector((state: RootState) => state.jobs);
  const handlePaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const range = {
      limit: event.detail.itemsPerPage,
      start: ((event.detail.page - 1) * event.detail.itemsPerPage),
      end: (event.detail.page * event.detail.itemsPerPage),
    };
    setRangeDispatch(range);
  };

  return (
    <WppPagination
      count={jobsCount}
      itemsPerPage={ELEMENTS_PER_PAGE_OPTIONS}
      onWppChange={handlePaginationChange}
    />
  );
};

export default Pagination;
