import _ from 'lodash';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GOAL_TYPES } from 'constantsBase';
import { IMPACT_OUTCOME_FORMULA } from 'containers/StrategyWizard/constants';
import { FormulaType, MetricConfig } from 'containers/StrategyWizard/types';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { OptimizationType } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { AWGProviderProps } from './types';
import { updateSelectedFormula, updateFormulaMetrics } from './actions';
import { awgReducer } from './reducers';

const AWGContext = createContext(null);

const AWGProvider = ({ children }: AWGProviderProps) => {
  const { wizardFormValues } = useStrategyWizardContext();
  const {
    attachFlightsStep: { selectedOptType },
    goalSelectionStep,
  } = wizardFormValues;
  const { setValue } = useFormContext();
  const goalType = useWatch({ name: 'goal.type' });
  const metricsConfig = useWatch({ name: 'metricsConfig', defaultValue: _.get(goalSelectionStep, 'metricsConfig') });
  const customGoal = useWatch({ name: 'customGoal', defaultValue: _.get(goalSelectionStep, 'customGoal', {}) });
  const isImpactGoal = _.isEqual(goalType, GOAL_TYPES.impactOutcome.value);
  const isCampaignOptType = _.isEqual(selectedOptType, OptimizationType.campaign);
  const defaultSelectedFormula = isImpactGoal ? IMPACT_OUTCOME_FORMULA : customGoal;

  const INITIAL_STATE = {
    formulaMetrics: metricsConfig ?? {},
    selectedFormula: defaultSelectedFormula,
  };
  const [state, dispatch] = useReducer(awgReducer, INITIAL_STATE);

  const actions = {
    setSelectedFormula: (formula: Partial<FormulaType>) => dispatch(updateSelectedFormula(formula)),
    setFormulaMetrics: (formulaMetrics: MetricConfig) => dispatch(updateFormulaMetrics(formulaMetrics)),
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    ...state,
    ...actions,
    isCampaignOptType,
    isImpactGoal,
  };

  useEffect(() => {
    // handle toggling back and forth between impact and awg
    if (isImpactGoal) {
      actions.setSelectedFormula(IMPACT_OUTCOME_FORMULA);
      setValue('customGoal', IMPACT_OUTCOME_FORMULA);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImpactGoal]);

  return (
    <AWGContext.Provider value={values}>
      {children}
    </AWGContext.Provider>
  );
};

export const useAWGContext = () => {
  const context = useContext(AWGContext);
  if (!context) {
    throw new Error('useAWGContext must be used within a AWGProvider');
  }
  return context;
};

export default AWGProvider;
