import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ObjectDropdown, WppGrid, WppTypography } from 'buildingBlocks';
import { getMemberOptionsWithDspIcon } from 'components/OptionWithIcon/OptionWithIcon';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { User } from 'utils/types';
import { FlightSyncForm } from '../constants';
import FLIGHT_SYNC_STYLES from '../styles';
import { useFlightSyncMemberFetcher } from '../utils';

type MemberFieldProps = {
  user: User
};

const MemberField = ({ user }: MemberFieldProps) => {
  const { control } = useFormContext<FlightSyncForm>();
  const selectedMember = useWatch({ name: 'member' });

  const [members, memberFetchError] = useFlightSyncMemberFetcher(user);
  const options = getMemberOptionsWithDspIcon(members);
  const disabled = _.isEmpty(members) || memberFetchError;

  return (
    <WppGrid container fullWidth>
      <WppGrid item all={8}>
        <WppTypography type="s-strong" style={FLIGHT_SYNC_STYLES.labelStyles}>Member</WppTypography>
        <Controller
          name="member"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <ObjectDropdown
              fluid
              selection
              options={options}
              keyFn={(mem) => `${mem.externalId} - ${mem.displayName}`}
              onChange={onChange}
              selectOnBlur={false}
              disabled={disabled}
              text={selectedMember ? `[${selectedMember.externalId}] ${selectedMember.displayName}` : 'Search DV360 Member...'}
              placeholder={selectedMember ? null : 'Search Members...'}
              search={{
                searchType: 'local',
                onSearchChange: searchByNameOrExtId(),
              }}
            />
          )}
        />
      </WppGrid>
    </WppGrid>
  );
};

export default MemberField;
