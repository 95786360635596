import _ from 'lodash';
import React, { CSSProperties, useEffect, useState } from 'react';
import { WppInlineMessage, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { FAILURE_TEXT, Status } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { FLIGHT_DETAIL_STYLES } from 'containers/StrategyWizard/steps/AttachFlights/styles';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import NMSIcon from 'components/NMSIcon';
import { Flight } from 'utils/types';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';

const {
  childLevelHeaderCell, childLevelFlightName, dspIcon, objectType, childLevelFlightDates, childLevelEndCell,
  baseChildLevelTableStyle, childFlightsError, baseChildLevelTableBodyCellStyle, inactiveChildFlightRow, activeChildFlightRow } = FLIGHT_DETAIL_STYLES;

type ChildLevelFlightDetailsProps = {
  parentFlightExtId: string
  flight: Flight
};

type ChildLevelFlightType = {
  extType: number
  name: string
  startDate: Date
  endDate: Date
  active: boolean
  isProgrammaticGuaranteed: boolean
};

type ChildLevelFlightWithExtIdType = ChildLevelFlightType & {
  externalId: string
};

type ChildLevelFlightRowProps = {
  flight: ChildLevelFlightWithExtIdType
  timezone: string
};

const ChildLevelFlightRow = ({ flight, timezone }: ChildLevelFlightRowProps) => {
  const { active, extType, startDate, endDate, name, isProgrammaticGuaranteed } = flight;
  const externalTypeDisplayName = _.get(FLIGHT_EXTERNAL_TYPE.getById(extType), 'wizardDisplayName') ?? FLIGHT_EXTERNAL_TYPE.getById(extType).displayName;
  const flightDatesToDisplay = `${displayDate(startDate, extType, timezone)} - ${displayDate(endDate, extType, timezone)}`;
  const rowStyle = active ? activeChildFlightRow : inactiveChildFlightRow;
  const getCellStyles = (style: CSSProperties): CSSProperties => ({
    ...style,
    ...{ borderTop: (active ? 'none' : '2px solid white') },
    ...baseChildLevelTableBodyCellStyle,
  });

  return (
    <WppTableBodyRow style={rowStyle}>
      <WppTableBodyCell style={getCellStyles(childLevelFlightName)}>
        <WppTypography type="s-body" tag="p">{name}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={getCellStyles(dspIcon)}>
        <WppTypography type="s-body" tag="p" />
      </WppTableBodyCell>
      <WppTableBodyCell style={getCellStyles(objectType)}>
        <WppTypography type="s-body" tag="p">{externalTypeDisplayName}
          <NMSIcon isProgrammaticGuaranteed={isProgrammaticGuaranteed} />
        </WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={getCellStyles(childLevelFlightDates)}>
        <WppTypography type="s-body" tag="p">{flightDatesToDisplay}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell style={getCellStyles(childLevelEndCell)} />
    </WppTableBodyRow>
  );
};

const ChildLevelFlightDetails = ({ parentFlightExtId, flight: flightObject }: ChildLevelFlightDetailsProps) => {
  const { wizardFormValues } = useStrategyWizardContext();
  const budgetAllocationState = _.get(wizardFormValues, 'budgetAllocationState');
  const budgetAllocationData = _.get(budgetAllocationState, `data[${parentFlightExtId}]`);
  const budgetAllocationDataError = budgetAllocationData === FAILURE_TEXT;
  const [childLevelFlights, setChildLevelFlights] = useState<Array<ChildLevelFlightWithExtIdType>>([]);
  const { kind } = budgetAllocationState;
  const statusHasData = kind === Status.hasData;

  useEffect(() => {
    // create a new array of all the childFlights and add externalId key
    const allChildFlights: Array<ChildLevelFlightWithExtIdType> = _.map({
      ..._.get(budgetAllocationData, 'hierarchy.childExtIdToSettings', {}), // active & not expired
      ..._.get(budgetAllocationData, 'hierarchy.invalidData', {}), // inactive or expired
    }, (flight: ChildLevelFlightType, extId: string) => ({ ...flight, externalId: extId }));
    // sort by active, then by name
    const sortedChildFlights = _.orderBy(allChildFlights, ['active', (flight: ChildLevelFlightWithExtIdType) => flight.name.toLowerCase()], ['desc', 'asc']);
    setChildLevelFlights(sortedChildFlights);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetAllocationState, parentFlightExtId]);

  return (
    <WppTable style={baseChildLevelTableStyle}>
      <WppTableBody>
        {(statusHasData && _.size(childLevelFlights))
          ? _.map(childLevelFlights, (flight: ChildLevelFlightWithExtIdType) => (
            <ChildLevelFlightRow
              flight={flight}
              key={`${flight.extType} - ${flight.externalId}`}
              timezone={flightObject.timezone}
            />
          ))
          : null}
        {(statusHasData && _.size(childLevelFlights) === 0 && !budgetAllocationDataError)
          ? (
            <WppTableBodyRow>
              <WppTableBodyCell style={childLevelHeaderCell} />
              <WppTableBodyCell style={childFlightsError}>
                <WppInlineMessage size="s" message="No Active Flights Found" type="warning" showTooltipFrom={100} />
              </WppTableBodyCell>
            </WppTableBodyRow>
          ) : null}
        {(kind === Status.error || budgetAllocationDataError)
          ? (
            <WppTableBodyRow>
              <WppTableBodyCell style={childFlightsError}>
                <WppInlineMessage size="s" message="Error Fetching Child Flights" type="warning" showTooltipFrom={100} />
              </WppTableBodyCell>
            </WppTableBodyRow>
          ) : null}
      </WppTableBody>
    </WppTable>
  );
};

export default ChildLevelFlightDetails;
