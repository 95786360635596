import React from 'react';
import Markdown from 'react-markdown-renderer';
import { WppButton, WppModal, WppTypography } from 'buildingBlocks';
import { MARKDOWN_OPTIONS } from '../constants';
import { confimMesssageModal } from '../style';

type ConfirmationModalProps = {
  open: boolean
  onCancel: React.MouseEventHandler<HTMLWppButtonElement>
  content: string
  onConfirm: React.MouseEventHandler<HTMLWppButtonElement>
  loading: boolean
};

export const ConfirmationModal = ({
  open, content, loading, onConfirm, onCancel,
}: ConfirmationModalProps) => (
  <WppModal
    size="s"
    open={open}
  >
    <WppTypography type="2xl-heading" tag="h3" slot="header">Confirmation</WppTypography>
    <div slot="body">
      <WppTypography type="s-body" tag="p"><Markdown markdown={content || ''} options={MARKDOWN_OPTIONS} /></WppTypography>
    </div>
    <div slot="actions" style={confimMesssageModal}>
      <WppButton
        variant="primary"
        size="m"
        type="submit"
        onClick={onConfirm}
        loading={loading}
      >
        Yes
      </WppButton>
      <WppButton
        variant="secondary"
        size="m"
        onClick={onCancel}
        disabled={loading}
      >
        No
      </WppButton>
    </div>
  </WppModal>
);

export default ConfirmationModal;
