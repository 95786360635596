import React from 'react';
import _ from 'lodash';
import { WppSegmentedControl, WppSegmentedControlItem, WppTypography } from 'buildingBlocks';
import { WppIconTick } from '@wppopen/components-library-react';
import { ELEMENT_ID } from 'cssTagConstants';
import { NotificationMsgObject, NotificationListState } from '../types';
import Notifications from './Notifications';
import { ActiveTab } from '../constants';
import { NOTIFICATION_STYLES } from '../style';

const { notificationHeader, notificationHeaderElements, notificationReadAllContainer, notificationReadAllTick, notificationReadAllAction } = NOTIFICATION_STYLES;

type SegmentedControlsProps = {
  notifications: Array<NotificationMsgObject>
  notificationsActionsCount: number
  notificationsUpdatesCount: number
  notificationsActionsUnreadCount: number
  notificationsUpdatesUnreadCount: number
  onClickNotification: (e: React.MouseEvent<HTMLDivElement>, selectedNotification: NotificationMsgObject) => void
  onDataRangeChange: (x: any) => void
  closeNotificationDropdown: () => void
  state: NotificationListState
  handleTabChange: (activeIndex: number) => void
  onReadAllNotifications: () => void
};

const SegmentedControls: React.FC<SegmentedControlsProps> = ({
  notifications,
  notificationsActionsCount,
  notificationsUpdatesCount,
  notificationsActionsUnreadCount,
  notificationsUpdatesUnreadCount,
  onClickNotification,
  onDataRangeChange,
  closeNotificationDropdown,
  state,
  handleTabChange,
  onReadAllNotifications,
}: SegmentedControlsProps): React.ReactElement => {
  const notificationCount = _.isEqual(state.activeTab, ActiveTab.actions) ? notificationsActionsCount : notificationsUpdatesCount;
  return (
    <>
      <div style={notificationHeader}>
        <div style={notificationHeaderElements}>
          <WppTypography type="m-strong" tag="h2">Notifications</WppTypography>
          <div style={notificationReadAllContainer} role="button" tabIndex={0} onClick={onReadAllNotifications}>
            <WppIconTick style={notificationReadAllTick} size="m" />
            <WppTypography type="s-strong" tag="h3" style={notificationReadAllAction}>Mark All As Read</WppTypography>
          </div>
        </div>
        <WppSegmentedControl value={state.activeTab} size="s" width="100%" hugContentOff>
          <WppSegmentedControlItem id={ELEMENT_ID.navBar.notificationBellActionRequired} value={ActiveTab.actions} onClick={() => handleTabChange(ActiveTab.actions)} counter={notificationsActionsUnreadCount}>Actions Required</WppSegmentedControlItem>
          <WppSegmentedControlItem value={ActiveTab.updates} onClick={() => handleTabChange(ActiveTab.updates)} counter={notificationsUpdatesUnreadCount}>Updates</WppSegmentedControlItem>
        </WppSegmentedControl>
      </div>
      <Notifications
        hasNotifications={_.gt(notificationCount, 0)}
        notifications={notifications}
        notificationsCount={notificationCount}
        onClickNotification={onClickNotification}
        onDataRangeChange={onDataRangeChange}
        closeNotificationDropdown={closeNotificationDropdown}
        state={state}
      />
    </>
  );
};

export default SegmentedControls;
