import _ from 'lodash';
import React from 'react';
import { WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderRow, WppTableHeaderCell, WppTypography, WppTag, WppIconTrash } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import { roleType } from 'constantsBase';
import { memberRoleColumns } from '../constants';
import { getMemberRoleForDisplay, orderAndGroupRolesForDisplay } from '../utils';
import { memberRolesTable } from '../style';

type Props = {
  roles: Array<{}>
  onClickRemove: Function
  userCanEditRoles: boolean
};

const getMember = (roles) => {
  const [{ role: { type } }] = roles;
  return type === roleType.global ? 'All Members Above' : '';
};

const displayMemberRole = (key, memberRole, userCanEditRoles, onClickRemove) => {
  const { member, roles } = memberRole;
  return (
    <WppTableBodyRow key={key}>
      <WppTableBodyCell style={memberRolesTable.memberColumnStyle}>
        <WppTypography tag="p" type="s-body">{member.displayName || getMember(roles)}</WppTypography>
      </WppTableBodyCell>
      <WppTableBodyCell>
        {member.dsp ? <DspIcon dspId={member.dsp} /> : null}
      </WppTableBodyCell>
      <WppTableBodyCell><WppTypography tag="p" type="s-body">{_.get(member, 'externalId')}</WppTypography></WppTableBodyCell>
      <WppTableBodyCell>
        {_.map(roles, (r, index) => (
          <div
            key={`${r.member ? r.member.id : 'admin'} - ${r.role.id}`}
            style={{
              ...memberRolesTable.actionStyle,
              paddingTop: _.isEqual(index, 0) ? '0px' : '8px',
            }}
          >
            <WppTag label={r.role.name} variant="neutral" />
            {userCanEditRoles ? <WppIconTrash size="m" slot="right" onClick={() => onClickRemove(r)} /> : null}
          </div>
        ))}
      </WppTableBodyCell>
    </WppTableBodyRow>
  );
};

const MemberRoleTable = ({ roles, onClickRemove, userCanEditRoles }: Props) => {
  const groupedRoles = orderAndGroupRolesForDisplay(roles);

  return (
    <WppTable>
      <WppTableHeader>
        <WppTableHeaderRow>
          {_.map(memberRoleColumns, (col) => <WppTableHeaderCell key={col.value}>{col.text}</WppTableHeaderCell>)}
          <WppTableHeaderCell />
        </WppTableHeaderRow>
      </WppTableHeader>
      <WppTableBody>
        {_.map(groupedRoles, (memberRole, i) => {
          const newMemberRole = getMemberRoleForDisplay(memberRole);
          return displayMemberRole(`row-${i}`, newMemberRole, userCanEditRoles, onClickRemove);
        })}
      </WppTableBody>
    </WppTable>
  );
};

export default MemberRoleTable;
