/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalState } from 'reducers';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { InputChangeEventDetail, PaginationChangeEventDetail } from '@wppopen/components-library';
import { WppGrid, WppInput, WppPagination, WppToastContainer, WppTypography } from 'buildingBlocks';
import BtnPageHeader from 'components/BtnPageHeader';
import DataLoading from 'components/DataLoading';
import DeletePanel from 'components/DeletePanel';
import { pluralizer } from 'utils/formattingUtils';
import { Permission } from 'utils/featureFlags';
import { ToastConfig, WppInputCustomEvent } from 'utils/types';
import {
  getMessages,
  selectAll,
  selectNone,
  selectMessages,
  clearStatusMsg,
  clearSelectedMessages,
  toggleModal as toggleModalAction,
} from './actions';
import {
  MESSAGES_ELEMENTS_PER_PAGE_OPTIONS,
  MESSAGES_STARTING_ELEMENTS_PER_PAGE,
} from './constants';
import MessageTable from './components/MessageTable';
import { State as MessageState } from './reducer';
import { messagesCustomHeader, messagesInputSearch } from './style';

const messagePluralizer = pluralizer('Message', 'Messages');
const REQUIRED_PERMISSIONS = [Permission.manageMessages];
const PAGE_NAME = 'Messages';

type CustomHeaderProps = {
  onSearchChange: (search: string) => void
};

export const CustomHeader = ({ onSearchChange }: CustomHeaderProps) => (
  <WppGrid item all={24} style={messagesCustomHeader}>
    <WppTypography tag="h1" type="3xl-heading">
      {PAGE_NAME}
    </WppTypography>
    <BtnPageHeader title={PAGE_NAME} onSearchChange={onSearchChange} permissions={REQUIRED_PERMISSIONS} />
  </WppGrid>
);

export const Messages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    messages,
    messagesCount,
    selectedMessages,
    deleteEnabledMessages,
    modalOpen,
    statusMessage,
    deleting,
    loading,
  } = useSelector<GlobalState>((state) => state.messages) as MessageState;

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(MESSAGES_STARTING_ELEMENTS_PER_PAGE);
  const [startingPage, setStartingPage] = useState<number>(1);

  const toastRef = useRef<HTMLWppToastContainerElement | null>(null);

  const showToast = (config: ToastConfig) => {
    toastRef.current?.addToast(config);
  };

  useEffect(() => {
    if (statusMessage) {
      showToast({
        header: statusMessage.header,
        type: statusMessage.isFailure ? 'error' : 'success',
        message: statusMessage.body,
        duration: 4000,
        maxMessageLines: 4,
      });
      dispatch(clearStatusMsg());
    }
  }, [statusMessage, dispatch]);

  const updatePaginationAndGetMessages = (limit, skip, search) => {
    setSearch(search);
    setLimit(limit);
    setStartingPage((skip / limit) + 1);
    getMessages(limit, skip, search, dispatch);
  };

  const browserUrlUpdate = (limit, skip, search) => {
    const newFilter = { search, limit, skip };
    navigate({
      pathname: location.pathname,
      search: `?${qs.stringify(newFilter)}`,
    });
  };

  useEffect(() => {
    const { limit, skip, search } = qs.parse(location.search.replace(/^\?/, ''));
    const parsedLimit = (_.toNumber(limit) > 0 && parseInt(limit as string, 10)) || MESSAGES_STARTING_ELEMENTS_PER_PAGE;
    const parsedSkip = (_.toNumber(skip) >= 0 && parseInt(skip as string, 10)) || 0;
    const parsedSearch = _.isUndefined(search) ? '' : search;
    updatePaginationAndGetMessages(parsedLimit, parsedSkip, parsedSearch);

    return () => {
      dispatch(clearStatusMsg());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, search, location.search]);

  const onMessageSelected = (_e, message) => {
    dispatch(selectMessages(message));
  };

  const onDataRangeChange = ({ limit, start }) => {
    dispatch(clearSelectedMessages());
    const skip = start;
    browserUrlUpdate(limit, skip, search);
    updatePaginationAndGetMessages(limit, skip, search);
  };

  const onSearchChange = (search) => {
    browserUrlUpdate(limit, 0, search);
    updatePaginationAndGetMessages(limit, 0, search);
  };

  const toggleModal = () => {
    dispatch(toggleModalAction());
  };

  const handleSelectAll = ({ checked }) => {
    if (checked) {
      dispatch(selectAll());
    } else {
      dispatch(selectNone());
    }
  };

  const deletePanelDisplayMsg = `
  ${_.size(selectedMessages)}
  Selected
  ${messagePluralizer(_.size(selectedMessages))}
`;

  const resetRevOptions = () => {
    dispatch(clearSelectedMessages());
  };

  const handleWppPaginationChange = (event: CustomEvent<PaginationChangeEventDetail>) => {
    const { itemsPerPage, page } = event.detail;
    onDataRangeChange({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
  };

  return (
    <WppPermissionPageTemplate
      name={PAGE_NAME}
      permissions={REQUIRED_PERMISSIONS}
      customHeader={<CustomHeader onSearchChange={onSearchChange} />}
    >
      <DataLoading loading={loading} pageName={PAGE_NAME}>
        <WppGrid>
          <WppToastContainer maxToastsToDisplay={10} ref={toastRef} />
          <div style={messagesInputSearch}>
            <WppInput
              placeholder="Search for messages"
              type="search"
              size="s"
              onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
                const searchVal = event.detail.value;
                onSearchChange(searchVal);
              }}
              value={search}
              style={{ width: '300px' }}
            />
            {!_.isEmpty(selectedMessages)
              && (
                <DeletePanel
                  toggleModal={toggleModal}
                  resetRevOptions={resetRevOptions}
                  displayMessage={deletePanelDisplayMsg}
                />
              )}
          </div>
          <MessageTable
            messages={messages}
            selectedMessages={selectedMessages}
            deleteEnabledMessages={deleteEnabledMessages}
            modalOpen={modalOpen}
            deleting={deleting}
            handleSelectAll={handleSelectAll}
            onMessageSelected={onMessageSelected}
            toggleModal={toggleModal}
            dispatch={dispatch}
          />
          <WppPagination
            count={messagesCount}
            onWppChange={handleWppPaginationChange}
            selectedItemPerPage={limit}
            itemsPerPage={MESSAGES_ELEMENTS_PER_PAGE_OPTIONS}
            activePageNumber={startingPage}
          />
        </WppGrid>
      </DataLoading>
    </WppPermissionPageTemplate>
  );
};

export default Messages;
