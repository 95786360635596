import React from 'react';
import { WppGrid, WppTypography, WppDivider } from 'buildingBlocks';
import { Datum } from 'utils/types';
import { containerStyle } from './style';

const DatumRow: React.FC<Datum> = ({ label, value }) => (
  <>
    <WppGrid container fullWidth style={containerStyle}>
      <WppGrid item all={4}>
        <WppTypography tag="p" type="s-strong">
          {label}
        </WppTypography>
      </WppGrid>
      <WppGrid item all={20}>
        {value}
      </WppGrid>
    </WppGrid>
    <WppDivider />
  </>
);

export default DatumRow;
