// Import libraries
import _ from 'lodash';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { User as UserType } from 'utils/types';

// Import components
import ABInsightsForm from 'containers/ABInsights/components/ABInsightsForm';
import ABInsightsResults from 'containers/ABInsights/components/ABInsightsResults';
import DemoSites from 'containers/DemoSites';
import FlightExtIdRedirect from './components/FlightExtIdRedirect';
import App from './containers/App/index';
import Brands from './containers/Brands/index';
import BrandDetails from './containers/Brands/BrandDetails';
import CopilotHealth from './containers/CopilotHealth/index';
import Dashboard from './containers/Dashboard';
import JobDetail from './containers/JobDetail';
import Jobs from './containers/Jobs';
import StrategyAnalytics from './containers/StrategyAnalytics';
import Seats from './containers/Seats';
import Messages from './containers/Messages';
import StrategiesList from './containers/StrategiesList';
import StrategyWizard from './containers/StrategyWizard';
import StrategyFlightRuns from './containers/StrategyFlightRuns';
import Users from './containers/Users';
import UserSettings from './containers/UserSettings';
import Roles from './containers/Roles';
import RoleDetail from './containers/Roles/Role';
import PageNotFound from './containers/404';
import ABInsightsRoutes from './containers/ABInsights';
import UserGroupsTool from './containers/UserGroups';
import AuthToken from './containers/AuthToken';
import APIToken from './containers/DeveloperToken';
import NewUser from './containers/NewUser';
import FlightSync from './containers/FlightSync';
import WhitelistIP from './containers/WhitelistIP';
import NotificationCenter from './containers/NotificationCenter';

import { WizardSteps, WIZARD_STEPS } from './containers/StrategyWizard/constants';
import User from './containers/User/User';

type InnerRoutesProps = {
  isAuthenticatedWithoutUser: boolean
  user: UserType
};

const isNewUser = (isAuthenticatedWithoutUser: boolean, user: UserType) => isAuthenticatedWithoutUser || _.isEmpty(_.get(user, 'roles'));

export const InnerRoutes = ({ isAuthenticatedWithoutUser, user }: InnerRoutesProps) => {
  const rootComponent = isNewUser(isAuthenticatedWithoutUser, user) ? (<NewUser user={user} />) : (<StrategiesList />);
  return (
    <div id="cp-main-container" style={{ paddingBottom: '30px' }}>
      <Routes>
        <Route path="/health/*" element={<CopilotHealth />} />
        <Route path="/strategies" element={<StrategiesList />} />
        <Route path={`/strategies/wizard/:id/${WIZARD_STEPS[WizardSteps.goalSelectionStep].id}/scripts/*`} element={<StrategyWizard />} />
        <Route path={`/strategies/wizard/${WIZARD_STEPS[WizardSteps.goalSelectionStep].id}/scripts/*`} element={<StrategyWizard />} />
        <Route path="/strategies/wizard/:id/:step/*" element={<StrategyWizard />} />
        <Route path="/strategies/wizard/:step/*" element={<StrategyWizard />} />
        <Route path="/strategies/wizard" element={<StrategyWizard />} />
        {/* this needs to be below wizard, otherwise 'wizard' will be considered to be an id for analytics */}
        <Route path="/strategies/wizard/*" element={<Navigate to={`/strategies/wizard/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`} replace />} />
        <Route index path="/strategies/:id/*" element={<StrategyAnalytics />} />
        <Route index path="*" element={<StrategyWizard />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/brands/create" element={<BrandDetails />} />
        <Route path="/brands/:id" element={<BrandDetails />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/jobs/:jobId" element={<JobDetail />} />
        <Route path="/members" element={<Seats />} />
        <Route path="/users" element={<Users />} />
        <Route path="/usersettings" element={<UserSettings />} />
        <Route path="/strategyflightruns/dashboard" element={<Dashboard />} />
        <Route path="/users/create" element={<User />} />
        <Route path="/users/:userId" element={<User />} />
        <Route path="/userprofile" element={<User />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/roles/create" element={<RoleDetail />} />
        <Route path="/roles/:roleId" element={<RoleDetail />} />
        <Route path="/strategyflightruns" element={<StrategyFlightRuns />} />
        <Route path="/abinsights" element={<ABInsightsRoutes />} />
        <Route path="/abinsights/1" element={<ABInsightsForm />} />
        <Route path="/abinsights/2" element={<ABInsightsResults />} />
        <Route path="/authtoken" element={<AuthToken />} />
        <Route path="/developertoken" element={<APIToken />} />
        <Route path="/usergroups" element={<UserGroupsTool />} />
        <Route path="/flightsync" element={<FlightSync />} />
        <Route path="/notifications" element={<NotificationCenter />} />
        <Route path="/whitelistip" element={<WhitelistIP />} />
        <Route path="/demosites" element={<DemoSites />} />
        <Route path="/" element={rootComponent} />
        <Route path="/flightExtId/:flightExtId/flightExtType/:flightExtType" element={<FlightExtIdRedirect />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default () => (
  <>
    <DocumentTitle title="Login" />
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </>
);
