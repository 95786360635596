import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS }, WPP } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const messageCountLabel: CSSProperties = {
  borderRadius: '10px',
  margin: '-10em 0 0 -1.5em !important',
  padding: '5px',
  backgroundColor: '#f77021',
  borderColor: '#f77021',
  color: 'white',
  top: '-4px',
};

export const notificationsOptions: CSSProperties = {
  color: 'gray',
  fontWeight: 'normal',
};

export const rowFormat: CSSProperties = {
  padding: '16px 0 0 16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const popupHeaderStyle: CSSProperties = {
  minWidth: '112px',
  maxWidth: '112px',
};

export const popupTitleStyle: CSSProperties = {
  ...rowFormat,
  lineHeight: '20px !important',
  padding: '4px 0 0 8px',
  fontSize: SPACING[12],
  fontWeight: 600,
  color: NEUTRALS.N600_ABBEY,
  justifyContent: 'flex-start',
};

export const popupLinkColumnStyle: CSSProperties = {
  minWidth: '240px',
};

export const flexRowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export const notificationTriggerStyle: CSSProperties = {
  ...flexRowStyle,
  minWidth: SPACING[32],
  justifyContent: 'center',
  cursor: 'pointer',
};

export const circleCountTextStyle: CSSProperties = {
  color: 'var(--wpp-primary-color-500)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '22px',
  minWidth: '22px',
  height: '22px',
  borderRadius: '22px',
  backgroundColor: 'var(--wpp-primary-color-100)',
};

export const NOTIFICATION_STYLES: { [key: string]: CSSProperties } = {
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    maxHeight: '600px',
    minHeight: '300px',
    width: '406px',
    justifyContent: 'space-between',
  },
  noNotifications: {
    display: 'flex',
    justifyContent: 'center',
  },
  notificationsStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    overflowY: 'auto',
    maxHeight: '600px',
    padding: '0px 0px 2px 0px',
  },
  strategyInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  strategyInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[4],
    cursor: 'pointer',
  },
  strategyStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: SPACING[4],
  },
  strategySuccessStatus: {
    color: 'var(--wpp-text-color-success)',
  },
  strategyAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notificationHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    padding: '16px 16px 0px 16px',
  },
  notificationItem: {
    boxShadow: '0px 1px 5px 0px #343A3F1A, 0px 0px 1px 0px #343A3F1A',
    padding: SPACING[12],
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[8],
    margin: '0px 16px',
  },
  notificationItemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: SPACING[8],
  },
  notificationItemHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: SPACING[8],
  },
  notificationAgeStyle: {
    color: 'var(--wpp-grey-color-600)',
    minWidth: 'fit-content',
  },
  notificationItemDescContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: SPACING[2],
  },
  notificationItemDescAction: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 'max-content',
    justifyContent: 'end',
    cursor: 'pointer',
  },
  notificationFooter: {
    display: 'flex',
    flexDirection: 'column',
    gap: SPACING[12],
    padding: '12px 16px 16px 16px',
  },
  notificationPagination: {
    display: 'flex',
    justifyContent: 'end',
  },
  notificationFooterAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  notificationHeaderElements: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  notificationReadAllAction: {
    color: WPP.grey900,
  },
  notificationReadAllContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '37%',
  },
  notificationReadAllTick: {
    color: WPP.grey900,
    marginTop: SPACING[2],
  },
};
