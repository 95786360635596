/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { WppGrid, WppInlineMessage } from 'buildingBlocks';
import { DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import {
  BudgetGroupOptions, ChildOptions, BudgetGroup as BudgetGroupType, TooltipsDescriptions, BudgetInterval, BudgetGroupLineItemMapping, GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { displayCurrencyOrImps } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { BudgetSetting, StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import { Currency } from 'utils/types';
import BudgetGroup from './BudgetGroup/BudgetGroup';
import DefaultGroupBanner from './BudgetGroup/DefaultGroupBanner';
import BudgetParentObject from './BudgetParent/BudgetParentObject';
import { BUDGET_MANAGEMENT_STYLES } from './style';
import { getAllNonAttachedLineItems, getAttachedLineItems } from './utils';

const { budgetGroup: { budgetGroupContainer, bannerMessage, container } } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupsProps = {
  groupedOptions: BudgetGroupOptions
  lifeTimeBudget: number
  requiredDailyValue: number
  tooltips: TooltipsDescriptions
  interval: BudgetInterval | BudgetSetting
  strategyName: string
  isCrossPlatformOptimization: boolean
  defaultCurrency: Currency
  remainingSpendDays: number
  allActiveChildOptions: ChildOptions
  budgetGroups: GroupSettings
  flightExtType: number
  flightTimezone: string
  hasNMSPermission: boolean
};

const BudgetGroups = ({
  groupedOptions,
  lifeTimeBudget,
  requiredDailyValue,
  tooltips,
  interval,
  strategyName,
  isCrossPlatformOptimization,
  defaultCurrency,
  remainingSpendDays,
  allActiveChildOptions,
  budgetGroups,
  flightExtType,
  flightTimezone,
  hasNMSPermission,
}: BudgetGroupsProps) => {
  const [budgetGroupToLineItems, setBudgetGroupToLineItems] = useState<BudgetGroupLineItemMapping>({});
  const { wizardFormValues: { strategyConfigurationStep } } = useStrategyWizardContext();
  const contextGroupSettings = _.get(strategyConfigurationStep, 'groupSettings');
  const { formState: { errors } } = useFormContext<StrategyConfigurationStep>();

  const filteredGroupSettings = _.omit(budgetGroups, [DEFAULT_GROUP_ID, DEFAULT_PG_GROUP_ID]);
  const generalAllocationRangeError = _.get(errors, 'groupSettings.minMaxAggregateValidation.message');
  const budgetMetric = isCrossPlatformOptimization
    ? defaultCurrency.code
    : displayCurrencyOrImps((interval as BudgetInterval));

  const { nonAttachedReg, nonAttachedPG } = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);

  useEffect(() => {
    // only want this running in edit flow to populate previous budgetGroupToLineItem mapping
    if (_.size(allActiveChildOptions) && _.size(contextGroupSettings)) {
      const prevBudgetGroup = {};
      _.forEach(filteredGroupSettings, (budgetGroup: BudgetGroupType, groupKey: string) => {
        const groupsAttachedLineItem = getAttachedLineItems(budgetGroup.childExtIds, allActiveChildOptions);
        prevBudgetGroup[groupKey] = groupsAttachedLineItem;
      });
      setBudgetGroupToLineItems(prevBudgetGroup);
    }
  }, [budgetGroups, allActiveChildOptions, contextGroupSettings]);

  const nonAttachedRegLineItemsCount = _.size(nonAttachedReg);
  const nonAttachedPGLineItemsCount = _.size(nonAttachedPG);
  // PG banner shown when budget groups are created
  const shouldShowPGBanner = (!!nonAttachedPGLineItemsCount && hasNMSPermission);
  const hasBudgetGroups = !!_.size(filteredGroupSettings);
  // initial PG banner shown when user hasn't created any budget groups yet
  const shouldShowInitialPGBanner = !hasBudgetGroups && shouldShowPGBanner;
  const showAnyBanner = shouldShowPGBanner || !!nonAttachedRegLineItemsCount;
  const containerGap = !shouldShowInitialPGBanner && !hasBudgetGroups ? 16 : 20;

  return (
    <WppGrid container item all={24} style={{ display: 'flex', gap: COPILOT_LAYOUT.SPACING[containerGap], padding: 0 }}>
      <BudgetParentObject
        interval={interval}
        tooltips={tooltips}
        strategyName={strategyName}
        lifeTimeBudget={lifeTimeBudget}
        requiredDailyValue={requiredDailyValue}
        budgetMetric={budgetMetric}
        flightExtType={flightExtType}
        flightTimezone={flightTimezone}
      />
      <div style={{ ...container, ...((hasBudgetGroups && showAnyBanner) && { marginBottom: COPILOT_LAYOUT.SPACING[16] }) }}>
        {hasBudgetGroups && (
          <>
            <WppGrid style={budgetGroupContainer} container fullWidth all={24}>
              {_.map(filteredGroupSettings, (budgetGroup: BudgetGroupType, key: string) => (
                <BudgetGroup
                  key={key}
                  groupedOptions={groupedOptions}
                  lifeTimeBudget={lifeTimeBudget}
                  budgetGroup={budgetGroup}
                  budgetGroupKey={key}
                  budgetGroupToLineItems={budgetGroupToLineItems}
                  setBudgetGroupToLineItems={setBudgetGroupToLineItems}
                  requiredDailyValue={requiredDailyValue}
                  budgetMetric={budgetMetric}
                  remainingSpendDays={remainingSpendDays}
                  interval={interval}
                  allActiveChildOptions={allActiveChildOptions}
                  budgetGroups={budgetGroups}
                  hasNMSPermission={hasNMSPermission}
                />
              ))}
            </WppGrid>
            <div style={container}>
              {generalAllocationRangeError && (
              <WppGrid container item all={24} fullWidth>
                <WppInlineMessage
                  size="m"
                  style={bannerMessage}
                  message={generalAllocationRangeError}
                  type="error"
                  showTooltipFrom={100}
                />
              </WppGrid>
              )}
              {!!nonAttachedRegLineItemsCount && (<DefaultGroupBanner nonAttachedLineItemsCount={nonAttachedRegLineItemsCount} />)}
              {shouldShowPGBanner && (<DefaultGroupBanner nonAttachedLineItemsCount={nonAttachedPGLineItemsCount} isPGBanner />)}
            </div>
          </>
        )}
        {shouldShowInitialPGBanner && (
          <div style={{ marginBottom: COPILOT_LAYOUT.SPACING[16] }}>
            <DefaultGroupBanner nonAttachedLineItemsCount={nonAttachedPGLineItemsCount} isPGBanner />
          </div>
        )}
      </div>
    </WppGrid>
  );
};

export default BudgetGroups;
