/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import { WppGrid, WppIconInfo, WppTooltip, WppTypography } from 'buildingBlocks';
import TimePicker from 'components/TimePicker';
import { TOOLTIP_DESCRIPTIONS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { TIME_FORMAT, roundToNearest15Min } from 'utils/dateTime';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import DatePicker from './CustomDatePicker';
import '../override.css';
import { handleDisabledMins } from '../utils';
import { messageFormGridText, messageFormStartDate } from '../style';

const disabledMinutes = _.memoize(handleDisabledMins);
const DatePickerWrapper = validatedFieldWrapper(DatePicker);

const StartDateTime = () => {
  const { control } = useFormContext();

  return (
    <WppGrid>
      <div style={{ ...messageFormStartDate, ...messageFormGridText }}>
        <WppTypography tag="p" type="s-strong">Publish Start Date & Time in (UTC)</WppTypography>
        <WppTooltip text={_.get(TOOLTIP_DESCRIPTIONS, 'startDateTime')} config={{ placement: 'bottom' }}>
          <WppIconInfo />
        </WppTooltip>
      </div>
      <div style={messageFormStartDate}>
        <WppGrid>
          <Controller
            name="startDate"
            control={control}
            render={(props) => (
              <DatePickerWrapper
                placeholder="Select Start Date"
                startDate={props.field.value}
                minDate={moment()}
                onChange={props.field.onChange}
                selected={props.field.value}
                closeOnSelect
                hasMinDate
                {..._.omit(props, 'formState')}
              />
            )}
          />
        </WppGrid>
        <WppGrid>
          <Controller
            name="startTime"
            control={control}
            render={(props) => (
              <TimePicker
                input={{ value: moment(props.field.value), onChange: props.field.onChange }}
                defaultValue={roundToNearest15Min(props.field.value)}
                defaultOpenValue={roundToNearest15Min(props.field.value)}
                placeholder="Enter Time"
                className="messagesWppTimePicker"
                disabledMinutes={disabledMinutes}
                hideDisabledOptions
                showSecond={false}
                allowEmpty={false}
                format={TIME_FORMAT}
                use12Hours
                {..._.omit(props, 'formState')}
              />
            )}
          />
        </WppGrid>
      </div>
    </WppGrid>
  );
};

export default StartDateTime;
