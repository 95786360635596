import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  WppGrid,
  WppTypography,
  WppIconPlus,
  WppButton,
  WppTable,
  WppTableHeader,
  WppTableHeaderRow,
  WppTableHeaderCell,
  WppTableBody,
  WppTableBodyRow,
  WppTableBodyCell,
  WppIconEdit,
  WppTag,
  WppInput,
  WppIconSearch,
  WppActionButton,
} from 'buildingBlocks';
import { roleType, ADMIN_ROLE_ID } from 'constantsBase';
import { Permission } from 'utils/featureFlags';
import withRouter from 'utils/withRouter';
import { GlobalState } from 'reducers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import DataLoading from 'components/DataLoading';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import PermissionWrapper from 'components/PermissionWrapper';
import { InputChangeEventDetail, WppInputCustomEvent, Role as RoleDataType } from 'utils/types';
import { getRoles } from './actions';
import { noBorderRadius, rolesTableStyle, customHeaderContainer } from './style';
import { roleListingTableColumns } from './constants';

const REQUIRED_PERMISSIONS = [Permission.manageRoles];
const PAGE_NAME = 'Roles';

export const CustomHeader: React.FC = (): React.ReactElement => (
  <WppGrid container fullWidth>
    <WppGrid item all={24} style={customHeaderContainer}>
      <WppTypography tag="h1" type="3xl-heading">
        {PAGE_NAME}
      </WppTypography>
      <PermissionWrapper unauthorizedComponent={<></>} permissions={REQUIRED_PERMISSIONS}>
        <Link to="/roles/create">
          <WppButton>
            <WppIconPlus slot="icon-start" /> New Role
          </WppButton>
        </Link>
      </PermissionWrapper>
    </WppGrid>
  </WppGrid>
);

type Props = {
  roles: Array<RoleDataType>
  getRoles: Function
  loading: boolean
};

export const Roles: React.FC<Props> = () => {
  const [filteredRoles, setFilteredRoles] = useState<Props['roles']>([]);
  const { roles, loading } = useSelector<GlobalState, { roles: Array<RoleDataType>; loading: boolean }>(
    (state) => state.roles,
  );
  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getRoles());
  });

  useEffect(() => {
    setFilteredRoles(roles);
  }, [roles]);

  const handleInputChange = (event) => {
    const value = _.toLower(event.target.value);
    setFilteredRoles(
      value
        ? _.filter(roles, (role) => _.includes(_.toLower(role.name), value)
            || _.includes(_.toLower(role.description), value)
            || _.includes(_.toString(role.id), value))
        : roles,
    );
  };

  return (
    <DataLoading loading={loading} pageName={PAGE_NAME}>
      <WppGrid container fullWidth>
        <WppGrid item all={4}>
          <WppInput
            name="search"
            placeholder="Search for roles"
            size="s"
            onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => handleInputChange(event)}
          >
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
          </WppInput>
        </WppGrid>
        <WppGrid item all={24}>
          <WppTable style={noBorderRadius}>
            <WppTableHeader>
              <WppTableHeaderRow>
                {_.map(roleListingTableColumns, (column) => (
                  <WppTableHeaderCell key={column.value}>
                    <WppTypography type="s-strong">{column.text}</WppTypography>
                  </WppTableHeaderCell>
                ))}
                <WppTableHeaderCell />
              </WppTableHeaderRow>
            </WppTableHeader>
            <WppTableBody>
              {_.size(filteredRoles) === 0 ? (
                <WppTableBodyRow style={rolesTableStyle.roleBodyRowStyle}>
                  <WppTableBodyCell colSpan={5}>
                    <div style={rolesTableStyle.roleNoRecordStyle}>
                      <WppTypography type="s-body">No Roles Found</WppTypography>
                    </div>
                  </WppTableBodyCell>
                </WppTableBodyRow>
              ) : (
                _.map(filteredRoles, (role) => (
                  <WppTableBodyRow key={role.id} style={rolesTableStyle.roleBodyRowStyle}>
                    <WppTableBodyCell style={rolesTableStyle.roleIdStyle}>
                      <WppTypography type="s-body">{role.id}</WppTypography>
                    </WppTableBodyCell>
                    <WppTableBodyCell style={rolesTableStyle.roleNameStyle}>
                      <WppTypography type="s-body">{role.name}</WppTypography>
                    </WppTableBodyCell>
                    <WppTableBodyCell style={rolesTableStyle.roleDescriptionStyle}>
                      <WppTypography type="s-body">{role.description}</WppTypography>
                    </WppTableBodyCell>
                    <WppTableBodyCell style={rolesTableStyle.roleEditIconStyle}>
                      <WppTag
                        label={_.upperFirst(_.findKey(roleType, (key) => key === role.type))}
                        variant={role.type === 1 ? 'positive' : undefined}
                        categoricalColorIndex={1}
                      />
                    </WppTableBodyCell>
                    <WppTableBodyCell style={rolesTableStyle.roleEditIconStyle}>
                      <Link to={`/roles/${role.id}`}>
                        <WppActionButton disabled={role.id === ADMIN_ROLE_ID}>
                          <WppIconEdit className={role.id === ADMIN_ROLE_ID ? 'wpp-icon-disabled' : ''} />
                        </WppActionButton>
                      </Link>
                    </WppTableBodyCell>
                  </WppTableBodyRow>
                ))
              )}
            </WppTableBody>
          </WppTable>
        </WppGrid>
      </WppGrid>
    </DataLoading>
  );
};

const PermissionRoles = (props) => (
  <WppPermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    customHeader={<CustomHeader />}
    permissions={REQUIRED_PERMISSIONS}
  >
    <Roles {...props} />
  </WppPermissionPageTemplate>
);

export default withRouter(PermissionRoles);
