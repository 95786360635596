import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const noBorderRadius = {
  borderRadius: 0,
  borderStyle: 'none',
};

export const inputField = {
  width: '100%',
  borderRadius: 0,
};

export const customHeaderContainer = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const rolesTableStyle = {
  roleBodyRowStyle: {
    height: '48%',
  },
  roleIdStyle: {
    width: '3%',
  },
  roleNameStyle: {
    width: '45%',
  },
  roleDescriptionStyle: {
    width: '42%',
  },
  roleTagStyle: {
    width: '8%',
  },
  roleEditIconStyle: {
    width: '2%',
  },
  roleNoRecordStyle: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export const roleDetailStyle = {
  roleDetailFooterBtnContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0px 0px 0px',
    marginBottom: SPACING[32],
    width: '98%',
    gap: SPACING[8],
  },
  roleFieldLabelStyle: {
    marginBottom: SPACING[8],
  },
  roleDropDownContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  roleInputContainerStyle: {
    marginBottom: SPACING[20],
  },
};

export const deleteRoleConfrimStyle = {
  deleteBtnStyle: {
    marginTop: SPACING[20],
    position: 'relative',
    right: '10px',
  },
  deleteConfrimModelActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: SPACING[8],
  },
};
