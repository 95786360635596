import { Remarkable } from 'remarkable';
import { Chatbot } from 'utils/copilotAPI';
import { Feedback, FeedbackMessagesType } from './constants';

const md = new Remarkable();

export const copyChatbotResp = (markdownText: string) => {
  // convert the markdown to HTML
  const html = md.render(markdownText);

  // strip HTML tags to get plain text
  const div = document.createElement('div');
  div.innerHTML = html;
  const textToCopy = div.textContent || div.innerText || '';
  navigator.clipboard.writeText(textToCopy);
};

export const sendFeedback = async (
  feedbackMessages: FeedbackMessagesType,
  feedback: Feedback,
  setShowThumb: (x: boolean) => void,
) => {
  try {
    // temporarily show done icon to the user
    setShowThumb(false);

    // make request to chatbot feedback endpoint via copilot-ui proxy
    await Chatbot.request({
      chat_history: feedbackMessages,
      feedback,
    });
  } catch (err) {
    console.error(err);
  } finally {
    setShowThumb(true);
  }
};
