import React, { createContext, Dispatch, ReactNode, Reducer, useContext, useMemo, useReducer } from 'react';
import strategyWizardReducer from './reducer';
import { INITIAL_WIZARD_VALUES_FOR_UI } from '../constants';
import { WizardFormValues, StrategyWizardAction } from '../types';

type StrategyWizardContextValue = {
  wizardFormValues: WizardFormValues
  dispatch: Dispatch<StrategyWizardAction>
};

const StrategyWizardContext = createContext<StrategyWizardContextValue | null>(null);

type Props = {
  children: ReactNode
};

const StrategyWizardProvider = ({ children }: Props) => {
  const [wizardFormValues, dispatch] = useReducer<Reducer<WizardFormValues, StrategyWizardAction>>(strategyWizardReducer, INITIAL_WIZARD_VALUES_FOR_UI);

  const providerValues = useMemo(() => ({
    wizardFormValues,
    dispatch,
  }), [wizardFormValues]);

  return (
    <StrategyWizardContext.Provider value={providerValues}>
      {children}
    </StrategyWizardContext.Provider>
  );
};

export const useStrategyWizardContext = () => {
  const context = useContext(StrategyWizardContext);
  if (!context) {
    throw new Error('useStrategyWizardContext must be used within a GoalSectionProvider');
  }
  return context;
};

export default StrategyWizardProvider;
