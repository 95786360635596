import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { WppGrid } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import { roleType } from 'constantsBase';
import { Permission } from 'utils/featureFlags';
import { Member, Role, User } from 'utils/types';
import MemberRoleSelector from './MemberRoleSelector';
import MemberRoleTable from './MemberRoleTable';
import {
  computeMemberOptions,
  computeRoleOptions,
  computeMemberRoleAdded,
  filterValidMemberRoles,
  computeRegionOptions,
  MemberRole,
} from '../utils';
import { MemberRoleState, MemberRoleToDelete, MembersByRegion, UserForm } from '../types';
import { updateMemberRoles } from '../actions';
import { memberRoleError } from '../style';

const REQUIRED_PERMISSIONS_TO_ADD_ROLES = [Permission.manageUserRoles];

type Props = {
  userCanEditRoles: boolean
  disabled: boolean
  selectedMembers: Array<Member>
  selectedRoles: Array<Role>
  isNewUser: boolean
};

const MemberRoleComponent = (props: Props) => {
  const { userCanEditRoles, disabled, selectedRoles, selectedMembers, isNewUser } = props;
  const userToViewOrEdit = useSelector<GlobalState>((rootState) => rootState.user) as User;
  const {
    members,
    memberRoleMap,
    memberRoles,
    memberRolesToSave,
    memberRolesToDelete,
    roles,
  } = userToViewOrEdit;

  const dispatch = useDispatch();
  const { setValue } = useFormContext<UserForm>();
  const validMemberRoles = filterValidMemberRoles(memberRoles, memberRoleMap);
  const assignedMemberRoles = _.concat(memberRoles, memberRolesToSave) as Array<MemberRole>;

  const [memberRoleState, setMemberRoleState] = useState<MemberRoleState>({
    roleOptions: computeRoleOptions(roles, assignedMemberRoles),
    membersPerRoles: computeMemberOptions(selectedRoles, members, memberRoleMap, assignedMemberRoles),
    memberRoles: validMemberRoles,
    regions: computeRegionOptions(members),
    error: '',
  });
  const previousProps = useRef(userToViewOrEdit);

  useEffect(() => {
    const validRoles = filterValidMemberRoles(memberRoles, memberRoleMap);
    const assignedRoles = _.concat(memberRoles, memberRolesToSave) as Array<MemberRole>;
    const membersPerRoles = computeMemberOptions(selectedRoles, members, memberRoleMap, assignedRoles);
    const checkSelectedMembers = _.intersectionBy(selectedMembers, membersPerRoles, 'id');

    setMemberRoleState((prevState) => ({
      ...prevState,
      roleOptions: computeRoleOptions(roles, assignedRoles),
      membersPerRoles,
      memberRoles: validRoles,
    }));

    if (selectedMembers && selectedMembers.length !== checkSelectedMembers.length) {
      setValue('selectedMembers', checkSelectedMembers);
    }

    if (!_.isEqual(previousProps.current.members, members)) {
      setMemberRoleState((prevState) => ({ ...prevState, regions: computeRegionOptions(members) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoles]);

  const onClickRemove = (memberRoleToDelete: MemberRoleToDelete) => {
    let memberRolesToSaveCopy;
    let memberRolesCopy;
    let memberRolesToDeleteCopy;

    // if memberRole come from database, it has an id
    if (memberRoleToDelete.id) {
      memberRolesCopy = _.reject(memberRoles, ({ id }) => _.isEqual(id, memberRoleToDelete.id));
      memberRolesToDeleteCopy = [...memberRolesToDelete, memberRoleToDelete];
    } else {
      const filterMemberRole = _.pick(memberRoleToDelete, ['member.id', 'role.id']);
      memberRolesToSaveCopy = _.reject(memberRolesToSave, ({ member, role }) => _.isEqual(role?.id, filterMemberRole.role?.id) && _.isEqual(member?.id, filterMemberRole.member?.id));
    }

    dispatch(updateMemberRoles({
      memberRolesToSave: memberRolesToSaveCopy,
      memberRoles: memberRolesCopy,
      memberRolesToDelete: memberRolesToDeleteCopy,
    }));
  };

  const createMemberRolesCombination = () => {
    const memberRolesCopy = [...memberRoles];
    const memberRolesToSaveCopy = [...memberRolesToSave];
    const memberRolesToDeleteCopy = [...memberRolesToDelete];

    _.each(selectedRoles, (role: { type: number }) => {
      if (role.type === roleType.global) {
        computeMemberRoleAdded(undefined, role, memberRolesCopy, memberRolesToSaveCopy, memberRolesToDeleteCopy);
      } else {
        _.each(selectedMembers, (member) => {
          computeMemberRoleAdded(member, role, memberRolesCopy, memberRolesToSaveCopy, memberRolesToDeleteCopy);
        });
      }
    });
    dispatch(updateMemberRoles({ memberRolesToSave: memberRolesToSaveCopy, memberRoles: memberRolesCopy, memberRolesToDelete: memberRolesToDeleteCopy }));
    // Reset the dropdowns after adding to table.
    setValue('selectedMembers', []);
    setValue('selectedRoles', []);
  };

  const fillMemberByRegion = (region: MembersByRegion) => {
    const membersPerRoles = computeMemberOptions(selectedRoles, members, memberRoleMap, assignedMemberRoles);
    const checkSelectedMembers = _.intersectionBy(region.value, membersPerRoles, 'id');
    setValue('selectedMembers', _.union(selectedMembers, checkSelectedMembers));
  };

  const isValidMemberRoleState = !_.isEmpty(memberRolesToSave) || !_.isEmpty(memberRoleState.memberRoles);

  return (
    <WppGrid item all={24}>
      {userCanEditRoles && (
        <MemberRoleSelector
          memberRoleState={memberRoleState}
          selectedRoles={selectedRoles}
          selectedMembers={selectedMembers}
          onAddMemberRoles={createMemberRolesCombination}
          onRegionAdd={fillMemberByRegion}
          disabled={disabled}
        />
      )}
      {!_.isEmpty(memberRoleState.error) && <span style={memberRoleError}>{memberRoleState.error}</span>}
      {(isValidMemberRoleState || isNewUser) && (
        <MemberRoleTable
          roles={_.concat(memberRolesToSave, memberRoleState.memberRoles)}
          onClickRemove={onClickRemove}
          userCanEditRoles={userCanEditRoles && !disabled}
        />
      )}
    </WppGrid>
  );
};

const PermissionMemberRoleComponent = (props: React.JSX.IntrinsicAttributes & Props) => (
  <PermissionWrapper
    permissions={props.isNewUser ? [] : REQUIRED_PERMISSIONS_TO_ADD_ROLES}
  >
    <MemberRoleComponent {...props} />
  </PermissionWrapper>
);

export default PermissionMemberRoleComponent;
