import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextareaInputChangeEventDetail } from '@wppopen/components-library';
import { Form, WppInput, WppGrid, WppLabel, WppTypography } from 'buildingBlocks';
import { WidgetWrapper } from 'utils/widget';
import { WppTextareaInputCustomEvent } from '@wppopen/components-library/dist/types/components';
import { User, WppInputCustomEvent, InputChangeEventDetail } from 'utils/types';
import { APPROVER_PLACEHOLDER } from '../constants';
import { UserForm, Mode, ApproverValidationMode } from '../types';
import { isAdmin } from '../utils';
import RequestAccessSection from './RequestAccessSection';
import { EmailStatusMessage } from './StatusMessages';
import { userFormRowPadding, bottomPadding, approverHelpText } from '../style';

const ApproverHelpText = () => (
  <WppTypography type="xs-midi">
    Please input an approver email to manage your Copilot roles and permissions.
    Approvers must be existing Copilot users.
    For further support please reach out to the
    <a
      role="button"
      tabIndex={0}
      style={approverHelpText}
      onClick={() => WidgetWrapper('open')}
    >
      Copilot team
    </a>.
  </WppTypography>
);

type ApproverProps = {
  showRequestNotes: boolean
  showRequestAccessButton: boolean
  onChangeRequestNotes: (e: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => void
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLWppButtonElement >) => void
  approver: string
  showEmailStatus: boolean
  onChangeApprover: (value: string) => void
  approverValidationMode: ApproverValidationMode
  requestNotes: string
  disabled: boolean
  userToViewOrEdit: User
  mode: Mode
  sendEmailErrorMsg: string
};

const Approver = ({ showRequestNotes, showRequestAccessButton,
  onChangeRequestNotes, handleAccessRequestSubmit, approver,
  showEmailStatus, onChangeApprover,
  approverValidationMode, requestNotes,
  disabled, userToViewOrEdit, mode, sendEmailErrorMsg,
}: ApproverProps) => {
  const { control } = useFormContext<UserForm>();
  const email = useWatch({ name: 'email' });
  const emailApproverError = !isAdmin(userToViewOrEdit) && (approver === email);
  const showMessage = !approver && mode !== Mode.view;
  const showError = showMessage || emailApproverError || _.isEqual(approverValidationMode, ApproverValidationMode.invalid);
  const getMessage = (): string => {
    if (!showError) return '';

    if (emailApproverError) return 'User and approver emails must be different';

    if (_.isEqual(approverValidationMode, ApproverValidationMode.invalid)) {
      return 'Please enter valid approver email';
    }

    return 'Approver Required';
  };

  return (
    <>
      <WppGrid container fullWidth style={userFormRowPadding}>
        <WppGrid item all={12}>
          <Form.Field>
            <WppLabel
              config={{
                text: 'Approver',
                icon: 'wpp-icon-info',
                description: 'Setting an approver will allow you to edit or update your permissions.',
              }}
              htmlFor="approver"
              typography="s-strong"
              style={bottomPadding}
            />
            <Controller
              name="approver"
              control={control}
              render={() => (
                <WppInput
                  name="approver"
                  value={approver}
                  disabled={disabled}
                  placeholder={APPROVER_PLACEHOLDER}
                  message={getMessage()}
                  messageType={showError ? 'error' : null}
                  onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => onChangeApprover(e.detail.value)}
                  required
                />
              )}
            />
            {showMessage && (<ApproverHelpText />)}
          </Form.Field>
        </WppGrid>
      </WppGrid>
      {showEmailStatus && (
        <EmailStatusMessage sendEmailErrorMsg={sendEmailErrorMsg} />
      )}
      {!showEmailStatus && (
        <RequestAccessSection
          showRequestNotes={showRequestNotes}
          showRequestAccessButton={showRequestAccessButton}
          onChangeRequestNotes={onChangeRequestNotes}
          handleAccessRequestSubmit={handleAccessRequestSubmit}
          approver={approver}
          requestNotes={requestNotes}
        />
      )}
    </>
  );
};

export default Approver;
