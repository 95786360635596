import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React, { MutableRefObject } from 'react';
import { Icon, Loader, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTypography } from 'buildingBlocks';
import { GoalSuccessEvent, MetricsFormattingConstants } from 'constantsBase';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { displayDate } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { Flight } from 'utils/types';
import { BUDGET_INTERVALS } from '../styles';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import RevenueTypeConfiguration from './RevenueTypeSection/RevenueTypeConfiguration';

const { activeBullet, futureIntervalTextStyle, singleBudgDelivHeader, singleBudgetDel } = BUDGET_INTERVALS;

type BudgetIntervalProps = {
  startDate: string
  endDate: string
  budgetAmount: number
  showLabels: boolean
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: Function
  finishCalculations: MutableRefObject<boolean>
  delivery?: number
  budgetImps?: number
};

const formatBudget = (budgetAmount: number, budgetType: string, budgetImps: number) => {
  if (budgetType === BudgetTypes.imps) {
    return numeral(budgetImps).format(MetricsFormattingConstants.NO_DECIMALS);
  }
  return numeral(budgetAmount).format(MetricsFormattingConstants.TWO_DECIMALS);
};

const formatBudgetUnit = (currency: string, budgetType: string) => {
  if (budgetType === BudgetTypes.imps) {
    return _.upperCase(GoalSuccessEvent.impressions);
  }
  return currency;
};

const BudgetInterval = ({
  endDate, startDate, budgetAmount, showLabels, delivery,
  budgetImps, initialFormValues, resetConfirmedGoal, finishCalculations,
}: BudgetIntervalProps) => {
  const {
    currencyCode: currency, budgetType, strategyId, hasRevTypePermission, revenueTypeEnabled,
    wizardFormValues: {
      attachFlightsStep: { attachedFlights },
      budgetAllocationState,
    },
  } = useGoalSectionContext();
  const flight: Flight = _.head(attachedFlights);
  const { timezone, externalType: flightExtType, externalId: flightExtId } = flight;
  const budgetData = _.get(budgetAllocationState, 'data');
  const cumDataLatest = _.last(_.get(budgetData, `${flightExtId}.parentData.cumData`));
  const deliveryInImps = _.get(cumDataLatest, 'imps');
  const intervalActive = moment(startDate).isBefore(moment(), 'day');
  const formattedDelivery = _.isNumber(delivery) && numeral(delivery).format(MetricsFormattingConstants.NO_DECIMALS);
  const startDateTodayOrPast = moment(startDate).isSameOrBefore(moment(), 'day');
  const revenueCalculationText = revenueTypeEnabled ? 'Customize' : 'Platform Reported';

  const getDelivery = () => {
    if (!startDateTodayOrPast) return 'Scheduled';
    if (!_.isNumber(delivery) || !intervalActive) return '0';
    if (!finishCalculations.current && strategyId) return <Loader size="mini" inline active />;
    if (budgetType === BudgetTypes.imps) {
      return `${numeral(deliveryInImps).format(MetricsFormattingConstants.NO_DECIMALS)} ${_.upperCase(GoalSuccessEvent.impressions)}`;
    }
    return `${formattedDelivery} ${currency}`;
  };

  return (
    <WppTableBody className="wpp-tbody">
      <WppTableBodyRow>
        <WppTableBodyCell>
          {startDateTodayOrPast && <Icon name="circle" size="small" style={activeBullet} />}
        </WppTableBodyCell>
        <WppTableBodyCell>
          <WppTypography type="s-body" tag="p">{displayDate(startDate, flightExtType, timezone)}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell>
          <WppTypography type="s-body" tag="p">{displayDate(endDate, flightExtType, timezone)}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell style={singleBudgetDel}>
          <WppTypography type="s-body" tag="p">{formatBudget(budgetAmount, budgetType, budgetImps)} {formatBudgetUnit(currency, budgetType)}</WppTypography>
        </WppTableBodyCell>
        <WppTableBodyCell style={singleBudgDelivHeader}>
          {getDelivery()}
        </WppTableBodyCell>
        {hasRevTypePermission && (
          <WppTableBodyCell style={{ width: '212px' }}>
            {!showLabels
              ? <div style={futureIntervalTextStyle}>{revenueCalculationText}</div>
              : (
                <RevenueTypeConfiguration
                  initialFormValues={initialFormValues}
                  resetConfirmedGoal={resetConfirmedGoal}
                />
              )}
          </WppTableBodyCell>
        )}
      </WppTableBodyRow>
    </WppTableBody>
  );
};

export default BudgetInterval;
