import React from 'react';
import { WppSpinner, WppGrid } from 'buildingBlocks';
import WppPageTemplate from 'components/PageTemplate/WppPageTemplate';
import { pageLoader } from '../style';

const { fullHeightAndWidth } = pageLoader;

const PageLoader = () => (
  <WppPageTemplate>
    <WppGrid container fullWidth>
      <WppGrid
        item
        all={24}
        style={fullHeightAndWidth}
      >
        <WppSpinner size="l" />
      </WppGrid>
    </WppGrid>
  </WppPageTemplate>
);

export default PageLoader;
