import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Segment, WppGrid } from 'buildingBlocks';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { GOAL_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { assertUnreachable } from 'utils/types';
import PixelsLoading from './PixelsLoading';
import PixelPickerModal from './PixelPickerModal';
import { PixelStates, PixelLoadingState, DspToPixel, DspToPixelToWeightsType } from './types';

type Props = {
  // the pixels available for selection keyed by DSP
  pixels: DspToPixel
  // DSP pixels to default weights - used when goal type is engagement score
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  goalType: string
  defaultOpen: boolean
};

export const PixelPicker = ({
  pixels,
  defaultDspToPixelToWeights,
  goalType,
  defaultOpen,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(defaultOpen);

  const { control } = useFormContext();

  return (
    <WppGrid direction="row" style={{ width: '100%' }}>
      <WppGrid direction="column">
        <Segment basic style={GOAL_SECTION_STYLES.pixelPickerSegment}>
          <Controller
            name="goal.impValueFilters"
            control={control}
            render={({ field: { onChange } }) => (
              <PixelPickerModal
                pixels={pixels}
                defaultDspToPixelToWeights={defaultDspToPixelToWeights}
                goalType={goalType}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onChange={onChange}
              />
            )}
          />
        </Segment>
      </WppGrid>
    </WppGrid>
  );
};

type PixelPickerWrapperProps = {
  state: PixelLoadingState
  goalType: string
  impValueFilters: DspToPixel
};

const PixelPickerWrapper = ({ state, goalType, impValueFilters }: PixelPickerWrapperProps) => {
  const {
    wizardFormValues: {
      goalSelectionStep: { goal },
    },
  } = useStrategyWizardContext();
  const { type: contextGoalType, impValueFilters: contextImpValFilters } = goal;
  // set defaultOpen based on if form values are empty or goal type is changed
  const defaultOpen = _.isEmpty(impValueFilters) && (_.isEmpty(contextImpValFilters) || (contextGoalType !== goalType));

  switch (state.kind) {
    case PixelStates.initial:
    case PixelStates.loading:
      return (
        // if create mode, automatically open
        <PixelsLoading />
      );
    case PixelStates.success:
      return (
        <PixelPicker
          pixels={state.pixels}
          defaultDspToPixelToWeights={state.defaultWeights}
          goalType={goalType}
          defaultOpen={defaultOpen}
        />
      );
    case PixelStates.unsupported:
      return <></>;
    default:
      return assertUnreachable(state);
  }
};

export default PixelPickerWrapper;
