/* eslint-disable no-restricted-imports */
import _ from 'lodash';
import moment, { Moment } from 'moment';
import momentTimeZone from 'moment-timezone';
import { TimeZoneValue } from 'containers/Jobs/types';
import { zpad } from 'utils/formattingUtils';
import { roundToNearest } from 'utils/functionHelpers';

/**
 * TODO(kenan): Use moment for this? cf. 'moment duration' 'moment elapsed time'
 * Given a bid time in minutes, format a time label as a number of days.
 *
 * Example:
 *  formatBidTime(1440) = '1 day';
 *  formatBidTime(8641) = '6 days';
 *
 * @param bidTime - The time since the segment action in minutes.
 * @returns - The same time expressed as days.
 */
export const formatBidTime = (bidTime: number): string => {
  const duration = moment.duration(Math.floor(bidTime), 'minutes');
  const dd = Math.floor(duration.asDays());
  const hh = duration.hours();
  const mm = duration.minutes();
  const dayString = dd + (dd !== 1 ? ' days' : ' day');
  const hourMinuteString = _.join([zpad(hh), zpad(mm)], ':');

  if (dd && (hh || mm)) {
    return `${dayString}, ${hourMinuteString}`;
  } if (dd) {
    return dayString;
  } if (hh || mm) {
    return hourMinuteString;
  }

  return '00:00';
};

export const SHORT_DATE_FORMAT = 'D[.]MMM';

export const TIME_FORMAT = 'h:mm a';

export const DATE_FORMAT_JS_DATE = 'MMM dd, yyyy';
export const DATE_FORMAT = 'MMM Do[,] YYYY'; // whereas this one is for moment.js specifically
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const ISO_DATE = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const SIMPLE_DATE_FORMAT = 'MMM DD, YYYY';
export const NUMBER_DATE_FORMAT = 'MM/DD/YYYY';
export const NUMBER_DATE_WITH_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
export const WPP_CUSTOM_DATE_FORMAT = 'MMM DD, yyyy';
export const WPP_DATE_PICKER_FORMAT = 'MM/dd/yyyy';
export const WPP_DATE_PICKER_PLACEHOLDER_FORMAT = 'mm/dd/yyyy';

export type PossibleMoment =
  // | moment$MomentOptions
  | number
  | Date
  | Array<number>
  | Moment
  | string
  | null
  | void;

export const createMoment = (possibleMoment: PossibleMoment, utc: boolean, fmt?: string, userTimeZone?: string): Moment => {
  // @ts-ignore moment types issue
  const m = moment.utc(possibleMoment, fmt);
  if (utc) {
    return m;
  }

  if (userTimeZone) {
    // @ts-ignore moment types issue
    return momentTimeZone.utc(possibleMoment, fmt).tz(userTimeZone);
  }

  return m.local();
};

/**
 * formatter to be used for dates and times to be displayed to the user.
 * times output will be in local timezone.
 * If reading datetime from string it will attempt to be parsed as UTC
 * if moment is passed in it will be processed as is.
 * @param {string or moment} possibleMoment - datetime to be formatted
 * @param {bool} output in UTC if false output is local
 * @param {string} fmt - how to process string into moment.
 * @return {string} formatted datetime string in local time zone
 */
export const dateTimeFormatter = {
  date(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string): string {
    const outFmt = utcOutput ? `${DATE_FORMAT} z` : DATE_FORMAT;
    return createMoment(possibleMoment, utcOutput, fmt).format(outFmt);
  },

  valueOf(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string): number {
    return createMoment(possibleMoment, utcOutput, fmt).valueOf();
  },

  isoDate(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string): string {
    return createMoment(possibleMoment, utcOutput, fmt).format(ISO_DATE);
  },

  isoDateTime(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string): string {
    return createMoment(possibleMoment, utcOutput, fmt).format(ISO_DATE_TIME_FORMAT);
  },

  chartShortDate(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string) {
    return createMoment(possibleMoment, utcOutput, fmt).format(SHORT_DATE_FORMAT);
  },

  time(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string) {
    const outFmt = utcOutput ? `${TIME_FORMAT} z` : TIME_FORMAT;
    return createMoment(possibleMoment, utcOutput, fmt).format(outFmt);
  },

  dateTime(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string) {
    const outFmt = utcOutput ? `${DATE_TIME_FORMAT} z` : DATE_TIME_FORMAT;
    return createMoment(possibleMoment, utcOutput, fmt).format(outFmt);
  },

  numberDate(possibleMoment: PossibleMoment, utcOutput: boolean = false, fmt?: string): string {
    return createMoment(possibleMoment, utcOutput, fmt).format(NUMBER_DATE_FORMAT);
  },

  timeZoneBasedDateTime(timezoneFilter: TimeZoneValue, possibleMoment:PossibleMoment, userTimeZone?: string) {
    const utcOutput = timezoneFilter === TimeZoneValue.utc;
    const outFmt = utcOutput ? `${DATE_TIME_FORMAT} z` : DATE_TIME_FORMAT;
    return timezoneFilter === TimeZoneValue.utc
      ? createMoment(possibleMoment, true).format(outFmt)
      : createMoment(possibleMoment, false, null, userTimeZone).format(outFmt);
  },
};

export const roundToNearest15Min = (customTime = moment()) => {
  const now = moment(customTime);
  const roundedUp = roundToNearest(now.minute(), 15);
  now.minute(roundedUp).second(0);
  return now;
};
