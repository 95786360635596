import _ from 'lodash';
import React, { useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { WppTableBodyCell, WppTableBodyRow, WppCheckbox, WppTypography } from 'buildingBlocks';
import { DATE_TIME_FORMAT, dateTimeFormatter } from 'utils/dateTime';
import { CheckBoxHandler } from 'utils/types';
import MessageCreator from 'containers/MessageCreator';
import { Message } from '../types';

export function getFontColor(startDate, endDate) {
  const startDateTime = moment(startDate, DATE_TIME_FORMAT);
  const endDateTime = moment(endDate, DATE_TIME_FORMAT);
  const currentDateTime = moment(dateTimeFormatter.dateTime(moment()), DATE_TIME_FORMAT);
  if (startDateTime <= currentDateTime && endDateTime >= currentDateTime) {
    return 'green';
  } if (startDateTime <= currentDateTime && endDate === 'N/A') {
    return 'green';
  } if (startDateTime > currentDateTime) {
    return 'blue';
  }
  return 'grey';
}

type Props = {
  message: Message
  onSelected: CheckBoxHandler
  selected: boolean
};

export const MessageDetails = ({
  onSelected,
  selected,
  message: {
    id,
    subject,
    content,
    startDate,
    endDate,
  },
}: Props) => {
  const [showMessageCreator, setShowMessageCreator] = useState<boolean>(false);
  const startDateTime = dateTimeFormatter.dateTime(startDate);
  const endDateTime = endDate ? dateTimeFormatter.dateTime(endDate) : 'N/A';

  const handleEditMessageClick = () => {
    setShowMessageCreator((prev) => !prev);
  };

  return (
    <WppTableBodyRow key={id} style={{ color: getFontColor(startDateTime, endDateTime) }} className="wpp-table-body-row">
      <WppTableBodyCell className="wpp-table-body-cell">
        <WppCheckbox onWppChange={onSelected} className={selected ? 'visible-checkbox' : 'wpp-checkbox'} checked={selected} />
      </WppTableBodyCell>
      <WppTableBodyCell>
        <Link to="#" onClick={handleEditMessageClick}>{subject}</Link>
        {showMessageCreator && <MessageCreator messageId={id} showMessageCreator={showMessageCreator} setShowMessageCreator={setShowMessageCreator} />}
      </WppTableBodyCell>
      <WppTableBodyCell><WppTypography type="s-body">{_.truncate(content, { length: 60, separator: /[.,!?]? +/ })}</WppTypography></WppTableBodyCell>
      <WppTableBodyCell><WppTypography type="s-body">{startDateTime}</WppTypography></WppTableBodyCell>
      <WppTableBodyCell><WppTypography type="s-body">{endDateTime}</WppTypography></WppTableBodyCell>
    </WppTableBodyRow>
  );
};

export default MessageDetails;
