// eslint-disable-next-line lodash/import-scope
import { Dictionary } from 'lodash';
import { AWGDimensions, RevenueType } from 'constantsBase';
import { BudgetAllocationResponse, GroupSettings } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { BudgetIntervals } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import {
  Flight, StrategyType, Advertiser, Member, SegmentGroupsType, StrategyGoals, OptimizationLevelType,
  FetchState, User, Goal, PricingStrategy, Brand, GoalType, Currency,
} from 'utils/types';
import { DspToPixel } from './components/PixelPicker/types';
import { APNSegment } from './ConfigurationByStrategyType/segmentUtils';
import { FlightIds } from './steps/AttachFlights/components/AttachFlightsModal/BulkUploader';
import { OptimizationType } from './steps/AttachFlights/constants';
import { RevenueTypeOutcomeOptions } from './steps/GoalSelection/constants';

export type OptLevelType = {
  displayName: string
  header: string
  description: string
  bannerTitle: string
  bannerDesc: string
  imgSrc: string
};

export type FlightCandidate = {
  name: string
  externalId: string
  externalType: number
  member: number
  memExtId: string
};

export type FlightStatusType = {
  [key: string]: Flight
  // @ts-ignore hard
  eligible?: boolean
  // @ts-ignore hard
  status?: string
};

export type FlightsStatusType = {
  [key: string]: FlightStatusType
};

export type AttachFlightsForm = {
  member: Member
  advertiser: Advertiser
  brand: Brand
  optimizationLevel: OptimizationLevelType
  defaultCurrency: Currency
  attachedFlights: Array<Flight>
  flightsStatus: FlightsStatusType
  ineligibleFlights: Array<Flight>
  eligibleFlights: Array<Flight>
  toBeDetached: Array<Flight>
  attachedToThisStrategy: Array<Flight>
  reactivatedFlights?: Array<Flight>
  toBeDeactivated?: Array<Flight>
  deactivatedFlights?: Array<Flight>
  selectedOptType?: OptimizationType
};

export type WizardFormAttachFlights = {
  member: Member
  advertiser: Advertiser
  brand: Brand
  optimizationLevel: OptimizationLevelType
  attachedFlights: Array<Flight>
};

export type BudgetSetting = {
  startDate: Date
  endDate: Date
  budget: number
  type?: 'amount'
  delivery?: number
  active?: boolean
  newlyAdded?: boolean
  originalValue?: number
};

export type OutcomeDetailsType = {
  outcome: RevenueType | null
  revenueValue: string | number | null
  externalType?: number
  name?: string
};

export type RevenueTypeConfig = {
  [campaignExtId: string]: OutcomeDetailsType
};

export type RevenueTypeOutcomes = {
  [dsp: number]: RevenueTypeConfig
};

export type FormulaType = {
  name: string
  value: string
  formula: Array<string>
  metrics: Array<string>
  optimizationDirection: OptimizationDirection
  id?: number
};

export enum OptimizationDirection {
  down = 'down',
  up = 'up',
}

export type WeightRowObj = {
  displayName: string
  weight: number | string
  code?: string
  id?: string | number
};

export type WeightObj = { [dimensionId: string]: WeightRowObj };

export type WeightConfig = {
  [dspId: string]: WeightObj
};

export type MetricConfigObj = {
  name: string
  dimension: AWGDimensions
  isWeighted: boolean
  weighting: WeightConfig | WeightObj
};

export type MetricConfig = {
  [metricName: string]: MetricConfigObj
};

export type PixelsConfigObject = {
  id: number
  name?: string
};

export type PixelsConfig = {
  [dsp: number]: Array<PixelsConfigObject>
};

export type WizardFormGoalSelection = {
  goal: {
    type: string
    target: number
    impValueFilters?: DspToPixel
  }
  budget?: RevenueTypeConfig
  budgetSettings?: Array<BudgetSetting>
  revenueOutcomeType?: RevenueTypeOutcomeOptions
  customGoal?: Partial<FormulaType>
  metricsConfig?: MetricConfig
};

export type BudgetSettingDB = {
  // eslint-disable-next-line camelcase
  start_date: string
  // eslint-disable-next-line camelcase
  end_date: string
  budget: string
  type: 'amount'
};

export type StrategyConfirmationForm = {
  userBidModifierRemovalConsent?: boolean
  name: string
};

export type StrategyTypeSelectionForm = {
  strategyType: StrategyType
};

export type WizardFormValues = {
  attachFlightsStep: AttachFlightsForm
  goalSelectionStep: WizardFormGoalSelection
  strategyTypeSelectionStep: StrategyTypeSelectionForm
  strategyConfigurationStep: StrategyConfigurationStep
  strategyConfirmationStep: StrategyConfirmationForm
  budgetAllocationState: BudgetAllocationResponse
  nextBtnClick: boolean
  initFromQs?: boolean
};

export type WizardFormForCreateStrategy = {
  attachFlightsStep: Pick<AttachFlightsForm, 'member' | 'advertiser' | 'brand'>
  goalSelectionStep: WizardFormGoalSelection
  strategyTypeSelectionStep: StrategyTypeSelectionForm
  strategyConfigurationStep: StrategyConfigurationStep
  strategyConfirmationStep: StrategyConfirmationForm
};

export type HeliosForm = {
  segmentGroups: SegmentGroupsType
  minBid: number
  useCustomBudget: boolean
  budgetType: string
  maxBid: number
  baseBid: number
  viewabilityThreshold: number
  strategyGoals: StrategyGoals
  creativeOptimization: boolean
  isBFO: boolean
  lineItem: string | undefined
  impValueFilters: DspToPixel
  budgetIntervals: BudgetIntervals
  viewability?: Viewability
};

export type SegmentRecencyForm = HeliosForm;

export type PredictorForm = {
  [algoId: number]: {
    id: number
    name?: string
  }
} & {
  featuresToCombine: Array<{ features: Array<string> }>
  segments: Array<APNSegment>
  minBid: number
  maxBid: number
  strategyGoals: StrategyGoals
  tolerance: string
  maxNodes: string
  pricingStrategy: PricingStrategy
  excludeFeatures: string
  // used by product to activate the MAB
  additionalAlgos: Array<any>
};

export type CustomTreeForm = {
  treeFileInput: string
  customTreeInput: string
  modelType: {
    value: string
    text: string
  }
  strategyType: number
  strategyGoals: StrategyGoals
};

export type CustomSDFForm = {
  selectedUsersForEmail: Array<User>
};

/* eslint-disable @typescript-eslint/indent */
export type StrategyConfigurationStep = HeliosForm |
  CustomTreeForm |
  SegmentRecencyForm |
  CustomBidListForm |
  BaseTTDForm |
  BudgetOptimizationForm |
  CustomSDFForm &
  { additionalAlgos };

type BaseTTDForm = {
  strategyGoals: StrategyGoals
  dateRange: number
  blackListRecovery: boolean
};

export type CustomBidListForm = {
  bidListCsvInput: string
  bidListString: string
  volumeControlBidListString: string
};

export type BudgetOptimizationForm = {
  strategyGoals: StrategyGoals
  budgetAllocation: boolean
  minBid: number
  maxBid: number
  intelligentChildObjects: boolean
  dailyParentBudgetInflationRatio: number
  bidOptimization: boolean
  fixedCostInventory: boolean
  hasCustomRevenueType: boolean
  lifetimeEventBudget: number
  isTrueView?: boolean
  viewability?: Viewability
  groupSettings?: GroupSettings
  // used to track if INT toggle touched
  prevSetIntelChildObj?: boolean
  revenueOutcomeType?: RevenueTypeOutcomeOptions
};

export type AttachFlightsInfoType = {
  flightsStatus: FlightsStatusType
  ineligibleFlights: Array<Flight>
  eligibleFlights: Array<Flight>
  attachedToThisStrategy: Array<Flight>
  toBeDetached: Array<Flight>
  reactivatedFlights?: Array<Flight>
  toBeDeactivated?: Array<Flight>
  deactivatedFlights?: Array<Flight>
  eligCPFlightsWithoutAmountBudgetType?: Array<Flight>
  eligCPFlightsWithSpend?: Array<Flight>
};

export type Status =
  | { kind: FetchState.loading }
  | {
    kind: FetchState.error
    error: string
  }
  | {
    kind: FetchState.success
    result: string
  }
  | { kind: FetchState.initial };

export type ModalSessionFlightsInfoType = {
  loadingFlightCandidates: boolean
  flightCandidates: Array<Flight>
  searchTerm: string
  pendingFlights: Array<Flight>
  attachedToAnotherStrategy: Array<Flight>
  attachedToThisStrategy: Array<Flight>
  eligibleFlights: Array<Flight>
  ineligibleFlights: Array<Flight>
  flightsStatus: FlightsStatusType
  bulkUpload: {
    bulkUploadFailedMsg: string
    bulkUploadedFlights: Array<Flight>
    bulkUploadLoading: boolean
    notFoundFlights: FlightIds
    attachedToCurrentFlights: FlightIds
  }
  syncFlightsStatus: Status
};

export type KoalaBoxData = {
  maxBid: number
  unboundedComputeNodes: Array<string>
};

export type WizardFormValuesForInitialization = {
  advertiser: Advertiser
  member: Member
  attachedFlights: Array<Flight>
};

export type Viewability = {
  enabled: boolean
  type?: Goal
  target?: number
};

export type GoalFlightTypesByFlightType = Dictionary<GoalType>;

export type AdvertiserMemberInfo = {
  member: Member
  defaultCurrency: number
  externalId: string
  id: number
  isEnabled: boolean
  name: string
  timezone: null | string
  updatedAt: string
  createdAt: string
};

export type RevenueEditForm = {
  outcome: string
  value: number
};

export type CustomFormError = {
  [fieldId: string]: string
};

export enum RevenueBulkUpdateTypes {
 reset = 'reset',
 update = 'update',
}

export type GoalDB = {
  displayName: string
  name: string
  equation: string
  direction: OptimizationDirection
  isSystemGoal?: boolean
  id?: number
  createdAt?: string
  updatedAt?: string
  createdBy?: string
  updatedBy?: string
  isWeighted?: boolean
  isConstraintGoal?: boolean
};

export type StrategyWizardAction = {
  type: string
  payload?: any
};
