// @ts-nocheck issue with redux-form and ObjectMultiDropdown props
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FieldProps, ObjectMultiDropdown, ObjectMultiDropdownProps, FormDropdownProps,
  WppGrid, WppButton, WppInput, WppInlineMessage, WppTypography,
} from 'buildingBlocks';
import { Permission } from 'utils/featureFlags';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import FormDropdown from 'components/FormDropdown';
import { validatedWrapper } from 'utils/wrapperComponents';
import { User, Role as RoleType, Permission as PermissionType } from 'utils/types';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import WithRouter, { RouterProps } from 'utils/withRouter';
import { inputField, roleDetailStyle } from './style';
import { saveRole, getRole, resetAll, resetPermissions, deleteRole } from './actions';
import { validateRolePage as validate } from './validate';
import { errorMsgMap, roleTypes } from './constants';
import { DeleteRoleConfirm } from './components/DeleteRoleConfirm';

const REQUIRED_PERMISSIONS = [Permission.manageRoles];

type ErrorProps = {
  error: string
};

const Error = ({ error }: ErrorProps) => (
  <WppInlineMessage htmlFor="saveError" size="s" message={error} type="error" showTooltipFrom={1000} />
);

type DropdownErrorProps = {
  errorMsg: string
};

const DropdownError = ({ errorMsg }: DropdownErrorProps) => (
  <WppInlineMessage size="s" message={errorMsg} type="error" showTooltipFrom={100} />
);

type RenderInputProps = {
  name: string
  label: string
  input: {}
  disabled: boolean
  className: string
};

export const RenderInput = (props: RenderInputProps) => (
  <>
    <WppTypography type="s-strong" style={roleDetailStyle.roleFieldLabelStyle} htmlFor={props.name}>{props.label}</WppTypography>
    <WppInput {...props.input} className={props.className} disabled={props.disabled} />
  </>
);

export const ValidatedRenderInput = validatedWrapper(RenderInput);

type RenderFormInputFieldProps = {
  name: string
  description: string
  label: string
  disabled?: boolean
};

const RenderFormInputField = ({ name, ...rest }: RenderFormInputFieldProps) => (
  <div style={roleDetailStyle.roleInputContainerStyle}>
    <Field<FieldProps<RenderFormInputFieldProps>>
      {...rest}
      id={name}
      name={name}
      style={inputField}
      component={ValidatedRenderInput}
    />
  </div>
);

type RoleProps = RouterProps & {
  permissions: Array<PermissionType>
  role: RoleType & {
    permissions: Array<PermissionType>
  }
  fetchPermissionsError: boolean
};

type RenderSaveSectionProps = RouterProps & {
  invalid: boolean
  user: User
  role: RoleType & {
    permissions: Array<PermissionType>
  }
};

const RenderSaveSection = ({ props }: RenderSaveSectionProps) => {
  const qAReadOnly = isQAGlobalReadOnly(props.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.saveRole(
      props.router.params.roleId,
      props.role,
      props.router,
    );
  };

  return (
    <div style={roleDetailStyle.roleDetailFooterBtnContainer}>
      <Link to="/roles">
        <WppButton variant="secondary">Cancel</WppButton>
      </Link>
      <WppButton
        onClick={handleSubmit}
        disabled={qAReadOnly || props.invalid}
      >
        Save
      </WppButton>
    </div>
  );
};

export const Role = (props: RoleProps) => {
  const { router, resetAll: resetAllData, getRole: getRoleData, resetPermissions: resetPermissionsData, deleteRole: deleteRoleData, saveError, role, user, fetchPermissionsError, permissions } = props;

  const roleId = router.params.roleId;
  useEffect(() => {
    resetAllData();
    getRoleData(roleId);
  }, [getRoleData, resetAllData, roleId]);

  const onChangeRoleType = (_e, type) => {
    resetPermissionsData(type);
  };

  const renderDeleteSection = () => {
    const qAReadOnly = isQAGlobalReadOnly(user);
    return (
      <WppGrid item all={6}>
        {saveError && <Error error={errorMsgMap.ROLE_ALREADY_EXIST} />}
        <DeleteRoleConfirm
          deleteRole={deleteRoleData}
          role={role}
          router={router}
          qAReadOnly={qAReadOnly}
        />
      </WppGrid>
    );
  };

  const isEdit = !_.isUndefined(router.params.roleId);

  return (
    <>
      <WppGrid container fullWidth>
        <WppGrid item all={6}>
          <RenderFormInputField
            name="roleName"
            description="Enter a Role Name"
            label="Role Name"
            disabled={isEdit}
          />
          <RenderFormInputField
            name="roleDescription"
            description="Tell us something about this role"
            label="Role Description"
            className="role-description"
          />
          <div style={roleDetailStyle.roleDropDownContainerStyle}>
            <WppTypography style={roleDetailStyle.roleFieldLabelStyle} type="s-strong" htmlFor="roleType">Role Type</WppTypography>
            <Field<FieldProps<FormDropdownProps>>
              onChange={(e, val) => onChangeRoleType(e, val)}
              id="roleType"
              name="roleType"
              component={FormDropdown}
              options={roleTypes}
              parse={(value) => parseInt(value, 10)}
              fluid
              search
              selection
              disabled={isEdit}
            />
          </div>
          <div style={roleDetailStyle.roleDropDownContainerStyle}>
            <WppTypography style={roleDetailStyle.roleFieldLabelStyle} type="s-strong" htmlFor="rolePermissions1">Role Permissions</WppTypography>
            <Field<FieldProps<ObjectMultiDropdownProps<unknown>>>
              component={ObjectMultiDropdown}
              id="rolePermissions"
              name="rolePermissions"
              options={_.filter(permissions, { type: role.type })}
              keyFn={(obj: { displayName: string }) => obj.displayName}
              error={fetchPermissionsError}
              disabled={fetchPermissionsError}
              selection
              value={role.permissions}
            />
          </div>
          {fetchPermissionsError && <DropdownError errorMsg={errorMsgMap.FETCH_PERMISSIONS_FAILED} />}
        </WppGrid>
      </WppGrid>
      {renderDeleteSection()}
    </>
  );
};

function mapStateToProps(state) {
  const selector = formValueSelector('roleDetails');
  return {
    permissions: state.roles.permissions,
    role: {
      id: state.roles.role.id,
      name: selector(state, 'roleName'),
      description: selector(state, 'roleDescription'),
      permissions: selector(state, 'rolePermissions'),
      type: selector(state, 'roleType'),
    },
    initialValues: {
      roleName: state.roles.role.name,
      roleDescription: state.roles.role.description,
      rolePermissions: state.roles.role.permissions,
      roleType: state.roles.role.type,
    },
    saveError: state.roles.saveError,
    fetchPermissionsError: state.roles.fetchPermissionsError,
    user: state.login.session.data.user,
  };
}

const PermissionRole = (props) => {
  const roleId = _.get(props, 'router.params');
  return (
    <>
      <WppPermissionPageTemplate
        title="Role"
        name={`${roleId ? 'Edit' : 'Create'} Role`}
        permissions={REQUIRED_PERMISSIONS}
      >
        <Role {...props} />
      </WppPermissionPageTemplate>
      <RenderSaveSection props={props} />
    </>
  );
};

export default connect(
  mapStateToProps,
  {
    getRole,
    saveRole,
    deleteRole,
    resetAll,
    resetPermissions,
  },
)(reduxForm({
  form: 'roleDetails',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
})(WithRouter(PermissionRole)));
