import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_TYPOGRAPHY, COPILOT_LAYOUT } from 'globalStyles';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';

const { HEADING } = COPILOT_TYPOGRAPHY;
const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;
const { toolTipStyle } = BAStyle;

type CSSStyleObject = { [key: string]: CSSProperties };

export const inputField = {
  width: '100%',
  borderRadius: 0,
};

export const textAreaField: CSSProperties = {
  width: '100%',
  borderRadius: 0,
  minHeight: '200px',
  resize: 'none',
};

export const postOptions = {
  listStyleType: 'none',
  textAlign: 'center',
  fontWeight: '500',
  margin: 0,
  padding: 0,
};

export const messageContentHeader = {
  ...HEADING.H4,
  color: NEUTRALS.N800_MATTER,
};

export const subHeaderStyle = {
  ...HEADING.H5,
  color: NEUTRALS.N600_ABBEY,
};

export const toolTip = {
  ...toolTipStyle,
  fontSize: SPACING[20],
  margin: 0,
  color: NEUTRALS.N400_GRANITE,
  marginLeft: SPACING[8],
};

export const datePickerStyles: CSSStyleObject = {
  calendarIconStyle: {
    cursor: 'pointer',
    backgroundColor: 'white',
    border: 0,
    boxShadow: '0px 0px 0px',
  },
  colorSetting: {
    color: NEUTRALS.N800_MATTER,
    backgroundColor: NEUTRALS.N0_WHITE,
  },
  customInputContainer: {
    fontWeight: 400,
    paddingLeft: SPACING[8],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px',
    borderStyle: 'solid',
    borderColor: NEUTRALS.N200_LINEN,
  },
  postOptions: {
    listStyleType: 'none',
    textAlign: 'center',
    fontWeight: '500',
    margin: 0,
    padding: 0,
  },
};

export const algorithmTypeStyle = {
  display: 'flex',
  gap: '5px',
};

export const algorithmTypeText = {
  marginBottom: '5px',
};

export const confimMesssageModal = {
  display: 'flex',
  justifyContent: 'end',
  gap: '10px',
};

export const confimMesssageModalFooter = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
};

export const messageFormGrid = {
  display: 'grid',
  gap: '5px',
};

export const messageFormStartDate = {
  display: 'flex',
  gap: '9px',
};

export const messageFormGridText = {
  marginTop: '3px',
  marginBottom: SPACING[2],
};

export const messageFormDismiss = {
  display: 'grid',
};

export const messageFooter: CSSStyleObject = {
  footerRow: {
    height: '258px',
  },
  footerCol: {
    display: 'flex',
    gap: SPACING[16],
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  undoBtn: {
    ...HEADING.H6,
    backgroundColor: NEUTRALS.N0_WHITE,
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    color: NEUTRALS.N800_MATTER,
    borderRadius: 4,
    width: '100px',
    height: SPACING[32],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  submitBtn: {
    ...HEADING.H6,
    color: NEUTRALS.N0_WHITE,
    borderRadius: 4,
    width: '100px',
    height: SPACING[32],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
};

export const endDateStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
};
