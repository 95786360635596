import React from 'react';
import { WppGrid, WppButton, WppIconAdd, WppInlineMessage } from 'buildingBlocks';
import { userFormRowPadding, bottomPadding } from '../style';

type Props = {
  disabled: boolean,
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLWppButtonElement>) => void,
};

const RequestAccessButton = ({ disabled, handleAccessRequestSubmit }: Props) => (
  <>
    <WppGrid container fullWidth style={userFormRowPadding}>
      <WppGrid item all={12}>
        <WppButton
          variant="secondary"
          size="s"
          disabled={disabled}
          onClick={handleAccessRequestSubmit}
        >
          <WppIconAdd slot="icon-start" />REQUEST ACCESS
        </WppButton>
      </WppGrid>
    </WppGrid>
    {disabled && (
    <WppGrid container fullWidth style={bottomPadding}>
      <WppGrid item all={12}>
        <WppInlineMessage size="s" message="You must first set up your manager before you can request access from them. Click “Edit” to select your approver." type="warning" />
      </WppGrid>
    </WppGrid>
    )}
  </>
);

export default RequestAccessButton;
