import React from 'react';
import { Container, Image, Header } from 'buildingBlocks';
import { dataUploader } from 'containers/StrategyAnalytics/components/View/DataUploader/style';
import { CSV_FILE_IMG_PATH } from 'containers/StrategyAnalytics/components/View/DataUploader/constants';

const {
  fileContainer, fileIcon, fileNameTextWidth,
  fileNameText, fileText, fileDownloadDiv,
} = dataUploader;

type FileToDownloadProps = {
  fileName: string
};

const FileToDownload = ({ fileName }: FileToDownloadProps) => (
  <>
    <div style={fileText}>This file will be used to create new members in AWS bucket.</div>
    <Container style={fileContainer}>
      <div style={fileDownloadDiv}>
        <Image alt="csv file" src={CSV_FILE_IMG_PATH} style={fileIcon} />
        <Header style={{ ...fileNameText, ...fileNameTextWidth }} content={fileName} />
      </div>
    </Container>
  </>
);

export default FileToDownload;
