import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { WppDivider, WppTypography } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { getChildDisplayName } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { SupportedExternalFlightTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import BudgetIntervals from './BudgetIntervals';
import { BUDGET_SECTION_STYLES } from '../styles';
import { CrossPlatformBudgetSettingsValidationType } from '../utils';
import RevenueTypeSection from './RevenueTypeSection/RevenueTypeSection';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';

type Props = {
  crossPlatformBudgetSettingsValidation: CrossPlatformBudgetSettingsValidationType
  setCrossPlatformBudgetSettingsValidation: Dispatch<SetStateAction<CrossPlatformBudgetSettingsValidationType>>
  initialFormValues: WizardFormGoalSelection
  resetConfirmedGoal: () => void
};

const BudgetSection = (props: Props) => {
  const {
    crossPlatformBudgetSettingsValidation,
    setCrossPlatformBudgetSettingsValidation,
    initialFormValues,
    resetConfirmedGoal,
  } = props;

  const {
    revenueTypeEnabled, isCrossPlatformOptimization, hasRevTypePermission,
    wizardFormValues: {
      attachFlightsStep: { optimizationLevel },
    },
  } = useGoalSectionContext();
  const flightExtType = _.head(optimizationLevel.externalTypeIds) as number;

  const childObjectTypeDisplayName = getChildDisplayName(flightExtType as SupportedExternalFlightTypes);
  const headerPendoClass = isCrossPlatformOptimization ? 'multiple-flight-budget-header' : 'single-flight-budget-header';

  return (
    <div style={BUDGET_SECTION_STYLES.container as CSSProperties}>
      <div style={BUDGET_SECTION_STYLES.budgetRevenueTypeStyle}>
        <WppTypography type="xl-heading" tag="p" className={headerPendoClass}>Budget</WppTypography>
        <WppTypography type="s-body" tag="p">Control budget and revenue types.</WppTypography>
      </div>
      <WppDivider />
      <BudgetIntervals
        flightExtType={flightExtType}
        childObjectTypeDisplayName={childObjectTypeDisplayName}
        crossPlatformBudgetSettingsValidation={crossPlatformBudgetSettingsValidation}
        setCrossPlatformBudgetSettingsValidation={setCrossPlatformBudgetSettingsValidation}
        initialFormValues={initialFormValues}
        resetConfirmedGoal={resetConfirmedGoal}
      />
      {(revenueTypeEnabled && hasRevTypePermission) && (<RevenueTypeSection />)}
    </div>
  );
};

export default BudgetSection;
