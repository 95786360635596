import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

// Utils
import localStorage from './utils/localstorage/reducer';

// Containers
import app from './containers/App/reducer';
import authToken from './containers/AuthToken/reducer';
import abInsights, {
  State as abInsightsState,
} from './containers/ABInsights/reducer';
import copilotHealth from './containers/CopilotHealth/reducer';
import dashboard from './containers/Dashboard/reducer';
import jobDetail from './containers/JobDetail/reducer';
import jobs from './containers/Jobs/reducer';
import login from './containers/Login/reducer';
import logout from './containers/Logout/reducer';
import messageCreator from './containers/MessageCreator/reducer';
import messages, { State as MessagesState } from './containers/Messages/reducer';
import roles from './containers/Roles/reducer';
import seats from './containers/Seats/reducer';
import strategiesList from './containers/StrategiesList/reducer';
import strategyAnalytics from './containers/StrategyAnalytics/reducers/main';
import strategyFlightRuns from './containers/StrategyFlightRuns/reducer';
import taskDetail from './components/TaskCard/reducer';
import user from './containers/User/reducer';
import userGroups from './containers/UserGroups/reducer';
import users from './containers/Users/reducer';

const reducers = {
  abInsights,
  authToken,
  app,
  copilotHealth,
  dashboard,
  form,
  jobDetail,
  jobs,
  localStorage,
  login,
  logout,
  messageCreator,
  messages,
  roles,
  seats,
  strategiesList,
  strategyAnalytics,
  strategyFlightRuns,
  taskDetail,
  user,
  userGroups,
  users,
};

export type GlobalState = {
  abInsights: abInsightsState;
  authToken: any;
  app: any;
  copilotHealth: any;
  dashboard: any;
  form: any;
  jobDetail: any;
  jobs: any;
  localStorage: any;
  login: any;
  logout: any;
  messageCreator: any;
  messages: MessagesState;
  roles: any;
  seats: any;
  strategiesList: any;
  strategyAnalytics: any;
  strategyFlightRuns: any;
  taskDetail: any;
  user: any;
  userGroups: any;
  users: any;
};

// The combineReducers helper function turns an object whose values are different
// reducing functions into a single reducing function you can pass to createStore.
const rootReducer = combineReducers<GlobalState>(reducers);
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
