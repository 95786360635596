/* eslint-disable max-classes-per-file */
import axios, { AxiosPromise, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { CANCEL } from 'redux-saga';
import qs from 'qs';
import _ from 'lodash';
import omitEmpty from 'omit-empty';
import { apiEnums, RESULTS_LIMIT } from 'constantsBase';
import { TTDOrWMTTrackingTag } from './types';

// This needs to be declared locally because await can't figure out that this is actually a promise if imported
// directly from axios
export type RequestT = {} | void;
export type ServiceT = (request: RequestT) => AxiosPromise;
export type URLServiceT = (url: string, request: RequestT) => AxiosPromise;

export type CopilotAPIT = {
  get: URLServiceT,
  put: URLServiceT,
  post: URLServiceT,
  delete: URLServiceT,
};

/**
 * formatRequestParams - remove empty (empty arrays, objects, undefined, etc) so that we do not request undefined things
 * from the API. will still allow 'null' to be passed so we can do SELECT WHERE IS NULL queries.
 * @param  Object of URL parameters
 * @return The new params, with empty values removed, and the 'where' clause stringified
 */
export const formatRequestParams = (params: RequestT) => {
  // @ts-ignore omit-empty
  const omitted = omitEmpty(params, { excludeType: 'null' });
  // @ts-ignore omit-empty
  if (omitted && omitted.where) {
    // @ts-ignore omit-empty
    const where = JSON.stringify(omitted.where);
    return { ...omitted, where };
  }
  return omitted;
};

/**
 * API for communicating to our sails backend
 */
export class CopilotAPI {
  conn: AxiosInstance;

  constructor(args?: {}) {
    this.conn = axios.create({ ...args });
  }

  get = (url: string, params: RequestT) => {
    const qsParams = qs.stringify(formatRequestParams(params));
    const finalURL = qsParams ? `${url}?${qsParams}` : url;
    const source = axios.CancelToken.source();
    const req = this.conn.get(finalURL, { cancelToken: source.token });
    req[CANCEL] = () => source.cancel();
    return req;
  };

  post = (url: string, args: unknown) => this.conn.post(url, args);

  put = (url: string, args: unknown) => this.conn.put(url, args);

  delete = (url: string, args: AxiosRequestConfig) => this.conn.delete(url, args);
}

const inst = new CopilotAPI();

export class Service {
  copilotAPI: CopilotAPIT;

  constructor(copilotAPI: CopilotAPIT) {
    this.copilotAPI = copilotAPI;
  }
}

/**
 * SmartService is a class that will provide get, put, post, getById.
 * When using get, provide the query string as an object like:
 * { limit: 100, where: { age: { '<': 30 } } }
 * Query-string will convert the object into a query string for you.
 *
 * When using getById, provide the id of the object you are trying to get.
 *
 * When you want to create a new service that supports these endpoints,
 * instantiate SmartService with an instance of copilotAPI typically `inst`
 * in this file and the URL that you would like to query.
 * you can see examples below in DSPs, Advertiser, Jobs
 *
 * If you are building a more complex service you can still inherit from
 * SmartService if you would like to provide get, put, post, getById
 */

export class SmartService extends Service {
  url: string;

  constructor(copilotAPI: CopilotAPIT, url: string = '') {
    super(copilotAPI);
    this.url = url;
  }

  get = (obj: RequestT): AxiosPromise => this.copilotAPI.get(`/${this.url}`, obj);

  post = (obj: RequestT): AxiosPromise => this.copilotAPI.post(`/${this.url}`, obj);

  put = (id: string, obj: RequestT): AxiosPromise => this.copilotAPI.put(`/${this.url}/${id}`, obj);

  count = (obj: RequestT): AxiosPromise => this.copilotAPI.get(`/${this.url}/count`, obj);

  getById = (id: string, obj?: RequestT): AxiosPromise => this.copilotAPI.get(`/${this.url}/${id}`, obj);

  delete = (id: string, config?: RequestT): AxiosPromise => this.copilotAPI.delete(`/${this.url}/${id}`, config);

  bulkUpdate = (records: RequestT) => this.copilotAPI.post(`/${this.url}/bulkUpdate`, records);

  bulkDeleteWhere = (filter: RequestT, config?: RequestT) => (
    this.copilotAPI.delete(`/${this.url}/bulkDeleteWhere?where=${filter}`, config)
  );

  bulkUpdateWhere = (filter: RequestT, obj: RequestT) => (
    this.copilotAPI.put(`/${this.url}/bulkUpdateWhere?where=${filter}`, obj)
  );

  // addCustom = (functionName, requestMethod, urlSuffix) => {
  //   this[functionName] = obj => this[requestMethod](this.url + urlSuffix, obj);
  // }
}

class EnvironmentService extends Service {
  getEnvironment = () => (
    this.copilotAPI.get('/environment/getenv')
  );
}

export class AuthService extends Service {
  login = (config: RequestT, browserTimeZone: string) => {
    const url = `/${apiEnums.auth}/login`;
    return this.copilotAPI.post(url, { post: config, browserTimeZone });
  };

  getSession = async (browserTimeZone: string) => {
    const url = `/${apiEnums.auth}/session`;
    const response = await this.copilotAPI.get(url, { browserTimeZone });
    if (response.status === 200) {
      return response;
    }
    return null;
  };

  updatedSession = async (email: string, session: Object, browserTimeZone: string) => {
    const url = `/${apiEnums.auth}/updatedSession`;
    const response = await this.copilotAPI.post(url, { post: { email, session, browserTimeZone } });
    if (response.status === 200) {
      return response.data;
    }
    return null;
  };

  logout = (session: {}) => {
    const url = `/${apiEnums.auth}/logout`;
    return this.copilotAPI.get(url, session);
  };

  getMaintenanceStatus = async () => {
    const response = await this.copilotAPI.get('/maintenanceStatus');
    if (response.status === 200) {
      return response.data;
    }
    return null;
  };
}

class IssueService extends Service {
  createIssue = (request: RequestT) => this.copilotAPI.post(`/${apiEnums.issues}`, request);
}

class JobsService extends SmartService {
  groupedCount = (request: RequestT) => this.copilotAPI.get(`/${apiEnums.job}/groupedCount`, request);
}

class AirflowService extends Service {
  triggerStrat = (request: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.airflow}/triggerStrat`, request)
  );

  triggerMultipleStratsFlights = (request: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.airflow}/triggerMultipleStratsFlights`, request)
  );

  triggerFlightDetachment = (request: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.airflow}/trigger/flight_detachment`, request)
  );

  triggerFilterLLD = (request: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.airflow}/trigger/filtering_driver`, request)
  );

  getDagStatus = (request: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.airflow}/status`, request)
  );

  getDagContent = (request: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.airflow}/content`, request)
  );

  getFromSignedUrl = (request: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.airflow}/s3Result`, request)
  );
}

class AFDagRunService extends Service {
  groupedCount = (request: RequestT) => this.copilotAPI.get(`/${apiEnums.afDagRun}/groupedCount`, request);
}

class AMZNPixelService extends SmartService {
  getAmazonPixels = (request: RequestT) => this.copilotAPI.get(`/${apiEnums.amznPixel}/getAmazonPixels`, request);
}

class MemberService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.member}`);
  }

  save = (member: { id: string }) => {
    if (!member.id) {
      return this.post(member);
    }
    return this.put(member.id, member);
  };

  storeSeatSecrets = (id: string, secrets: RequestT) => {
    const url = `/${apiEnums.member}/${id}/storeSeatSecrets`;
    return this.copilotAPI.post(url, secrets);
  };

  getMembersBasedOnUserRoles = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.member}/findForUserRoles`, obj);

  memberAdvBrand = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.member}/memberAdvBrand`, obj);

  membersByPermissions = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.member}/membersByPermissions`, obj);

  getUnauthorizedStrategyMembers = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.member}/getUnauthorizedStrategyMembers`, obj);
}

class RoleService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.role}`);
  }

  getRolesBasedOnUserRoles = (limit: number = RESULTS_LIMIT) => {
    const url = `/${apiEnums.role}/findForUserRoles?limit=${limit}`;
    return this.copilotAPI.get(url);
  };
}

class UserRoleService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.userrole}`);
  }

  getMembersAndRolesMapping = (limit: number = RESULTS_LIMIT) => {
    const url = `/${apiEnums.userrole}/validMemberRolesToAdd?limit=${limit}`;
    return this.copilotAPI.get(url);
  };
}

class S3Service extends Service {
  async modelFieldRequest({ type, field, id }: { type: string, field: string, id: string }) {
    const url = `/${apiEnums.s3}/model_field_request/${type}/${field}/${id}`;
    const response = await this.copilotAPI.get(url);
    if (response.status !== 200) {
      throw new Error(`report status was not 200. it was ${response.status}`);
    }
    return response.data;
  }

  getLatest = (bucket: string, key: string) => {
    const url = `/${apiEnums.s3}/get_latest/${bucket}/${key}`;
    const response = this.copilotAPI.get(url);
    return response;
  };
}

class SearchService extends Service {
  get = (q: string) => this.copilotAPI.get(`/${apiEnums.search}/${apiEnums.search}/`, { q });
}

export class StrategyService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.strategy}`);
  }

  configuration = (strategyId: string, obj: RequestT) => (
    this.copilotAPI.get(`/${this.url}/${strategyId}/configuration`, obj)
  );

  groupedCount = async (obj: RequestT) => this.copilotAPI.get(`/${this.url}/groupedCount`, obj);

  getStatus = (obj: RequestT) => this.copilotAPI.get(`/${this.url}/getStatus`, obj);

  strategyConfiguration = (obj: RequestT) => (
    this.copilotAPI.post(`/${this.url}/configure`, obj)
  );

  search = (obj: RequestT) => this.copilotAPI.get(`/${this.url}/search`, obj);

  getValidStrategies = (obj: RequestT) => this.copilotAPI.get(`/${this.url}/getValidStrategies`, obj);

  bulkEditStrategies = (obj: RequestT) => this.copilotAPI.post(`/${this.url}/bulkEditStrategies`, obj);
}

class StrategyFlightRunService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.strategyFlightRun}`);
  }

  getLatest = (strategyId: string) => this.copilotAPI.get(`/${this.url}/getLatestStrategyFlightRuns/${strategyId}`);

  getFeatures = (runId: string) => this.copilotAPI.get(`/${this.url}/${runId}/features`);

  getResultAtKey = (runId: string, obj: RequestT) => this.copilotAPI.get(`/${this.url}/${runId}/result`, obj);
}

class NotificationService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.notification}`);
  }

  updateUserNotification = (userId: string | number, obj: RequestT) => (
    this.copilotAPI.put(`/${this.url}/user/${userId}`, obj)
  );

  getGroupedNotifications = (obj: RequestT) => (
    this.copilotAPI.get(`/${this.url}/getGroupedNotifications`, obj)
  );

  getNotifications = (obj: RequestT) => (
    this.copilotAPI.get(`/${this.url}/getNotifications`, obj)
  );
}

class StrategyAnalyticsService extends Service {
  singleStrategy = (strategyId: string, obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalyticsCache}/${strategyId}/singleStrategy`, obj)
  );

  singleStrategyPerFlight = (strategyId: string, obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalyticsCache}/${strategyId}/singleStrategyPerFlight`, obj)
  );

  singleStrategyByLeaf = (strategyId: string, obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalytics}/${strategyId}/singleStrategyByLeaf`, obj)
  );

  singleStrategyPerFlightByLeaf = (strategyId: string, obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalytics}/${strategyId}/singleStrategyPerFlightByLeaf`, obj)
  );

  multiStrategy = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalyticsCache}/multiStrategy`, obj)
  );

  multiStrategyPerStrategy = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalyticsCache}/multiStrategyPerStrategy`, obj)
  );

  multiExternalFlight = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyAnalytics}/multiExternalFlight`, obj)
  );
}

class StrategyGoalAnalyticsService extends Service {
  getAnalyticsData = (obj: RequestT) => (
    this.copilotAPI.post(`/${apiEnums.strategyGoalAnalytics}/getAnalyticsData`, obj)
  );
}

class StratSegRecLeafAnalyticsService extends Service {
  getSegRecLeafAnalytics = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.stratSegRecLeafAnalytics}/getSegRecLeafAnalytics`, obj)
  );
}

class StratHeliosLeafAnalyticsService extends Service {
  getHeliosLeafAnalytics = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.stratHeliosLeafAnalytics}/getHeliosLeafAnalytics`, obj)
  );
}

class StratDefaultWizardConfigService extends Service {
  getConfig = (obj: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.stratDefaultWizardConfig}/getConfig`, obj)
  );
}

class StratCustomTreeLeafAnalyticsService extends Service {
  getCustomTreeLeafAnalytics = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.stratCustomTreeLeafAnalytics}`, obj);
}

class StratHeliosLeafIdentifierService extends Service {
  get = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.stratHeliosLeafIdentifier}`, obj);
}

class StratSegRecLeafIdentifierService extends Service {
  get = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.stratSegRecLeafIdentifier}`, obj);
}

class EligibilityService extends Service {
  checkFlightsEligibility = (strategyTypeId: string, flights: RequestT) => (
    this.copilotAPI.post(`/eligibility/strategyType/${strategyTypeId}/`, flights)
  );

  flightFirst = (flights: RequestT) => (
    this.copilotAPI.post('/eligibility/flightFirst', flights)
  );
}

class StrategyRecommendationService extends Service {
  stratTypeRestrictions = (request) => (
    this.copilotAPI.post(`/${apiEnums.strategyRecommendation}/strategyTypeRestrictions/`, request)
  );
}

class StrategyGoalService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.strategyGoals}`);
  }

  getValidCustomFormulas = (request: RequestT) => (
    this.copilotAPI.get(`/${apiEnums.strategyGoals}/getValidCustomFormulas`, request)
  );
}

class AppNexusService extends Service {
  /**
   * Fetch a line item by id from Xandr
   * id: the external id of the line item
   */
  lineItem = async (memberId: number, id: string | number | Array<string | number>) => {
    const url = `/${apiEnums.appnexus}/paginated_api/${memberId}/line-item?id=${String(id)}`;

    // TODO we should always be using paginated_api to get all data from apn, below is a hack to make the response look
    // as it was before we used paginated_api
    const lineItem = await this.copilotAPI.get(url);
    lineItem.data = { response: { lineItem: lineItem.data.response } };
    return lineItem;
  };

  /**
   * Fetch a campaign by id from Xandr
   * id: the id of the campaign
   */
  campaign = async (memberId: Array<string>, campaignId: string) => {
    const url = `/${apiEnums.appnexus}/paginated_api/${String(memberId)}/campaign?id=${campaignId}`;
    const campaign = await this.copilotAPI.get(url);
    return campaign;
  };

  /**
   * Fetch a profile by id from Xandr
   * id: the id of the profile
   */
  profile = async (profileId: string | Array<string>, memberId: string) => {
    const url = `/${apiEnums.appnexus}/paginated_api/${memberId}/profile?id=${String(profileId)}`;
    const profile = await this.copilotAPI.get(url);
    return profile;
  };

  /**
   * Fetch pixels by id from Xandr.
   * id: the id of the pixel
   */
  pixelsById = async (memberId: number | string, pixelId: string | Array<number | string>) => {
    const url = `/${apiEnums.appnexus}/paginated_api/${memberId}/pixel?id=${pixelId}`;
    const pixels = await this.copilotAPI.get(url);
    return pixels;
  };

  /**
   * Fetch pixels for seat/advertiser.
   * seatId: The id of the seat from which you are requesting pixels.
   * advertiserId: The id of the advertiser from which you are requesting pixels.
   */
  pixels = async (seatId: string, advertiserId: string) => {
    const url = `/${apiEnums.appnexus}/paginated_api/${seatId}/pixel?advertiser_id=${advertiserId}`;
    // TODO we should always be using paginated_api to get all data from apn, below is a hack to make the response look
    // as it was before we used paginated_api
    const pixels = await this.copilotAPI.get(url);
    pixels.data = { response: { pixels: pixels.data.response } };
    return pixels;
  };

  ioById = async (memberId: number, ioExtIds: Array<string>): Promise<AxiosResponse<any>> => {
    const url = `/${apiEnums.appnexus}/paginated_api/${memberId}/insertion-order?id=${ioExtIds}`;
    return this.copilotAPI.get(url);
  };
}

class AdminService extends Service {
  checkAdblock = async () => {
    const url = `/${apiEnums.admin}/checkAdblock?advertiser=`;
    const response = this.copilotAPI.get(url);
    return response;
  };

  genAuthHeaders = () => this.copilotAPI.get(`${apiEnums.admin}/genAuthHeaders`);
}

class LambdaService extends Service {
  invokeLambdaFunction = (functionName: string, request: RequestT = { payload: {} }) => (
    this.copilotAPI.post(`/${apiEnums.lambda}/${functionName}`, request)
  );
}

class MicroservicesService extends Service {
  runService = (request: RequestT, service: string) => (
    this.copilotAPI.post(`/${apiEnums.microservices}/runService/${service}`, request)
  );

  getTaskStatus = (taskId: string) => (
    this.copilotAPI.get(`/${apiEnums.microservices}/runService/task_status`, { id: taskId })
  );

  getTaskResult = (taskId: string) => (
    this.copilotAPI.get(`/${apiEnums.microservices}/runService/task_result`, { id: taskId })
  );

  getCustomBiddingAlgorithms = (
    memberExtId: string,
    advertiserExtId: string,
    goalType?: string,
  ) => (
    this.copilotAPI.get(`/${apiEnums.microservices}/runService/list_custom_bidding_algorithms`, {
      member_ext_id: memberExtId,
      adv_ext_id: advertiserExtId,
      goal_type: goalType,
    })
  );

  getCustomBiddingAlgorithmScript = (
    memberExtId: string,
    algoExtId: string,
  ) => (
    this.copilotAPI.get(`/${apiEnums.microservices}/runService/get_custom_bidding_algorithm_script`, {
      member_ext_id: memberExtId,
      algo_ext_id: algoExtId,
    })
  );

  createCustomBiddingAlgorithm = (
    memberExtId: string,
    advertiserExtId: string,
    name: string,
    pixelToWeight: { [pid: string]: number },
    goalType: string,
  ) => (
    this.copilotAPI.post(`/${apiEnums.microservices}/runService/create_custom_bidding_algorithm`, {
      member_ext_id: memberExtId,
      adv_ext_id: advertiserExtId,
      name,
      pixel_to_weight: pixelToWeight,
      goal_type: goalType,
    })
  );

  updateCustomBiddingAlgorithm = (
    memberExtId: string,
    advertiserExtId: string,
    name: string,
    pixelToWeight: { [pid: string]: number },
    goalType: string,
    algoExtId: string,
  ) => (
    this.copilotAPI.post(`/${apiEnums.microservices}/runService/update_custom_bidding_algorithm`, {
      member_ext_id: memberExtId,
      adv_ext_id: advertiserExtId,
      name,
      pixel_to_weight: pixelToWeight,
      goal_type: goalType,
      algo_ext_id: algoExtId,
    })
  );

  archiveCustomBiddingAlgorithm = (memberExtId: string, algoExtId: string) => (
    this.copilotAPI.post(`/${apiEnums.microservices}/runService/archive_custom_bidding_algorithm`, {
      member_ext_id: memberExtId,
      algo_ext_id: algoExtId,
    })
  );
}

class ViewStrategyFlightRunService extends SmartService {
  groupedCount = (request: RequestT) => this.copilotAPI.get(`/${apiEnums.viewStrategyFlightRun}/groupedCount`, request);
}

class DeveloperApiTokenService extends Service {
  genApiToken = () => this.copilotAPI.get(`${apiEnums.developerApiToken}/generateApiToken`);

  get = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.developerApiToken}`, obj);
}

class TTDService extends Service {
  campaign = async (memberExtId: string, campaignExtIds: Array<string>) => {
    const p = _.map(campaignExtIds, (ceid) => this.copilotAPI.get(`/${apiEnums.dspService}/ttd/${memberExtId}/campaign/${ceid}`));
    const response = await Promise.all(p);
    return _.flatMap(response, 'data.response');
  };

  trackingTag = async (memberExtId: string, trackingTagIds: Array<string>) => {
    const p = _.map(trackingTagIds, (ttid) => this.copilotAPI.get(`/${apiEnums.dspService}/ttd/${memberExtId}/trackingtag/${ttid}`));
    const response = await Promise.all(p);
    return _.flatMap(response, 'data.response') as Array<TTDOrWMTTrackingTag>;
  };
}

class WMTService extends Service {
  campaign = async (memberExtId: string, campaignExtIds: Array<string>) => {
    const p = _.map(campaignExtIds, (ceid) => this.copilotAPI.get(`/${apiEnums.dspService}/wmt/${memberExtId}/campaign/${ceid}`));
    const response = await Promise.all(p);
    return _.flatMap(response, 'data.response');
  };

  trackingTag = async (memberExtId: string, trackingTagIds: Array<string>) => {
    const p = _.map(trackingTagIds, (ttid) => this.copilotAPI.get(`/${apiEnums.dspService}/wmt/${memberExtId}/trackingtag/${ttid}`));
    const response = await Promise.all(p);
    return _.flatMap(response, 'data.response') as Array<TTDOrWMTTrackingTag>;
  };
}

class UserService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.user}`);
  }

  sendRequestAccessEmail = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.user}/requestAccessEmail`, obj);

  sendAccessApprovedEmail = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.user}/accessApprovedEmail`, obj);

  validateUser = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.user}/validateUser`, obj);
}

class StarredUserStrategyService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.starredUserStrategy}`);
  }

  starStrategy = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.starredUserStrategy}/starStrategy`, obj);

  unstarStrategy = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.starredUserStrategy}/unstarStrategy`, obj);

  starMultipleStrategies = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.starredUserStrategy}/starMultipleStrategies`, obj);

  unstarMultipleStrategies = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.starredUserStrategy}/unstarMultipleStrategies`, obj);

  getStarredStrategies = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.starredUserStrategy}/getStarredStrategies`, obj);
}

class AdvertiserService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.advertiser}`);
  }

  validAdvertisersForBrand = () => this.copilotAPI.get(`/${apiEnums.advertiser}/validAdvertisersForBrand`);
}

class AdvertiserBrandService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.advertiserBrand}`);
  }

  brandsForAdvertiser = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.advertiserBrand}/brandsForAdvertiser`, obj);
}

class FlightCandidateService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.flightCandidate}`);
  }

  brandFlightCandidate = (obj: RequestT) => this.copilotAPI.get(`/${apiEnums.brandFlightCandidate}`, obj);
}

export class DBMLineItemService extends SmartService {
  constructor(copilotAPI: CopilotAPIT) {
    super(copilotAPI, `${apiEnums.dbmLineItem}`);
  }

  conversionFloodlightActivityIds = (obj: RequestT) => this.copilotAPI.get('/dbmLineItem/conversionFloodlightActivityIds', obj);
}

class ChatbotService extends Service {
  request = (obj: RequestT) => this.copilotAPI.post(`/${apiEnums.chatbot}`, obj);
}

// used for testing will be removed once copilotAPI has its own folder and index can be used as public interface
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
export const _AuthService = AuthService;
export const _MemberService = MemberService;
export const _AppNexusService = AppNexusService;
export const _CopilotAPI = CopilotAPI;
export const _RoleService = RoleService;
export const _UserRoleService = UserRoleService;

export const DSPs = new SmartService(inst, apiEnums.dsp);
export const BusinessModel = new SmartService(inst, apiEnums.businessModel);
export const BusinessUnit = new SmartService(inst, apiEnums.businessUnit);
export const Currency = new SmartService(inst, apiEnums.currency);
export const CurrencyExchange = new SmartService(inst, apiEnums.currencyExchange);
export const Country = new SmartService(inst, apiEnums.country);
export const FeeOption = new SmartService(inst, apiEnums.feeOption);
export const FeeType = new SmartService(inst, apiEnums.feeType);
export const Region = new SmartService(inst, apiEnums.region);
export const APNCampaign = new SmartService(inst, apiEnums.apnCampaign);
export const APNSegment = new SmartService(inst, apiEnums.apnSegment);
export const APNStandardFeedAnalytics = new SmartService(inst, apiEnums.apnStandardFeedAnalytics);
export const Brand = new SmartService(inst, apiEnums.brand);
export const ViewAPNCampaign = new SmartService(inst, apiEnums.viewApnCampaign);
export const ViewAPNInsertionOrder = new SmartService(inst, apiEnums.viewApnInsertionOrder);
export const ViewFlight = new SmartService(inst, apiEnums.viewFlight);
export const ViewStrategy = new SmartService(inst, apiEnums.viewStrategy);
export const ViewDBMLineItemFloodlightActivity = new SmartService(inst, apiEnums.viewDbmLineItemFloodlightActivity);
export const APNInsertionOrder = new SmartService(inst, apiEnums.apnInsertionOrder);
export const APNLineItemCache = new SmartService(inst, apiEnums.apnLineItemFilterCache);
export const Metadata = new SmartService(inst, apiEnums.metadata);
export const Flight = new SmartService(inst, apiEnums.flight);
export const Permission = new SmartService(inst, apiEnums.permission);
export const LoginDomain = new SmartService(inst, apiEnums.logindomain);
export const UserSettings = new SmartService(inst, apiEnums.userSettings);
export const Messages = new SmartService(inst, apiEnums.message);
export const MessageContext = new SmartService(inst, apiEnums.messageContext);
export const Algorithm = new SmartService(inst, apiEnums.algorithm);
export const AlgorithmType = new SmartService(inst, apiEnums.algorithmType);
export const StrategyType = new SmartService(inst, apiEnums.strategyType);
export const APNLineItem = new SmartService(inst, apiEnums.apnLineItem);
export const ViewTTDAdGroup = new SmartService(inst, apiEnums.viewTtdAdGroup);
export const TTDCampaign = new SmartService(inst, apiEnums.ttdCampaign);
export const WMTCampaign = new SmartService(inst, apiEnums.wmtCampaign);
export const ViewDBMLineItem = new SmartService(inst, apiEnums.viewDbmLineItem);
export const DBMInsertionOrder = new SmartService(inst, apiEnums.dbmInsertionOrder);
export const UserRoleApprover = new SmartService(inst, apiEnums.userRoleApprover);
export const ViewUser = new SmartService(inst, apiEnums.viewUser);
export const AMZNCampaign = new SmartService(inst, apiEnums.amznCampaign);
export const AMZNOrderPixel = new SmartService(inst, apiEnums.amznOrderPixel);
export const StrategyRevenueType = new SmartService(inst, apiEnums.strategyRevenueType);
export const Goal = new SmartService(inst, apiEnums.goal);
export const GoalWeight = new SmartService(inst, apiEnums.goalWeight);
export const ViewStrategyFlightMember = new SmartService(inst, apiEnums.viewStrategyFlightMember);
export const DemoConfiguration = new SmartService(inst, apiEnums.demoConfiguration);
/*
Add TTDAdGroup & TTDCampaign here
*/

export const Admin = new AdminService(inst);
export const Advertiser = new AdvertiserService(inst);
export const AdvertiserBrand = new AdvertiserBrandService(inst);
export const Airflow = new AirflowService(inst);
export const AFDagRun = new AFDagRunService(inst);
export const AMZNPixel = new AMZNPixelService(inst);
export const AppNexus = new AppNexusService(inst);
export const Auth = new AuthService(inst);
export const Chatbot = new ChatbotService(inst);
export const DBMLineItem = new DBMLineItemService(inst);
export const DeveloperApiToken = new DeveloperApiTokenService(inst);
export const Eligibility = new EligibilityService(inst);
export const Environment = new EnvironmentService(inst);
export const FlightCandidate = new FlightCandidateService(inst);
export const Issue = new IssueService(inst);
export const Jobs = new JobsService(inst, apiEnums.job);
export const Member = new MemberService(inst);
export const Message = new SmartService(inst, apiEnums.message);
export const Lambda = new LambdaService(inst);
export const Microservices = new MicroservicesService(inst);
export const Notifications = new NotificationService(inst);
export const Role = new RoleService(inst);
export const S3 = new S3Service(inst);
export const StratDefaultWizardConfig = new StratDefaultWizardConfigService(inst);
export const StratHeliosLeafAnalytics = new StratHeliosLeafAnalyticsService(inst);
export const StratSegRecLeafAnalytics = new StratSegRecLeafAnalyticsService(inst);
export const StratCustomTreeLeafAnalytics = new StratCustomTreeLeafAnalyticsService(inst);
export const StratHeliosLeafIdentifier = new StratHeliosLeafIdentifierService(inst);
export const StratSegRecLeafIdentifier = new StratSegRecLeafIdentifierService(inst);
export const StrategyGoalAnalytics = new StrategyGoalAnalyticsService(inst);
export const StrategyRecommendation = new StrategyRecommendationService(inst);
export const StrategyGoals = new StrategyGoalService(inst);
export const StarredUserStrategy = new StarredUserStrategyService(inst);
export const Search = new SearchService(inst);
export const Strategy = new StrategyService(inst);
export const StrategyAnalytics = new StrategyAnalyticsService(inst);
export const StrategyFlightRun = new StrategyFlightRunService(inst);
export const TTD = new TTDService(inst);
export const WMT = new WMTService(inst);
export const UserRole = new UserRoleService(inst);
export const Users = new UserService(inst);
export const ViewStrategyFlightRun = new ViewStrategyFlightRunService(inst, apiEnums.viewStrategyFlightRun);

export default inst;
