import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { WppTypography } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import withRouter from 'utils/withRouter';
import { RootState, GlobalState } from 'reducers';
import { Range } from 'utils/types';
import { initJobs, setRange } from './actions';
import { PAGE_NAME, REQUIRED_PERMISSIONS } from './constants';
import { JobProps, TimeZoneValue } from './types';
import { Filters, DataTable } from './components';

const Jobs: React.FC<JobProps> = (props) => {
  const { jobStatusOptions, jobTypeOptions, memberOptions } = useSelector((state: RootState) => state.jobs);
  const { router } = props;
  const user = useSelector<GlobalState>((state) => state.login.user);

  const [selectedTimezone, setSelectedTimezone] = useState<TimeZoneValue>(TimeZoneValue.utc);
  const dispatch = useDispatch();
  const setRangeDispatch = (range: Range) => { dispatch(setRange(range, router)); };

  useMount(() => {
    dispatch(initJobs(user, jobTypeOptions, jobStatusOptions, memberOptions, router));
  });

  const handleTimezoneChange = (newTimezone: TimeZoneValue) => {
    setSelectedTimezone(newTimezone);
  };

  const propsWithTimezone = {
    ...props,
    selectedTimezone,
    handleTimezoneChange,
  };

  return (
    <>
      <Filters {...propsWithTimezone} />
      <DataTable router={router} selectedTimezone={selectedTimezone} setRangeDispatch={setRangeDispatch} />
    </>
  );
};

const PermissionJobs: React.FC<JobProps> = (props) => (
  <WppPermissionPageTemplate
    title={PAGE_NAME}
    name={PAGE_NAME}
    permissions={REQUIRED_PERMISSIONS}
    user={props.user}
    withoutLayout
    customHeader={(
      <WppTypography tag="p" type="3xl-heading">Jobs</WppTypography>
    )}
  >
    <Jobs {...props} />
  </WppPermissionPageTemplate>
);

export default withRouter(PermissionJobs);
