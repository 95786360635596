// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-button::part(button) {
  height: 68px;
  width: 36px;
  padding: 0;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
  z-index: 1000000000;
}

.chatbot-input::part(textarea) {
  border: none;
  background: white;
}

.chat-messages-container {
  font-family: Inter !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.chat-messages-container h1, .chat-messages-container h2, .chat-messages-container h3, .chat-messages-container h4, .chat-messages-container h5, .chat-messages-container h6 {
  font-family: Inter !important;
}
.chat-messages-container::-webkit-scrollbar {
  display: none;
}
.chat-messages-container .chatbot-feedback-container .wpp-action-button::part(button) {
  padding: 4px 6px !important;
}
.chat-messages-container .chatbot-feedback-container .wpp-action-button .wpp-icon {
  color: var(--wpp-grey-color-800);
}

.chatbot-prompt::part(button) {
  color: #121619;
  padding: 5px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.chatbot-prompt::part(button), .chatbot-prompt::part(overlay) {
  border-radius: 64px;
  border: 1px solid #A2A9B0;
}

.support-link-msg::part(button) {
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: 100%;
  height: 100%;
  background-color: #F8F9FB;
}
.support-link-msg::part(button):hover {
  background-color: rgba(105, 112, 119, 0.12);
}
.support-link-msg::part(button), .support-link-msg::part(overlay) {
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Chatbot/chatbotOverrides.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,UAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;EACE,6BAAA;EAIA,wBAAA;EACA,qBAAA;AAFF;AAFE;EACE,6BAAA;AAIJ;AAAE;EACE,aAAA;AAEJ;AAGM;EACA,2BAAA;AADN;AAGM;EACE,gCAAA;AADR;;AAQE;EACE,cAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AALJ;AAOE;EACE,mBAAA;EACA,yBAAA;AALJ;;AAUE;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;AAPJ;AAQI;EACE,2CAAA;AANN;AASE;EACE,kBAAA;AAPJ","sourcesContent":[".chatbot-button::part(button) {\n  height: 68px;\n  width: 36px;\n  padding: 0;\n  align-items: center;\n  border-radius: 4px 0px 0px 4px;\n  z-index: 1000000000;\n}\n\n.chatbot-input::part(textarea) {\n  border: none;\n  background: white;\n}\n\n.chat-messages-container {\n  font-family: Inter !important;\n  h1, h2, h3, h4, h5, h6 {\n    font-family: Inter !important;\n  }\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  .chatbot-feedback-container {\n    .wpp-action-button {\n      &::part(button) {\n      padding: 4px 6px !important;\n      }\n      .wpp-icon {\n        color: var(--wpp-grey-color-800);\n      }\n    }\n  }\n}\n\n.chatbot-prompt {\n  &::part(button) {\n    color: #121619;\n    padding: 5px 12px;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 20px;  \n  }\n  &::part(button), &::part(overlay) {\n    border-radius: 64px;\n    border: 1px solid #A2A9B0;\n  }\n}\n\n.support-link-msg {\n  &::part(button) {\n    justify-content: center;\n    align-items: center;\n    font-weight: 500;\n    width: 100%;\n    height: 100%;\n    background-color: #F8F9FB;\n    &:hover {\n      background-color: rgb(105, 112, 119, .12);\n    }\n  }\n  &::part(button), &::part(overlay) {\n    border-radius: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
