import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { WppListItem, WppSelect } from 'buildingBlocks';
import { AWGDimensions, DSP, AmznConversionEvents } from 'constantsBase';
import { WeightObj } from 'containers/StrategyWizard/types';
import { getDimensionText, isAmazonDsp, addWeightValueToMetric } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import WeightsList from './WeightsList';
import { useWeightsSectionContext } from '../contexts/WeightsSectionProvider';

type DspAccordionProps = {
  dspId: string
  flights: WeightObj
  flightsToDisplay: ImmutableMap<string, WeightObj>
  setFlightsToDisplay: Dispatch<SetStateAction<ImmutableMap<string, WeightObj>>>
};

const DspAccordion = (props: DspAccordionProps) => {
  const { dspId, flights, flightsToDisplay, setFlightsToDisplay } = props;
  const { selectedDimension, selectedMetric, sessionMetrics, setSelectedEvents, selectedEvents, setSessionMetrics } = useWeightsSectionContext();
  const dspCode = DSP.getById(dspId).code;
  const amazonDsp = isAmazonDsp(dspId);
  const isPixelDimension = selectedDimension === AWGDimensions.pixel;
  const isAmznPixelDimension = amazonDsp && isPixelDimension;
  let selectedConversionMetric = [];
  /*
  * condition to get already selected conversion metrics in edit case or if user clicks assign weights again after setting up weights
  * this is only applicable for amazon and pixel dimension
  */
  if (selectedMetric && isAmznPixelDimension && sessionMetrics.getIn([selectedMetric, 'weighting'], {}).toJS()[dspId]) {
    selectedConversionMetric = Object.keys(sessionMetrics.getIn([selectedMetric, 'weighting'], {}).toJS()[dspId]);
  }
  selectedConversionMetric = _.size(_.get(selectedEvents, selectedMetric)) ? selectedEvents[selectedMetric] : selectedConversionMetric;
  const [selectedConversionEvents, setSelectedConversionEvents] = useState<Object>({ ...selectedEvents, [selectedMetric]: selectedConversionMetric });
  const isWeightedValue = sessionMetrics.toJS()[selectedMetric] ? sessionMetrics.toJS()[selectedMetric].isWeighted : false;
  const noFlightsFoundMsgText = isPixelDimension && _.isString(flights)
    ? <span> Error loading {dspCode} Pixels</span>
    : <span>No {getDimensionText(selectedDimension)}s found.</span>;

  useEffect(() => {
    // this is to check if conversion events are not set for selected metrics in edit case then save those, only applicable for amazon and pixel dimension
    if (isAmznPixelDimension && !selectedConversionEvents[selectedMetric]) {
      setSelectedConversionEvents({ ...selectedConversionEvents, [selectedMetric]: [...selectedConversionMetric] });
      setSelectedEvents({ ...selectedConversionEvents, [selectedMetric]: [...selectedConversionMetric] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMetric, selectedDimension, dspCode, selectedConversionEvents, selectedConversionMetric]);

  useEffect(() => {
    // when dimension is changed and metric is not weighted then deselect all selected conversion events for selected metric
    if (isAmznPixelDimension && !isWeightedValue) {
      setSelectedConversionEvents({ ...selectedConversionEvents, [selectedMetric]: [] });
      setSelectedEvents({ ...selectedConversionEvents, [selectedMetric]: [] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimension]);

  const handleChange = (event: CustomEvent) => {
    // if user add new conversion metric then add it in session metrics
    if (_.size(event.detail.value) > _.size(selectedConversionEvents[selectedMetric])) {
      const addedEvent = _.filter(event.detail.value, (conversionEvent) => !_.includes(selectedConversionEvents[selectedMetric], conversionEvent));
      if (_.size(addedEvent)) {
        const updatedSessionMetric = addWeightValueToMetric('1', addedEvent[0], dspId, sessionMetrics, selectedMetric);
        setSessionMetrics(updatedSessionMetric);
      }
    }
    // if user unchecked conversion metric already added then remove it from session metrics
    if (_.size(event.detail.value) < _.size(selectedConversionEvents[selectedMetric])) {
      const removedEvent = _.filter(selectedConversionEvents[selectedMetric], (conversionEvent) => !_.includes(event.detail.value, conversionEvent));
      if (_.size(removedEvent)) {
        const updatedSessionMetric = sessionMetrics.deleteIn([selectedMetric, 'weighting', dspId, removedEvent[0]]);
        setSessionMetrics(updatedSessionMetric);
      }
    }
    setSelectedConversionEvents({ ...selectedConversionEvents, [selectedMetric]: [...event.detail.value] });
    setSelectedEvents({ ...selectedConversionEvents, [selectedMetric]: [...event.detail.value] });
  };
  const selectedList = (selectedConversionEvents[selectedMetric] ? selectedConversionEvents[selectedMetric] : []);
  const weightedList = (_.size(flights)
    ? (
      <WeightsList
        dspId={dspId}
        weightsList={flights}
        flightsToDisplay={flightsToDisplay}
        setFlightsToDisplay={setFlightsToDisplay}
        selectedConversionEvents={selectedList}
      />
    )
    : noFlightsFoundMsgText);

  return (
    <>
      {isAmznPixelDimension // only when dsp is amazon and dimension is pixel then add select box
        ? (
          <>
            <div style={AWG_WEIGHTS_MODAL_STYLES.selectBoxAlignment}>
              <WppSelect
                placeholder="Amazon Conversion Events"
                value={selectedList}
                type="multiple"
                withSearch
                onWppChange={handleChange}
              >
                {
                  _.map(Object.values(AmznConversionEvents), (event: string) => (
                    <WppListItem value={event} key={event}>
                      <p slot="label">{event}</p>
                    </WppListItem>
                  ))
                }
              </WppSelect>
            </div>
            <WeightsList
              dspId={dspId}
              weightsList={flights}
              flightsToDisplay={flightsToDisplay}
              setFlightsToDisplay={setFlightsToDisplay}
              selectedConversionEvents={selectedList}
            />
          </>
        ) : weightedList}
    </>
  );
};

export default DspAccordion;
