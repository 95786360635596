/* eslint-disable react/prop-types */
import _ from 'lodash';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WppGrid, WppCheckbox } from 'buildingBlocks';
import DatePicker from './CustomDatePicker';
import '../override.css';

type EndDateOptionProps = {
  hasExpirationDate: boolean
};

const EndDateOption = ({ hasExpirationDate }: EndDateOptionProps) => {
  const { control, setValue } = useFormContext();
  const startDate = useWatch({ name: 'startDate' });
  const endDate = useWatch({ name: 'endDate' });
  const minEndDate = moment(startDate).add(1, 'd');

  const handleEndDateToggle = () => {
    // set endDate to null if no expiration date
    const updatedEndDate = hasExpirationDate ? null : moment();
    setValue('endDate', updatedEndDate);
    setValue('hasExpirationDate', !hasExpirationDate);
  };

  useEffect(() => {
    if (hasExpirationDate) {
      if (moment(endDate).diff(startDate, 'day') <= 0) {
        setValue('endDate', minEndDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasExpirationDate, startDate]);

  return (
    <WppGrid>
      <Controller
        name="hasExpirationDate"
        control={control}
        render={(props) => (
          <WppCheckbox
            labelConfig={{ text: 'Message will expire at a specific date' }}
            checked={props.field.value}
            required
            onWppChange={handleEndDateToggle}
            {..._.omit(props, 'formState')}
          />
        )}
      />
      {hasExpirationDate && (
        <Controller
          name="hasExpirationDate"
          control={control}
          render={(props) => (
            <DatePicker
              placeholder="Select End Date"
              selected={props.field.value}
              endDate={props.field.value}
              minDate={minEndDate}
              onChange={props.field.onChange}
              closeOnSelect
              hasMinDate
              {..._.omit(props, 'formState')}
            />
          )}
        />
      )}
    </WppGrid>
  );
};

export default EndDateOption;
