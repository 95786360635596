import React from 'react';
import _ from 'lodash';
import { List, WppModal, WppTypography, WppButton, WppInlineMessage } from 'buildingBlocks';
import { deleteConfirmModalBtn } from './style';

type Props = {
  itemsToDelete: { [key: string]: { id: number } }
  onCancel: (...args: Array<any>) => void
  onDelete: (...args: Array<any>) => void
  open: boolean
  deleting?: boolean
  footerMessage?: string
  modalName: string
  deleteMessage?: string
  itemName?: string
  disabled?: boolean
};

type RemoveModalListProps = {
  itemsToDelete: { [key: string]: { id: number } }
  itemName?: string
};

export const RemoveModalList = ({ itemsToDelete, itemName = 'name' }: RemoveModalListProps) => (
  <List celled>
    {
      _.map(itemsToDelete, (item) => (
        <List.Item key={item.id}>
          <List.Icon name="trash" color="red" />
          <List.Content>
            {_.get(item, itemName, item.id)}
          </List.Content>
        </List.Item>
      ))
    }
  </List>
);

export const DeleteConfirmationModalComponent = ({
  itemsToDelete,
  onCancel,
  open,
  onDelete,
  deleting,
  footerMessage,
  modalName,
  deleteMessage,
  itemName,
  disabled,
}: Props) => (
  <WppModal open={open}>
    <WppTypography slot="header" tag="p" type="xl-heading">{modalName}</WppTypography>
    <div slot="body">
      {deleteMessage && <p>{deleteMessage}</p>}
      <RemoveModalList
        itemsToDelete={itemsToDelete}
        itemName={itemName}
      />
      <WppTypography tag="p" type="s-body">Are you sure you wish to proceed? This action cannot be undone.</WppTypography>
    </div>
    <div slot="actions">
      {footerMessage && (<WppInlineMessage size="s" message={footerMessage} type="error" />)}
      <div style={deleteConfirmModalBtn}>
        <WppButton variant="secondary" size="s" onClick={onCancel}>Cancel</WppButton>
        <WppButton variant="destructive" size="s" onClick={() => onDelete(itemsToDelete)} loading={deleting} disabled={disabled}>Delete</WppButton>
      </div>
    </div>
  </WppModal>
);

export default DeleteConfirmationModalComponent;
