import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Grid, Divider } from 'buildingBlocks';
import SegmentPicker from 'containers/StrategyWizard/components/SegmentPicker';
import BidSettingsComponent from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/components/BidSettingsComponent';
import { BidTypeValue } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { getBidsAndBidType } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/utils';
import { APNSegment } from 'containers/StrategyWizard/ConfigurationByStrategyType/segmentUtils';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { LineItem } from 'utils/types';
import { getSegments } from '../utils';

const { column, divider } = strategyWizardStyles;

type Props = {
  segments: Array<APNSegment>,
  strategyId: number,
  attachedLIs: Array<LineItem>,
  count: number
  setCount: (x: any) => void
};

const SRForm = ({
  segments,
  strategyId,
  attachedLIs,
  count,
  setCount,
}: Props) => {
  const minBid = useWatch({ name: 'minBid' });
  const maxBid = useWatch({ name: 'maxBid' });

  const startingMinBid = useWatch({ name: 'startingMinBid' });
  const startingMaxBid = useWatch({ name: 'startingMaxBid' });
  const maxDays = useWatch({ name: 'maxDays' });

  const {
    wizardFormValues: {
      attachFlightsStep: { member, advertiser, attachedFlights, defaultCurrency },
      strategyTypeSelectionStep: { strategyType },
    },
  } = useStrategyWizardContext();

  const [includeNetworkSegments, setIncludeNetworkSegments] = useState(true);
  const segmentLookup = _.keyBy(segments, 'externalId');
  const { bids, bidType } = getBidsAndBidType(attachedFlights, strategyType.id);

  return (
    <Grid stackable celled="internally">
      <Grid.Row>
        <Grid.Column style={column}>
          <Controller
            name="segmentGroups"
            render={({ field, fieldState }) => (
              <SegmentPicker
                dataProvider={getSegments(
                  member.id,
                  advertiser.externalId,
                  includeNetworkSegments,
                )}
                preventCloning
                enableItemReordering
                enableGroupReordering
                showIncludeExcludeSelector={false}
                cannotDeleteAllSegmentGroups
                segmentLookup={segmentLookup}
                onFilterExcludedIds={setIncludeNetworkSegments}
                filterAllSegments={includeNetworkSegments}
                currency={advertiser.defaultCurrency}
                strategyId={strategyId}
                bidType={bidType}
                globalDefaultBidValues={{
                  startingMinBid,
                  startingMaxBid,
                  maxDays,
                }}
                field={field}
                fieldState={fieldState}
                onChange={field.onChange}
                count={count}
                setCount={setCount}
              />
            )}
          />
        </Grid.Column>
      </Grid.Row>
      <Divider style={divider} />
      {
        !_.isEmpty(attachedFlights)
        && (
          <BidSettingsComponent
            currency={defaultCurrency}
            bidType={bidType as BidTypeValue}
            bids={bids}
            attachedLIs={attachedLIs}
            minBidMultiplier={minBid}
            maxBidMultiplier={maxBid}
          />
        )
      }
    </Grid>
  );
};

export default SRForm;
