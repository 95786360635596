import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WppInput, ValidatedCustomWppInput as NameField, WppGrid, WppDivider } from 'buildingBlocks';
import { ViewUser } from 'utils/copilotAPI';
import { WppInputCustomEvent, InputChangeEventDetail } from 'utils/types';
import { Mode } from '../types';
import { userFormRowPadding } from '../style';

// eslint-disable-next-line no-useless-escape
const validEmailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

type Props = {
  userId: string | number
  disabled: boolean
  mode: number
  emailValidated: boolean
  setEmailValidated: (x: any) => void
};
const UserInfo = ({ userId, disabled, mode, emailValidated, setEmailValidated }: Props) => {
  const [validatingEmail, setValidatingEmail] = useState<boolean>(false);
  const email = useWatch({ name: 'email' });
  const [emailError, setEmailError] = useState<any>(email ? false : { content: 'Required' });
  const { control } = useFormContext();

  const getUserEmailError = () => {
    if (!_.isEqual(mode, Mode.create)) {
      return false;
    }
    return emailError;
  };

  // eslint-disable-next-line consistent-return
  const validateEmail = async () => {
    if (!email) {
      setEmailError({ content: 'Required' });
    }
    if (email && !validatingEmail) {
      await setValidatingEmail(true);
      const emailIsValid = validEmailFormat.test(email);
      const dbCheck = await ViewUser.get({ email, limit: 1 });
      if (!_.isEmpty(dbCheck.data)) {
        setEmailError({ content: 'This email address is already in use' });
        setEmailValidated(false);
      } else if (!emailIsValid) {
        setEmailError({ content: 'Please enter a valid email format' });
        setEmailValidated(false);
      } else {
        setEmailError(false);
        setEmailValidated(true);
      }
      setValidatingEmail(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateWrapper = useCallback(_.debounce(validateEmail, 1000), [validateEmail]);

  useEffect(() => {
    validateWrapper();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <WppGrid item all={24}>
      <WppGrid container fullWidth style={userFormRowPadding}>
        <WppGrid item all={7}>
          <Controller
            name="firstName"
            rules={{ required: { value: true, message: 'Required' } }}
            control={control}
            render={({ field, fieldState }) => (
              <NameField
                type="text"
                placeholder="First Name"
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(e.detail.value)}
                field={field}
                fieldState={fieldState}
                disabled={disabled}
                skipIsDirtyCheck
                labelConfig={{ text: 'First Name' }}
                required
              />
            )}
          />
        </WppGrid>
        <WppGrid item all={7}>
          <Controller
            name="lastName"
            rules={{ required: { value: true, message: 'Required' } }}
            control={control}
            render={({ field, fieldState }) => (
              <NameField
                type="text"
                placeholder="Last Name"
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(e.detail.value)}
                field={field}
                fieldState={fieldState}
                disabled={disabled}
                skipIsDirtyCheck
                labelConfig={{ text: 'Last Name' }}
                required
              />
            )}
          />
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth style={userFormRowPadding}>
        <WppGrid item all={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <WppInput
                name="email"
                value={field.value}
                disabled={!(_.isNaN(userId))}
                placeholder={'User\'s Email Address'}
                labelConfig={{ text: 'Email' }}
                message={!emailValidated ? getUserEmailError().content : ''}
                messageType={!emailValidated && getUserEmailError().content ? 'error' : undefined}
                onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => field.onChange(e.detail.value)}
                required
              />
            )}
          />
        </WppGrid>
      </WppGrid>
      <WppGrid container fullWidth>
        <WppGrid item all={24}>
          <WppDivider style={userFormRowPadding} />
        </WppGrid>
      </WppGrid>
    </WppGrid>
  );
};

export default UserInfo;
