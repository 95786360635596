import React from 'react';
import { WppActionButton, WppIconTrash, WppPill, WppPillGroup } from 'buildingBlocks';

type Props = {
  /** Message displayed to user next to delete icon. */
  displayMessage: string
  /**
   * Function called when delete icon is clicked, which may manage a resulting
   * modal.
   */
  toggleModal: (event: React.MouseEvent<HTMLWppActionButtonElement, MouseEvent>) => void
  resetRevOptions: () => void
};

export const DeletePanelComponent = ({ displayMessage, toggleModal, resetRevOptions }: Props) => (
  <div>
    <WppPillGroup type="display">
      <WppPill label={displayMessage} onWppClose={resetRevOptions} removable />
    </WppPillGroup>
    <WppActionButton variant="destructive" onClick={toggleModal}>
      Delete
      <WppIconTrash slot="icon-start" />
    </WppActionButton>
  </div>
);

export default DeletePanelComponent;
