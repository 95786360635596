import { COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const noBorderRadius = {
  borderRadius: 0,
  borderStyle: 'none',
};

export const inputField = {
  width: '100%',
  borderRadius: 0,
};

export const textLabel = {
  color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.METTLES.M700_OSLO,
  fontWeight: 'normal',
};

export const addButton = {
  marginTop: '23px',
  height: '35px',
};

export const pageLoader = {
  container: {
    marginTop: '80px',
    width: '100%',
  },
  divWrapper: {
    position: 'relative' as 'relative',
    height: '400px',
  },
  fullHeightAndWidth: {
    minHeight: '350px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export const breadCrumContainer = {
  display: 'flex',
  marginBottom: SPACING[8],
};

export const breadCrumBackLink = {
  marginLeft: SPACING[4],
  lineHeight: '15px',
};

export const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: SPACING[12],
};

export const headerContainer = {
  width: '100%',
};

export const userFormRowPadding = {
  paddingBottom: SPACING[32],
};

export const bottomPadding = {
  paddingBottom: SPACING[12],
};

export const approverHelpText = {
  color: COPILOT_COLORS.WPP.blue500,
  marginLeft: '5px',
  cursor: 'pointer',
};

export const memberRoleError = {
  color: COPILOT_COLORS.WPP.danger500,
};

export const footerStyle = {
  display: 'flex',
  justifyContent: 'right',
  gap: SPACING[12],
  paddingRight: '0px',
  paddingTop: SPACING[32],
};

export const sideModalFooter = {
  display: 'flex',
  justifyContent: 'right',
  gap: SPACING[12],
  paddingRight: '0px',
};

export const pageLoaderStyle = {
  marginTop: '100px',
};

export const memberRolesTable = {
  actionStyle: {
    minWidth: '125px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  memberColumnStyle: {
    maxWidth: '425px',
  },
};
