import React from 'react';

// ENUMS
export enum ChatRole {
  user = 'user',
  assistant = 'assistant',
}

export enum Feedback {
  thumbsUp = 'thumbsUp',
  thumbsDown = 'thumbsDown',
}

// TYPES
export type ChatMessageType = {
  role: ChatRole
  content: string
};

export type MessagesType = Array<ChatMessageType>;

export type HandleSubmitType = (event: React.MouseEvent<HTMLWppActionButtonElement, MouseEvent> | React.KeyboardEvent<HTMLWppTextareaInputElement>, customInput?: string) => void;

export type FeedbackMessagesType = [ChatMessageType, ChatMessageType];

// CONSTANTS
export const MAX_CHAR_COUNT = 2000;

export const initialAssistantMessage = 'How can I assist you today?';

export const initialMessagesState = [{ role: ChatRole.assistant, content: initialAssistantMessage }];

export const userPrompts = [
  'How do I access Copilot?',
  'How do I connect my DSP?',
  'I\'m having login issues',
];

export const errorMessage = 'Sorry there was an error. Please contact Copilot support for your question.';
