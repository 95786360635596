import React from 'react';
import WizardForm from './StrategyWizard';
import StrategyWizardProvider from './contexts/StrategyWizardProvider';

// wrap the StrategyWizard component with the provider
const StrategyWizard = () => (
  <StrategyWizardProvider>
    <WizardForm />
  </StrategyWizardProvider>
);

export default StrategyWizard;
