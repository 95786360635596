import React, { useState } from 'react';
import { WppActionButton, WppIconCopy, WppIconDislike, WppIconDone, WppIconLike, WppIconRefresh } from 'buildingBlocks';
import { Feedback, FeedbackMessagesType, HandleSubmitType } from '../constants';
import CHATBOT_STYLES from '../styles';
import { sendFeedback } from '../utils';

const { feedbackContainer } = CHATBOT_STYLES;

type ChatbotFeedbackProps = {
  onRefreshClick: HandleSubmitType
  onCopyClick: () => void
  feedbackMessages: FeedbackMessagesType
};

const ChatbotFeedback = (props: ChatbotFeedbackProps) => {
  const { onRefreshClick, onCopyClick, feedbackMessages } = props;
  const [showCopyBtn, setShowCopyBtn] = useState<boolean>(true);
  const [showThumbsUp, setShowThumbsUp] = useState<boolean>(true);
  const [showThumbsDown, setShowThumbsDown] = useState<boolean>(true);

  const handleCopyClick = () => {
    // temporariliy change copy icon to indicate response has been copied
    setShowCopyBtn(false);
    onCopyClick();
    setTimeout(() => setShowCopyBtn(true), 1000);
  };

  return (
    <div style={feedbackContainer} className="chatbot-feedback-container">
      <WppActionButton
        variant="primary"
        onClick={handleCopyClick}
      >
        {showCopyBtn ? <WppIconCopy /> : <WppIconDone />}
      </WppActionButton>
      <WppActionButton
        variant="primary"
        onClick={() => sendFeedback(feedbackMessages, Feedback.thumbsUp, setShowThumbsUp)}
      >
        {showThumbsUp ? <WppIconLike /> : <WppIconDone />}
      </WppActionButton>
      <WppActionButton
        variant="primary"
        onClick={() => sendFeedback(feedbackMessages, Feedback.thumbsDown, setShowThumbsDown)}
      >
        {showThumbsDown ? <WppIconDislike /> : <WppIconDone />}
      </WppActionButton>
      <WppActionButton
        variant="primary"
        onClick={onRefreshClick}
      >
        <WppIconRefresh />
      </WppActionButton>
    </div>
  );
};

export default ChatbotFeedback;
