import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';

const { WPP: { blue500 } } = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

export const jobTypeLabel = {
  marginRight: '5px',
  color: blue500,
};

export const jobStatusLabel = {
  fontWeight: '400',
};

export const containerLayout = {
  marginTop: SPACING[24],
};
