import { CSSProperties } from 'react';
import {
  GridProps as GridPropsSUIReact,
  SemanticICONS as SemanticICONSSUIReact,
  SemanticSIZES as SemanticSIZESSUIReact,
  SemanticWIDTHS as SemanticWIDTHSSUIReact,
  SemanticCOLORS as SemanticCOLORSSUIReact,
  InputProps as InputPropsSUIReact,
  ModalProps as ModalPropsSUIReact,
  MenuProps as MenuPropsSUIReact,
  ButtonProps as ButtonPropsSUIReact,
  FormDropdownProps as FormDropdownPropsSUIReact,
  TextAreaProps as TextAreaPropsSUIReact,
} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SegmentSizeProp as SemanticSizePropSUIReact } from 'semantic-ui-react/dist/commonjs/elements/Segment/Segment';
import DatePickerInput from './DatePickerInput';
import Dropdown from './Dropdown';
import Checkbox from './Checkbox';
import List from './List';
import ObjectDropdown, {
  ObjectDropdownSearch as ObjectDropdownSearchImport,
  ObjectDropdownProps as ObjectDropdownPropsImport,
} from './ObjectDropdown';
import ObjectMultiDropdown, {
  ObjectMultiDropdownProps as ObjectMultiDropdownPropsImport,
} from './ObjectMultiDropdown';
import ModalMultiSelect from './TwoListMultiSelect/ModalMultiSelect';
import MultiSelect from './TwoListMultiSelect/MultiSelect';
import WppMultiSelect from './MultiSelect/MultiSelect';
import Table from './Table';
import WarningPopup from './WarningPopup';
import {
  AgGrid,
  AgGridActionButton,
  AgGridHeader,
  AgGridDeleteRowPanel,
} from './AgGrid';
import WppDivider from './Divider';
import WppDimmer from './WppDimmer';
import WppSpinner from './Spinner';
import ValidatedCustomWppInput from './ReactHookFormComponents/ValidatedCustomWppInput';
import ValidatedWppFieldInlineEdit from './ReactHookFormComponents/ValidatedWppFieldInlineEdit';
import {
  WppTable,
  WppTableBodyCell,
  WppTableHeader,
  WppTableHeaderCell,
  WppTableHeaderRow,
  WppTableBodyRow,
  WppTableBody,
} from './WppTable';

export type FieldWrapper = {
  name: string
  component: any
  onChange?: any
  required?: boolean
  className?: string
  style?: CSSProperties
  normalize?: Function
  id?: string
};

export type OmitKeys<T, K> = Pick<T, Exclude<keyof T, K>>;
export type Omit<T, U> = Pick<T, Exclude<keyof T, keyof U>>;

export type FieldProps<T> = FieldWrapper & OmitKeys<T, 'input' | 'meta'>;

export {
  DatePickerInput,
  Dropdown,
  Checkbox,
  List,
  ObjectDropdown,
  ObjectMultiDropdown,
  ModalMultiSelect,
  MultiSelect,
  Table,
  WarningPopup,
  ValidatedCustomWppInput,
  ValidatedWppFieldInlineEdit,
};

export type GridProps = GridPropsSUIReact;
export type SemanticCOLORS = SemanticCOLORSSUIReact;
export type SemanticICONS = SemanticICONSSUIReact;
export type SemanticSIZES = SemanticSIZESSUIReact;
export type SemanticWIDTHS = SemanticWIDTHSSUIReact;
export type SegmentSizeProp = SemanticSizePropSUIReact;
export type InputProps = InputPropsSUIReact;
export type ObjectDropdownProps<T> = ObjectDropdownPropsImport<T>;
export type ObjectDropdownSearch = ObjectDropdownSearchImport;
export type ObjectMultiDropdownProps<T> = ObjectMultiDropdownPropsImport<T>;
export type ModalProps = ModalPropsSUIReact;
export type MenuProps = MenuPropsSUIReact;
export type ButtonProps = ButtonPropsSUIReact;
export type FormDropdownProps = FormDropdownPropsSUIReact;
export type TextAreaProps = TextAreaPropsSUIReact;

export {
  Accordion,
  Button,
  Breadcrumb,
  Card,
  Confirm,
  Container,
  Divider,
  Flag,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Dimmer,
  Loader,
  Menu,
  Message,
  Modal,
  Popup,
  Progress,
  Reveal,
  Segment,
  Sidebar,
  Statistic,
  Tab,
  TextArea,
  Transition,
  Step,
  Search,
  Radio,
} from 'semantic-ui-react';

export {
  WppInput,
  WppCheckbox,
  WppPill,
  WppPopover,
  WppProgressIndicator,
  WppAvatar,
  WppActionButton,
  WppIconButton,
  WppSelect,
  WppTooltip,
  WppButton,
  WppTextareaInput,
  WppInlineMessage,
  WppListItem,
  WppCard,
  WppRadio,
  WppToggle,
  WppPillGroup,
  WppGrid,
  WppPagination,
  WppPaginationSelect,
  WppTypography,
  WppTopbar,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppBanner,
  WppTag,
  WppLabel,
  WppStepper,
  WppDivider as WppObjectDivider,
  WppSpinner as WppObjectSpinner,
  WppStep,
  WppBreadcrumb,
  WppEmptyContent,
  WppEmptyNothingFound,
  WppSkeleton,
  WppMenuContext,
  WppSortButton,
  WppModal,
  WppCardGroup,
  WppSideModal,
  WppIconChevron,
  WppIconAirplane,
  WppIconBarChart,
  WppIconDownload,
  WppIconLike,
  WppIconDislike,
  WppIconEdit,
  WppIconRefresh,
  WppIconMore,
  WppIconTrash,
  WppIconImport,
  WppIconRedo,
  WppIconPlus,
  WppIconNotification,
  WppIconEye,
  WppIconSearch,
  WppIconClose,
  WppIconSupportChat,
  WppIconUndo,
  WppIconNotificationNew,
  WppIconGear,
  WppIconInfo,
  WppIconDone,
  WppIconExternalLink,
  WppIconLogout,
  WppIconError,
  WppIconSend,
  WppToastContainer,
  WppIconFile,
  WppIconFavourites,
  WppIconFavouritesFilled,
  WppTab,
  WppTabs,
  WppAccordion,
  WppSlider,
  WppExpandableCard,
  WppIconCopy,
  WppDatepicker,
  WppTree,
  WppIconHelp,
  WppIconAdd,
  WppInlineEdit,
  WppIconTrendAscend,
  WppIconTrendDescent,
  WppIconMailRead,
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
  WppIconMoney,
  WppEmptyTable,
  WppIconWarning,
  WppIconSort,
} from '@wppopen/components-library-react';

export { AgGridReact } from 'ag-grid-react';

export {
  AgGrid,
  AgGridActionButton,
  AgGridHeader,
  AgGridDeleteRowPanel,
  WppDivider,
  WppMultiSelect,
  WppSpinner,
  InfiniteScroll,
  WppDimmer,
  WppTable,
  WppTableBodyCell,
  WppTableHeader,
  WppTableHeaderCell,
  WppTableHeaderRow,
  WppTableBodyRow,
  WppTableBody,
};
