import _ from 'lodash';
import moment from 'moment';
import { SyntheticEvent } from 'react';
import { Dispatch } from 'redux';
import { eventChannel } from 'redux-saga';
import { MessagePriorities } from 'constantsBase';
import { Notifications } from 'utils/copilotAPI';
import { dateTimeFormatter } from 'utils/dateTime';
import { User } from 'utils/types';
import {
  NOTIFICATIONS_ELEMENTS_PER_PAGE,
  NOTIFICATIONS_FETCH_EXISTING,
  NOTIFICATIONS_FETCH_UPDATES,
  NOTIFICATIONS_READ_FAILED,
  SelectedCategoryFilter,
  ActiveTab,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from './constants';
import { NotificationMsgObject } from './types';

export const getNotificationAge = (createdAtDate: string) => {
  // date is provided from DB as string without timezone so must convert to UTC
  const timeFromNow = moment.utc(createdAtDate).fromNow();
  return _.endsWith(timeFromNow, 'ago') ? timeFromNow : `${timeFromNow} ago`;
};

const TEXT_CUTOFF = 42;
export const truncateMessageContent = (content: string, max: number = TEXT_CUTOFF) => (
  _.truncate(content, { length: max, separator: /([\n[]|[,!?] +?)/ })
);

export const getActionNotifications = (notifications: Array<NotificationMsgObject>) => (
  _.filter(notifications, (n) => _.includes([MessagePriorities.medium, MessagePriorities.high], n.priority))
);

export const getUpdateNotifications = (notifications: Array<NotificationMsgObject>) => (
  _.filter(notifications, (n) => _.isEqual(MessagePriorities.low, n.priority))
);

export const getUnreadNotifications = (notifications: Array<NotificationMsgObject>) => (
  _.filter(notifications, (n) => _.isNil(n.readAt))
);

export const getNotificationsToDisplay = (notificationsArr: Array<NotificationMsgObject>, activeTab: number, skip: number) => {
  const notifications = activeTab === ActiveTab.actions ? getActionNotifications(notificationsArr) : getUpdateNotifications(notificationsArr);
  return _.slice(notifications, skip, skip + NOTIFICATIONS_ELEMENTS_PER_PAGE);
};

export const preventBubbleUp = (e: SyntheticEvent) => {
  e.stopPropagation();
};

export const getMessageAction = (actions: Array<{ [title: string]: string }>) => (
  _.find(actions, (act) => (act.title !== 'Support' && act.title !== 'Help Center'))
);

export const createSocketSubscription = (user: User) => eventChannel((emit) => {
  const notificationHandler = (event) => emit(event);
  window.io.socket.on(
    `notification/user/${user.id}`,
    (body) => notificationHandler({ action: NOTIFICATIONS_FETCH_UPDATES, payload: body }),
  );
  window.io.socket.get(
    `/notification/user/${user.id}`,
    { startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE },
    (body) => notificationHandler({ action: NOTIFICATIONS_FETCH_EXISTING, payload: body }),
  );

  const unsubscribe = () => window.io.socket.off(`notification/user/${user.id}`, notificationHandler);
  return unsubscribe;
});

export const notify = (msg) => {
  if (!('Notification' in window)) {
    return false;
  }

  if (Notification.permission === 'granted') {
    return new Notification(msg);
  }

  if (Notification.permission !== 'denied') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        return new Notification(msg);
      }
      return false;
    });
  }
  return false;
};

export const readNotification = async (id: string, userId: number, selectedCategoryFilter: SelectedCategoryFilter, read: boolean, dispatch: Dispatch<any>) => {
  try {
    const readAt = dateTimeFormatter.isoDateTime(undefined, true);
    await Notifications.updateUserNotification(
      userId,
      { id, readAt, selectedCategoryFilter, read, startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE },
    );
  } catch (exception) {
    dispatch({ type: NOTIFICATIONS_READ_FAILED, payload: exception });
  }
};

export const readAllNotifications = async (userId: number, dispatch: Dispatch) => {
  try {
    await Notifications.updateUserNotification(userId, { readAll: true });
  } catch (exception) {
    dispatch({ type: NOTIFICATIONS_READ_FAILED, payload: exception });
  }
};
