/* eslint-disable react/prop-types */
import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ObjectDropdown, WppTextareaInput, WppGrid, WppTypography, WppCheckbox, ValidatedCustomWppInput } from 'buildingBlocks';
import { useAlgoTypeFetcher } from 'utils/hooks/useAlgoTypeFetcher';
import { validatedFieldWrapper, validatedWppFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import AlgorithmTypeSelection from './AlgorithmTypeSelection';
import StartDateTime from './StartDateTime';
import EndDateOption from './EndDateOption';
import PublishMsgOptions from './PublishMsgOptions';
import { Priority, PublishOptions } from '../types';
import { PRIORITIES } from '../constants';
import { messageFormDismiss, messageFormGrid, messageFormGridText } from '../style';

const ValidatedTextArea = validatedWppFieldWrapper(WppTextareaInput);
const ValidatedDropdown = validatedFieldWrapper(ObjectDropdown);

const MessageForm = () => {
  const [allAlgoTypes, error] = useAlgoTypeFetcher(true);
  const { control } = useFormContext();
  const publishOption = useWatch({ name: 'publishOption', control });
  const hasExpirationDate = useWatch({ name: 'hasExpirationDate', control });

  return (
    <WppGrid style={messageFormGrid}>
      <AlgorithmTypeSelection
        options={allAlgoTypes as unknown as { id: number, name: string }[]}
        error={error}
      />
      <WppGrid>
        <WppTypography type="s-strong" style={messageFormGridText}>Subject</WppTypography>
        <Controller
          name="subject"
          control={control}
          render={(props) => (
            <ValidatedCustomWppInput
              type="text"
              placeholder="Enter Subject"
              value={props.field.value}
              onWppChange={props.field.onChange}
              skipIsDirtyCheck
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </WppGrid>
      <WppGrid>
        <WppTypography type="s-strong" style={messageFormGridText}>Message</WppTypography>
        <Controller
          name="content"
          control={control}
          render={(props) => (
            <ValidatedTextArea
              type="text"
              placeholder="Enter your message here..."
              value={props.field.value}
              onChange={props.field.onChange}
              skipIsDirtyCheck
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </WppGrid>
      <WppGrid>
        <WppTypography type="s-strong" style={messageFormGridText}>Priority</WppTypography>
        <Controller
          name="priority"
          control={control}
          render={(props) => (
            <ValidatedDropdown
              fluid
              selection
              placeholder="Select Priority"
              options={PRIORITIES}
              onChange={props.field.onChange}
              value={props.field.value}
              keyFn={(p: Priority) => p.name}
              {..._.omit(props, 'formState')}
            />
          )}
        />
      </WppGrid>
      <PublishMsgOptions publishOption={publishOption} />
      {_.isEqual(publishOption, PublishOptions.custom) && (<StartDateTime />)}
      <Controller
        name="isDismissible"
        control={control}
        render={(props) => (
          <WppGrid style={messageFormDismiss}>
            <WppTypography type="s-strong" style={messageFormGridText}>Dismiss Mode</WppTypography>
            <div style={messageFormDismiss}>
              <EndDateOption hasExpirationDate={hasExpirationDate} />
              <WppCheckbox
                labelConfig={{ text: 'Message is dismissible by the user' }}
                checked={props.field.value}
                required
                onWppChange={props.field.onChange}
                {..._.omit(props, 'formState')}
              />
            </div>
          </WppGrid>
        )}
      />
    </WppGrid>
  );
};

export default MessageForm;
