import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const containerLayout = {
  marginTop: SPACING[24],
};

export const taskDetailContainer = {
  padding: '13px 16px',
};

export const buttonGroupContainer = {
  display: 'flex',
  gap: SPACING[12],
};

export const modalButtonAlignment = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const modalTaskDetailStatus = {
  wordBreak: 'break-word',
};
