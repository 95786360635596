import React, { useState } from 'react';
import { BasicHeader } from 'components/SubNav';
import PermissionWrapper from 'components/PermissionWrapper';
import { WppButton, WppIconPlus } from 'buildingBlocks';
import MessageCreator from 'containers/MessageCreator';
import { messagesBtnPageHeader } from './style';

type Props = {
  title: string
  permissions: Array<string>
  onSearchChange?: (searchQuery: string) => void
};

const BtnPageHeader = ({ title, permissions, onSearchChange }: Props) => {
  const [showMessageCreator, setShowMessageCreator] = useState<boolean>(false);
  const handleNewMessageClick = () => {
    setShowMessageCreator((prev) => !prev);
  };

  return (
    <PermissionWrapper
      unauthorizedComponent={<BasicHeader header={title} />}
      permissions={permissions}
    >
      <WppButton onClick={handleNewMessageClick} style={messagesBtnPageHeader}>
        <WppIconPlus slot="icon-start" />
        New Message
      </WppButton>
      {showMessageCreator && (
        <MessageCreator
          onSearchChange={onSearchChange}
          showMessageCreator={showMessageCreator}
          setShowMessageCreator={setShowMessageCreator}
        />
      )}
    </PermissionWrapper>
  );
};

export default BtnPageHeader;
