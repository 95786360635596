import _ from 'lodash';
import React, { ReactNode, ComponentType, ComponentElement, CSSProperties } from 'react';
import { UseControllerReturn } from 'react-hook-form';
import { Label, Form, WppInlineMessage } from 'buildingBlocks';
import { CustomDatePickerProps, InputChangeEventDetail, WppInputCustomEvent } from './types';

/**
 * @param {Component} InnerComponent the component to wrap
 * @return {Component} that will display any meta.error messages after it has been touched.
 */

// OLD - built for Semantic input
export type ValidatedInputFieldProps = Omit<UseControllerReturn<any, any>, 'formState'> & {
  onChange: (e, value: { value: string }) => void
  onKeyDown?: (e, value: { value: string }) => void
  value?: string | number
  noInput?: any
  required?: boolean
  width?: number
  label?: string
  title?: string
  labelPosition?: string
  disabled?: boolean
  icon?: string | ComponentElement<any, any>
  className?: string
  type?: string
  fluid?: boolean
  style?: CSSProperties
  autoFocus?: boolean
  placeholder?: number | string
  defaultValue?: string | number
  skipIsDirtyCheck?: boolean
  selection?: boolean
  options?: Array<unknown>
  keyFn?: Function
  autoComplete?: string
} & CustomDatePickerProps;

// @ts-ignore issue with Form namespace
export type ValidatedFieldWrapper = (node: ReactNode) => (inputFields: ValidatedInputFieldProps) => Form.Field<unknown>;

export const validatedFieldWrapper: ValidatedFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ field, fieldState, skipIsDirtyCheck, ...rest }: ValidatedInputFieldProps) => {
    // If label position wasn't specified, remove it before passing it down to the input field
    let cleanInput: { label?: string } = rest;
    if (!rest.labelPosition) {
      const omitFields = rest.noInput ? ['label', 'input'] : ['label'];
      cleanInput = _.omit(rest, omitFields);
    }

    const dirty = skipIsDirtyCheck ? fieldState.error : (fieldState.isDirty && fieldState.error);
    const displayedLabel = _.get(rest, 'title', _.get(rest, 'label'));
    const value = field.value ?? '';

    return (
      // @ts-ignore
      <Form.Field required={rest.required} error={!!dirty} width={rest.width}>
        {displayedLabel && <label htmlFor={field.name}>{displayedLabel}</label>}
        <InnerComponent {...cleanInput} value={value} />
        {(dirty && fieldState.error.message)
          && <Label basic style={{ display: 'block', background: 'transparent', padding: '4px 0px 0px' }}>{fieldState.error.message}</Label>}
      </Form.Field>
    );
  }
);

export const validatedWppFieldWrapper: ValidatedFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ field, fieldState, skipIsDirtyCheck, ...rest }: ValidatedInputFieldProps) => {
    const cleanInput = _.omit(rest, ['formState', 'skipIsDirtyCheck']);
    const dirty = skipIsDirtyCheck ? fieldState.error : (fieldState.isDirty && fieldState.error);
    const value = field.value ?? '';

    return (
      <Form.Field required={rest.required} error={!!dirty}>
        <InnerComponent
          {...cleanInput}
          value={value}
          onWppChange={(event: WppInputCustomEvent<InputChangeEventDetail>) => {
            rest.onChange(event, {
              value: event.detail.value,
            });
          }}
        />
        {(dirty && fieldState.error.message)
          && (
            <WppInlineMessage
              size="s"
              message={fieldState.error.message}
              type="error"
            />
          )}
      </Form.Field>
    );
  }
);
