import React, { useState } from 'react';
import { WppButton, WppIconTrash, WppModal, WppTypography } from 'buildingBlocks';
import { Role } from 'utils/types';
import { deleteRoleConfrimStyle } from 'containers/Roles/style';
import { RouterProps } from 'utils/withRouter';

type Props = RouterProps & {
  deleteRole: Function
  role: Pick<Role, 'id' | 'name'>
  qAReadOnly: boolean
};

export const DeleteRoleConfirm: React.FC<Props> = ({ deleteRole, role: { id, name }, qAReadOnly, router }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const deleteRoleHandler = () => {
    toggleOpen();
    deleteRole(id, router);
  };

  return (
    <>
      <WppButton
        onClick={toggleOpen}
        disabled={!id || qAReadOnly}
        variant="destructive"
        style={deleteRoleConfrimStyle.deleteBtnStyle}
      >
        <WppIconTrash slot="icon-start" /> Delete
      </WppButton>
      <WppModal open={open}>
        <div slot="header">
          <WppTypography type="s-body">
            {`All users who currently have this role will lose the permissions associated with it.
            Are you sure you want to delete role "${name}" (${id})?`}
          </WppTypography>
        </div>
        <div slot="actions" style={deleteRoleConfrimStyle.deleteConfrimModelActionContainer}>
          <WppButton variant="secondary" size="s" onClick={toggleOpen}>Cancel</WppButton>
          <WppButton variant="destructive" size="s" onClick={deleteRoleHandler}>Delete</WppButton>
        </div>
      </WppModal>
    </>
  );
};

export default DeleteRoleConfirm;
