import _ from 'lodash';
import React from 'react';
import { Controller, Control, useWatch, useFormContext } from 'react-hook-form';
import { ValidatedCustomWppInput } from 'buildingBlocks';
import { getRealValue, setNumberValue } from 'buildingBlocks/ReactHookFormComponents/utils';
import { GOAL_VALUE_TYPE } from 'constantsBase';
import { usePixelLoader } from 'containers/StrategyWizard/components/PixelPicker/hooks';
import { RevenueTypeConfig, WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import ConvRevenueCalculator from 'components/ConvRevenueCalculator';
import PixelPickerWrapper from 'containers/StrategyWizard/components/PixelPicker/PixelPicker';
import { DspToPixel } from 'containers/StrategyWizard/components/PixelPicker/types';
import { InputChangeEventDetail, WppInputCustomEvent } from 'utils/types';
import { GOAL_TYPES_FOR_STRATEGY_WIZARD } from '../constants';
import { useGoalSectionContext } from '../contexts/GoalSectionProvider';
import { GOAL_SECTION_STYLES } from '../styles';
import { isAWGGoalType } from '../utils';

export const getGoalTargetProps = (goalValueType: string, currency: string, budgetConfig?: RevenueTypeConfig, label?: string, isAWGOrImpact?: boolean) => {
  if (_.size(budgetConfig) && !isAWGOrImpact) {
    return { component: ValidatedCustomWppInput, label: '%' };
  }
  switch (goalValueType) {
    case GOAL_VALUE_TYPE.PERCENTAGE:
      return { component: ValidatedCustomWppInput, label: '%' };
    case GOAL_VALUE_TYPE.CURRENCY:
      return { component: ValidatedCustomWppInput, label: currency };
    case GOAL_VALUE_TYPE.LABEL:
      return { component: ValidatedCustomWppInput, label };
    default:
      return { component: ValidatedCustomWppInput };
  }
};

type Props = {
  control: Control<WizardFormGoalSelection>
  valueType: string
  label: string
  value: string
  impValueFilters: DspToPixel
  showAwgFormula: boolean
  isImpactGoal: boolean
};

export const Configuration = ({
  control,
  valueType,
  label,
  value,
  impValueFilters,
  showAwgFormula,
  isImpactGoal,
}: Props) => {
  const { setValue, trigger } = useFormContext<WizardFormGoalSelection>();
  const {
    currencyCode, dsp,
    wizardFormValues: {
      attachFlightsStep: { attachedFlights, selectedOptType },
    },
  } = useGoalSectionContext();
  const customGoal = useWatch({ name: 'customGoal' });
  const metricsConfig = useWatch({ name: 'metricsConfig' });
  const budgetConfig = useWatch({ name: 'budget' });

  const isAWGOrImpact = isAWGGoalType(value) || isImpactGoal;
  const showAwgError = isAWGOrImpact && (!customGoal || !metricsConfig);
  const awgImpactErrorMsg = isImpactGoal ? 'Configure your goal.' : 'Please select a formula.';

  const pixelState = usePixelLoader(
    attachedFlights,
    selectedOptType,
    impValueFilters,
    setValue,
    value,
    dsp,
  );
  const goalTargetProps = getGoalTargetProps(valueType, currencyCode, budgetConfig, label, isAWGOrImpact);
  const showCalculatorIcon = _.isEqual(value, GOAL_TYPES_FOR_STRATEGY_WIZARD.conversionRevenue.value);
  const shouldValidatePercentage = valueType === GOAL_VALUE_TYPE.PERCENTAGE || (_.size(budgetConfig) && !isAWGOrImpact);

  const getStyle = () => {
    if (showAwgFormula) {
      return { ...GOAL_SECTION_STYLES.goalTarget, marginTop: '16px' };
    }
    if (showAwgError) {
      return { ...GOAL_SECTION_STYLES.goalTarget, marginTop: '12px' };
    }
    return GOAL_SECTION_STYLES.goalTarget;
  };

  return (
    <>
      {showAwgError && <span style={GOAL_SECTION_STYLES.errorText}>{awgImpactErrorMsg}</span>}
      <div style={getStyle()}>
        <Controller
          name="goal.target"
          rules={{
            required: { value: true, message: 'Required' },
            min: { value: 0, message: 'Goal target must be at least 0' },
            max: { value: (shouldValidatePercentage ? 1 : Number.MAX_VALUE), message: 'Goal target must be at most 100' },
          }}
          control={control}
          render={({ field, ...rest }) => (
            <goalTargetProps.component
              inputIcon={showCalculatorIcon && (
                <ConvRevenueCalculator
                  onChange={(val) => {
                    field.onChange(val);
                    trigger('goal.target');
                  }}
                />
              )}
              title="Goal Target (For Reporting Only)"
              type="decimal"
              size="s"
              onWppChange={(e: WppInputCustomEvent<InputChangeEventDetail>) => {
                field.onChange(setNumberValue(e.detail.value, shouldValidatePercentage));
                trigger('goal.target');
              }}
              value={(shouldValidatePercentage && _.isNumber(field.value)) ? getRealValue(field.value, 2) : field.value}
              skipIsDirtyCheck
              {..._.omit(goalTargetProps, 'component')}
              {..._.omit({ field, ...rest }, 'formState')}
            />
          )}
        />
      </div>
      <PixelPickerWrapper
        goalType={value}
        state={pixelState}
        impValueFilters={impValueFilters}
      />
    </>
  );
};
