import _ from 'lodash';
import React, { createContext, useContext, useReducer } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { DSP, GoalSuccessEvent } from 'constantsBase';
import { BudgetTypes } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import { configuringCampaignStratCheck, configuringCrossPlatformStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import { getDspByMemberOrFlights } from 'containers/StrategyWizard/utils';
import { GlobalState } from 'reducers';
import { checkPermissions, Permission } from 'utils/featureFlags';
import { User } from 'utils/types';
import { getFlightExtIdFromBudgetConfig, getParentObjectDisplayName } from '../utils';
import { updateRevenueTypeEnabled, updateRevTypeSearchStr, updateSuccessEventFilter } from './actions';
import { goalSectionReducer } from './reducers';
import { GoalSectionProviderProps } from './types';
import { INITIAL_STATE } from './constants';

const GoalSectionContext = createContext(null);

const GoalSectionProvider = ({ children }: GoalSectionProviderProps) => {
  const [state, dispatch] = useReducer(goalSectionReducer, INITIAL_STATE);
  const params = useParams<{ id: string }>();
  const strategyId = params.id ? _.toNumber(params.id) : null;
  const { wizardFormValues } = useStrategyWizardContext();
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;
  const contextAttachFlightsStep = _.get(wizardFormValues, 'attachFlightsStep');
  const contextGoalSelectionStep = _.get(wizardFormValues, 'goalSelectionStep');
  const { optimizationLevel, member, attachedFlights, selectedOptType, defaultCurrency } = contextAttachFlightsStep;

  const isCrossPlatformOptimization = configuringCrossPlatformStratCheck(selectedOptType);
  const isCampaignOptimization = configuringCampaignStratCheck(selectedOptType);
  const isEditModeRevSingleOutcomeStrat = !_.isEmpty(contextGoalSelectionStep.budget);
  const currencyCode = _.get(defaultCurrency, 'code', 'USD');
  const dsp = getDspByMemberOrFlights(attachedFlights, member);
  const firstFlightExtId = isEditModeRevSingleOutcomeStrat ? getFlightExtIdFromBudgetConfig(contextGoalSelectionStep.budget) : _.get(_.head(attachedFlights), 'externalId');
  const firstFlightsBudgetAllocationData = _.get(wizardFormValues?.budgetAllocationState, `data[${firstFlightExtId}]`);
  const budgetType = _.get(firstFlightsBudgetAllocationData, 'hierarchy.parentSettings.budgetType');
  const isImpsBudgetType = _.isEqual(budgetType, BudgetTypes.imps);
  const hasAmznFlights = _.some(attachedFlights, (f) => _.isEqual(f.dsp, DSP.AMZN.id));
  const hasRevTypePermission = checkPermissions(user, [Permission.accessRevenueTypes], _.map(attachedFlights, 'member'));
  const disableRevType = isImpsBudgetType || hasAmznFlights || !hasRevTypePermission;

  const actions = {
    setRevenueTypeEnabled: (isEnabled: boolean) => dispatch(updateRevenueTypeEnabled(isEnabled)),
    setRevTypeSearchStr: (searchStr: string) => dispatch(updateRevTypeSearchStr(searchStr)),
    setGoalSuccessEventFilter: (successEvent: GoalSuccessEvent | 'all') => dispatch(updateSuccessEventFilter(successEvent)),
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValues = {
    ...state,
    ...actions,
    wizardFormValues,
    parentObjectTypeDisplayName: getParentObjectDisplayName(attachedFlights, optimizationLevel),
    strategyId,
    isCrossPlatformOptimization,
    // in edit mode, firstFlightExtId contains prefix of externalId-
    firstFlightExtId,
    currencyCode,
    user,
    dsp,
    isImpsBudgetType,
    hasAmznFlights,
    budgetType,
    isCampaignOptimization,
    disableRevType,
    hasRevTypePermission,
  };

  return (
    <GoalSectionContext.Provider value={providerValues}>
      {children}
    </GoalSectionContext.Provider>
  );
};

export const useGoalSectionContext = () => {
  const context = useContext(GoalSectionContext);
  if (!context) {
    throw new Error('useGoalSectionContext must be used within a GoalSectionProvider');
  }
  return context;
};

export default GoalSectionProvider;
