/* eslint-disable no-restricted-imports */
import React from 'react';
import { Moment } from 'moment';
import { GOAL_VALUE_TYPE, LOADING_STATES, GoalSuccessEvent, StratTypeIconType, RevenueType, MetricsFormattingConstants } from 'constantsBase';
import { SemanticICONS } from 'buildingBlocks';
import { WppCheckboxCustomEvent } from '@wppopen/components-library/dist/types/components';
import { CheckboxChangeEvent } from '@wppopen/components-library/components';
import { BudgetIntervals, Segment } from 'containers/StrategyWizard/ConfigurationByStrategyType/Helios/type';
import { Item } from 'containers/StrategyWizard/components/SegmentPicker/SegmentPickerTypes';
import { BudgetSetting, GoalDB, RevenueTypeConfig } from 'containers/StrategyWizard/types';
import { BudgetAllocationData } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { MemberRoleMap, ApproverValidationMode } from '../containers/User/types';

export type { ColDef } from 'ag-grid-community';

export type { WppInputCustomEvent, WppPillCustomEvent, WppSelectCustomEvent, WppToggleCustomEvent, WppBreadcrumbCustomEvent, WppPaginationSelectCustomEvent, WppTextareaInputCustomEvent, WppTabsCustomEvent, WppDatepickerCustomEvent, WppInlineEditCustomEvent, WppExpandableCardCustomEvent }
  from '@wppopen/components-library/dist/types/components';

export type { InputChangeEventDetail, SelectSize, PaginationChangeEventDetail, BreadcrumbItemEventDetails, CombinedSelectControl, CardGroupChangeEventDetail,
  SelectChangeEventDetail, NavigationState, PaginationPageChangeEventDetail, TextareaInputChangeEventDetail, TabsChangeEventDetail, ToggleChangeEvent, DatePickerEventDetail, InlineEditChangeModeEventDetail, InlineEditMode, ExpandableCardSectionChangeEventDetail }
  from '@wppopen/components-library';

export type { BaseFormControlEventDetail }
  from '@wppopen/components-library/dist/types/interfaces/base-form-control-event-detail';

export type { GridReadyEvent, CellStyle } from 'ag-grid-community';

export type { AgGridReactProps } from 'ag-grid-react';

export type Currency = {
  code: string
  symbol: string
};

export type CurrencyExchange = {
  code: string
  createdAt: string
  updatedAt: string
  id: number
  ratePerUsd: number
};

/* eslint-disable camelcase */
export type Advertiser = {
  id: number
  externalId: string
  defaultCurrency: Currency
  name: string
  member: number
};

export type Brand = {
  id: number
  name: string
  advertisers: Array<Advertiser>
  updatedAt: string
};

export type AdvertiserWithPopulatedMember = {
  id: number
  externalId: string
  defaultCurrency: Currency
  name: string
  member: Member
};

export type Member = {
  id: number
  externalId: string
  displayName: string
  dsp: number | { id: number }
  name: string
  region: number
  businessModel?: number
};

export type FlightExternalType = {
  id: number
  displayName: string
  wizardAbbreviation?: string
};

export type StratTypeIcon =
  | {
    type: StratTypeIconType.fontAwesome
    name: SemanticICONS
  }
  | {
    type: StratTypeIconType.custom
    name: string
  };

export type StrategyType = {
  id: number
  displayName: string
  shortName: string
  dsp: number
  help: string
  icon: StratTypeIcon
  displayPosition: number
  canRevert: boolean
  toolTipDesc: string
  // comes from database and we sometime merge it from db
  description?: string
  schedule?: unknown
  new?: unknown
  beta?: boolean
  flightExternalTypes: Array<FlightExternalType>
};

export type Algorithm = {
  algorithmType: number
  schedule: number
  strategy: number
  createdBy: number
  updatedBy: number
  scheduled: boolean
  config: {}
  active: boolean
  revision: number
  strategyRevision: number
  changeLog: null | string
  id: number
  createdAt: string
  updatedAt: string
};

export type AlgorithmType = {
  createdAt: string
  description: string
  dsp: number
  id: number
  isIndependent: boolean
  name: string
  schedule: number
  updatedAt: string
};

export type Flight = {
  id: number
  eligible: boolean
  externalId: string
  externalType: number
  ineligibilityReasons: Array<string>
  status: string
  alreadyAttached: string
  grandParentName: string
  grandParentExternalId: string
  parentName: string
  parentExternalId: string
  name: string
  strategy: number
  startDate: Date
  endDate: Date
  member: number
  memExtId: string
  advExtId: string
  defaultCurrency?: number
  currency?: number
  dsp?: number
  advertiser?: number
  timezone?: string
};

export type FlightInfo = {
  id: string
  externalId: string
  name: string
  adTypes: Array<string>
  revenueType: RevenueType
  revenueAuctionEventType: string
  remainingDays: number
  segmentGroupTargets: Array<SegmentGroup>
  pixels: Array<Pixel>
  goalPixels: Array<Pixel>
  budgetOptData?: BudgetAllocationData
};
type AdditionalFlightFields = {
  insertionOrder: {
    name: string
    externalId: string
  }
  lineItem: {
    name: string
    externalId: string
  }
};

export type AugmentedFlight = Flight & AdditionalFlightFields;

export type FlightStatuses = {
  [key: string]: {
    id: string
    error: boolean
    errorMessage: string
  }
};

export type ApnObj = {
  externalId: string
  name: string
};

export type GoalType = {
  valueType:
  | typeof GOAL_VALUE_TYPE.LABEL
  | typeof GOAL_VALUE_TYPE.CURRENCY
  | typeof GOAL_VALUE_TYPE.PERCENTAGE
  | typeof GOAL_VALUE_TYPE.NONE
  text: string
  shortText: string
  value: string
  label?: string
  isConversionGoal?: boolean
  sliderName?: string
  filterText?: string
  sliderConfig?: {
    min: number
    max: number
    step: number
  }
  insightsLabel?: string
  isBeta: boolean
  isCustom?: boolean
  isNewAdvanced?: boolean
  strategyWizardAbbreviation?: string
  strategyWizardLongText?: string
  lowerIsBetter?: boolean
  successEvent?: GoalSuccessEvent // this is optional because lift has no success event
  id?: number
};

export type Goal = {
  type: string
  value?: number | string
  target?: number | string
  customGoal?: Partial<GoalDB>
};

export type StrategyGoal = {
  type: GoalType | string
  target: number | string
  attributes?: {
    ivrStandard?: 'video' | 'display'
    conversionPixelIds?: Array<string | number>
  }
};

export type StrategyGoalDB = {
  active: boolean
  attributes: any
  createdAt: string
  id: number
  priority: number
  strategy: number
  strategyRevision: number
  target: number
  type: string
  updatedAt: string
  goal: number | null
  customGoal?: Partial<GoalDB>
};

export type StrategyGoalsDB = Array<StrategyGoalDB>;
export type StrategyGoals = Array<StrategyGoal>;

export type StrategyConfig = {
  [algoId: number]: {
    id: number
    name?: string
  }
  strategyGoals?: StrategyGoals
  additionalAlgos?: Array<any>
  cyodEnabled?: boolean
  hasCustomRevenueType?: boolean
  intelligentChildObjects?: boolean
} & HeliosConfig &
HeliosSegmentRecencyConfig &
PredictorALIConfig &
ViewabilityConfig &
BudgetOptimizationConfig;

export type Strategy = {
  active: boolean
  advertiser: Advertiser
  brand: Brand
  changeLog: string | null
  clientTest: boolean
  config: StrategyConfig
  createdAt: string
  createdBy: number
  goals: Goal
  id: number
  member: Member
  name: string
  revision: number
  revTypeConfig: RevenueTypeConfig
  schedule: number
  scheduled: boolean
  strategyGoals: StrategyGoalsDB
  strategyType: StrategyType
  updatedAt: string
  updatedBy: number
};

export type StrategyView = {
  strategyType: StrategyType
  createdAt: string
  updatedAt: string
  updatedBy: number
  createdBy: number
  member: Member
  advertiser: Advertiser
  brand: Brand
  schedule: number | null
  numFlights: number
  name: string
  config: {}
  active: boolean
  scheduled: boolean
  revision: number
  changeLog: string | null
  goals: Goal
  id: number
  clientTest: boolean
};

export type SparklineAnalytics = {
  date: string
  impressions: number
  revenue: number
  strategyId: number
};

// this type gets created by the strategy list. The saga adds a bunch of properties to the strategy objects after they
// come back from the API, then this gets passed around to a few different components.
export type EnhancedStrategy = Strategy & {
  algorithms: Array<Algorithm>
  analytics: Array<SparklineAnalytics>
  dspId: number
  flightExternalType: number | null
  flights: { [key: string]: Flight }
  lastModifiedTime: string
  numFlights: number
};

export type Options = {
  text: string
  value: string
};

export type DataSourceType = {
  externalId: string
  name: string
};

export type LoadingStates = $Values<typeof LOADING_STATES>;

export type Feature = {
  memberExtIds: Array<string>
};

export type Role = {
  id: number
  name: string
  type: number
  description: string
};

export type Roles = {
  id: number
  role: Role
};

export type MemberRole = {
  member: Member
  roles: Array<Roles>
};

export type GroupRole = {
  id: number
  member: Member
  role: Role
  user: number
};

export type User = {
  id: number
  features: Feature
  login: {
    session: {
      data: {
        user: { id: number }
      }
    }
  }
  roles: Array<Role>
  email: string
  firstName?: string
  lastName?: string
  members?: Array<Member>
  memberRoleMap?: MemberRoleMap
  statusMsg?: string
  memberRoles?: Array<SingleMemberRole>
  memberRolesToSave?: Array<SingleMemberRole>
  memberRolesToDelete?: Array<SingleMemberRole>
  saving?: boolean
  approver?: string
  requestNotes?: string
  showEmailStatus?: boolean
  validatingApprover?: boolean
  approverValidationMode?: ApproverValidationMode
  loadingUser?: boolean
  users?: Array<{}>
  approverRecordId?: any
  sendEmailErrorMsg?: string
};

export type MemberEmailType = Array<MemberEmail>;

export type UserSettings = {
  config: {
    strategyListView: string
    strategyListElements: number
    sharedNotifications?: { [key: string]: Array<string> }
    emailSubscription: boolean
    timeZone: string
    emailFrequency: string
  }
  createdAt: string
  updatedAt: string
  id: number
  tours: { [tourName: string]: boolean }
  user: number
};

export type StrategyFlightRun = {
  advertiser: number
  algorithm: number
  algorithmRevision: number
  algorithmType: AlgorithmType
  createdAt: string
  extFlightId: string
  firstTaskStartedAt: string
  flight: number
  flightRevision: number
  hasWarnings: boolean
  id: number
  member: Member
  queuedDuration: number
  result: {}
  runDuration: number
  status: StrategyFlightRunStatusValue
  statusDetail: null | string
  strategy: number
  strategyCreatedBy: number
  strategyLastUpdatedBy: number
  strategyRevision: number
  strategyType: StrategyType
  totalDuration: number
  triggerType: number
  updatedAt: string
};

export type Permission = {
  name: string
  displayName: string
  description: string
  id: number
  createdAt: string
  updatedAt: string
  type: number
};

// NEW TYPESCRIPT TYPES:
export type GenericChangeHandler = React.ChangeEventHandler<Element>;
export type EventHandler<T> = (event: T, data: unknown) => void;

export type GenericMouseEventHandler = EventHandler<React.MouseEvent<unknown>>;
export type GenericFormEventHandler = EventHandler<React.FormEvent<unknown>>;

export type ButtonClickHandler = EventHandler<React.MouseEvent<HTMLButtonElement>>;
export type TextAreaChangeHandler = EventHandler<React.FormEvent<HTMLTextAreaElement>>;
export type CheckBoxHandler = (event: WppCheckboxCustomEvent<CheckboxChangeEvent>) => void;
export type MenuItemClickHandler = EventHandler<React.MouseEvent<HTMLAnchorElement, MouseEvent>>;

export type $Values<T extends {}> = T[keyof T];

export type Region = {
  name: string
  id: number
  createdAt: string
  updatedAt: string
};

export type BusinessUnit = {
  name: string
  id: number
  createdAt: string
  updatedAt: string
};

export type FeeOption = {
  name: string
  id: number
  createdAt: string
  updatedAt: string
};

export type FeeType = {
  name: string
  id: number
  createdAt: string
  updatedAt: string
};

export type BusinessModel = {
  name: string
  id: number
  createdAt: string
  updatedAt: string
};

export type Country = {
  name: string
  isocode: string
  id: number
  isonumber: number
  region: string
  createdAt: string
  updatedAt: string
};

// DSP from the database
export type DSPT = {
  displayName: string
  code: string
  id: number
  createdAt?: string
  updatedAt?: string
};

export type DspEnum = {
  id: number
  code: string
  displayName: string
  strategySaveConfirmText: string
};

/*
  This function is a simple way to assert that the location it is used should never be reached by
  the interpreter (such as the default case of a switch statement)
  // named assertNever in typescript docs

  More here: https://stackoverflow.com/a/39419171
*/

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

export const assertUnreachable = (_x: never) => null;

/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable no-unused-vars */

export type PricingStrategy = {
  value: string
  displayName: string
  order: number
};

// STRATEGY CONFIG TYPES

export type HeliosConfig = {
  baseBid: number
  minBid: number
  maxBid: number
  viewabilityThreshold: number
  segmentGroups: Array<SegmentGroup>
  lineItem: string
  useCustomBudget: boolean
  budgetType: string
  budgetIntervals: BudgetIntervals
  impValueFilters: Array<Pixel>
  creativeOptimization: boolean
  isBFO: boolean
};

export type HeliosSegmentRecencyConfig = {
  minBid: number
  maxBid: number
  segmentGroups: Array<SegmentGroup>
};

export type BasePredictorConfig = {
  postView: number
  postClick: number
  minBid: number
  maxBid: number
  segments: Array<number>
  featuresToCombine: Array<{ features: Array<string> }>
  featureMaps: Array<number>
  tolerance: number
  excludeFeatures: string
  impValueFilters: Array<Pixel>
};

export type PredictorALIConfig = BasePredictorConfig & {
  forcePerformance: boolean
};

export type BudgetOptimizationConfig = {
  budgetAllocation: boolean
  dailyParentBudgetInflationRatio: number
  intelligentChildObjects: true
  strategyGoals: StrategyGoals
  budgetSettings?: Array<BudgetSetting>
};

export type ViewabilityConfig = {
  viewabilityRateStandard?: string
};

// /STRATEGY CONFIG TYPES

export type StrategyFlightRunStatusValue =
  | 'SUCCESS'
  | 'FAILED'
  | 'RUNNING'
  | 'NO_DATA'
  | 'FLIGHT_EXTERNAL_NOT_FOUND'
  | 'ABORT'
  | 'CANCELLED'
  | 'PENDING';

export type Status = {
  [key: number]: {
    error: boolean
    FlightStatuses: FlightStatuses
  }
};

export type Metadata = {
  isDeleteModalOpen: boolean
  deleting: boolean
  deletionFailed: boolean
  error: Error | null
  loading: boolean
  processingData: boolean
  status: Status
  strategy: Strategy
  flights: Array<Flight>
  currency: Currency
};

export type SegmentGroup = {
  name: string
  boolOperator: {
    text: string
    value: string
  }
  segments: Array<Item | Segment>
  startingMaxBid: number
  startingMinBid: number
  uuid: string
  maxDays: number
  segmentExtIds: Array<string>
};

export type SegmentGroupsType = Array<SegmentGroup>;

// pixels directly from the apn api
export type APNPixel = {
  id: number
  name: string
  state: string
  trigger_type: string
  post_click_revenue: number
  post_view_revenue: number
};

// tracking tags directly from the ttd or walmart api
export type TTDOrWMTTrackingTag = {
  AdvertiserId: string
  TrackingTagId: string
  TrackingTagName: string
  TrackingTagType: string
  TrackingTagLocation: string | null
  Revenue: string | null
  Currency: string | null
  ContianerTagBody: string | null
  FirstPartyDataId: number
  TrackingTagCategory: 'StaticTag' | 'AppEventTrackerTag' | 'UniversalPixelTag' | 'OfflineTag'
  TrackingTagAvailability: string
  OfflineDataProviderId: string | null
  UniversalPixelName: string | null
  HitCount7Day: number
  TagRedirectUri: string | null
  HouseholdEnabled: boolean
  ImageTag: string | null
  IframeTag: string | null
};

export type PrettyOption = {
  content: JSX.Element
  displayName: string
  dsp: number | { id: number }
  externalId?: string
};

export type FlightDisplayName = {
  single?: string
  multiple: string
};

export type OptimizationLevelType = {
  name: string
  header: string
  description: string
  externalTypeIds: Array<number>
  defaultStrat: number
  permissions: Array<string>
  displayName: FlightDisplayName
};

export type AnyObject = { [key: string]: any };
export type StateModifier<T> = React.Dispatch<React.SetStateAction<T>>;

export type LineItem = {
  budgetIntervals: BudgetIntervals
  externalId: string
  id: number
  consoleRevenueType: string
  name: string
  revenueValue: number
  adTypes: Array<string>
  revenueType: RevenueType
  revenueAuctionEventType: string
  remainingDays: number
};

export type StrategyTypeEnumIdType = 3 | 4 | 5 | 8 | 9 | 10 | 11 | 13 | 7 | 14 | 15 | 16 | 17 | 18 | 19 | 20;

export type Pixel = {
  id: number | string
  name?: string
  weight?: number
};

export type DV360Pixel = {
  id: string
  externalId: string
  name: string
};

export enum FetchState {
  loading = 'loading',
  success = 'success',
  initial = 'initial',
  error = 'error',
}

export enum EnvironmentVariables {
  demo = 'demo',
  dev = 'dev',
  release = 'release',
  prod = 'prod',
}

export type SingleMemberRole = {
  id: number
  role: Role
  user: number
  member: Member
};

export type MemberEmail = {
  member: Member
  emails: Array<string>
  invalidEmails?: Set<string>
};

export type CustomDatePickerProps = {
  placeholder?: string
  minDate?: Moment
  selected?: Moment
  changeTime?: Function
  showPostOptions?: boolean
  startDate?: Moment
  endDate?: Moment
  hasMinDate?: boolean
  hasMaxDate?: boolean
  maxDate?: Moment
  onChange?: Function
  closeOnSelect?: boolean
};

export type MetricsConstant = {
  [rateOrAggregator: string]: {
    [metric: string]: {
      text: string
      value: string
      format: MetricsFormattingConstants
      func: Function
      vizFormat?: MetricsFormattingConstants
    }
  }
};

export type JsonDataType = string | ArrayBuffer;

export type SeatToSaveProps = {
  feeOption?: { id: number }
  businessModel?: BusinessModel
  permissions?: Array<Permission>
  prefix?: string | number
  accountId?: string | number
  externalId?: string
  jsonObject?: JsonDataType
};

export type StrategiesType = {
  text: string
  value: string
  id: number
};

export type SeatFormFiledsProps = {
  initialValues: {
    id: number
    dsp?: string
    externalId?: string
    prefix?: string
    name?: string
    displayName?: string
    apiUser?: string
    businessModel?: string
    feeOption?: string
    feeType?: string
    feeValue?: string
    permissions?: Array<Permission>
    country?: string
    businessUnit?: string
    region?: string
  }
  dspList: Array<DSPT>
  countries: Array<Country>
  businessModels: Array<BusinessModel>
  businessUnits: Array<BusinessUnit>
  feeOptions: Array<FeeOption>
  feeTypes: Array<FeeType>
  regions: Array<Region>
  permissions: Array<Permission>
};

export type Datum = {
  label: string
  value: string | number | React.ReactNode
};

export type Range = {
  limit: number
  start: number
  end: number
};

export type ToastConfig = {
  type: 'success' | 'error' | 'warning' | 'information'
  message: string
  index?: number
  duration?: number
  maxMessageLines?: number
  header?: string
};
