import _ from 'lodash';
import React from 'react';
import { WppInlineMessage, WppTable, WppTableBody, WppTableBodyCell, WppTableBodyRow, WppTableHeader, WppTableHeaderCell, WppTableHeaderRow, WppTypography } from 'buildingBlocks';
import DspIcon from 'components/DspIcon';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { AdvertiserMemberInfo } from 'containers/StrategyWizard/types';
import { FlightCategory } from 'containers/StrategyWizard/steps/AttachFlights/constants';
import { STRATEGY_CONFIRMATION_STYLES } from 'containers/StrategyWizard/steps/StrategyConfirmation/styles';
import { truncate } from 'utils/formattingUtils';
import { Flight } from 'utils/types';
import { getFlightDescriptionAndColor } from '../utils';

const { column } = strategyWizardStyles;

type SingleDSPObjectTableProps = {
  flights: Array<Flight>
  advertiserMemberInfo: Array<AdvertiserMemberInfo>
  flightCategory: string
  dspCount?: number
};

const FlightTableHeader = () => (
  <WppTableHeader>
    <WppTableHeaderRow key={column.value}>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableObjectNameStyle}>
        <WppTypography type="s-strong" tag="p">Object Name</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableNameStyle}>
        <WppTypography type="s-strong" tag="p">DSP</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableMemberStyle}>
        <WppTypography type="s-strong" tag="p">Member</WppTypography>
      </WppTableHeaderCell>
      <WppTableHeaderCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableHeaderAdvertiserStyle}>
        <WppTypography type="s-strong" tag="p">Advertiser</WppTypography>
      </WppTableHeaderCell>
    </WppTableHeaderRow>
  </WppTableHeader>
);

type FlightRowProps = {
  flight: Flight
  advertiser: AdvertiserMemberInfo
};

const FlightRow = ({ flight, advertiser }: FlightRowProps) => (
  <WppTableBody>
    <WppTableBodyRow style={STRATEGY_CONFIRMATION_STYLES.confirmStepTableStyle}>
      <WppTableBodyCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableObjectNameStyle}>
        {truncate(flight.name, 140)}
      </WppTableBodyCell>
      <WppTableBodyCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableNameStyle}>
        <DspIcon dspId={flight.dsp} />
      </WppTableBodyCell>
      <WppTableBodyCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableMemberStyle}>
        {advertiser.member.displayName}
      </WppTableBodyCell>
      <WppTableBodyCell style={STRATEGY_CONFIRMATION_STYLES.dspObjectTableAdvertiserStyle}>
        {advertiser.name}
      </WppTableBodyCell>
    </WppTableBodyRow>
  </WppTableBody>
);

const SingleDSPObjectTable = ({
  flights,
  advertiserMemberInfo,
  flightCategory,
  dspCount,
}: SingleDSPObjectTableProps) => {
  const {
    color: tableColor,
    desc: flightDesc,
  } = getFlightDescriptionAndColor(flights, flightCategory, dspCount);

  return (
    <div style={STRATEGY_CONFIRMATION_STYLES.dspObjectContainer}>
      <div className="confirm-wpp-table" style={{ ...STRATEGY_CONFIRMATION_STYLES.budgetTableSectionStyle, backgroundColor: tableColor }}>
        <WppTable>
          {(flightCategory === FlightCategory.attachedToThisStrategy) && (<FlightTableHeader />)}
          {_.map(flights, (flight: Flight, i: number) => {
            const advertiser = _.head(_.filter(advertiserMemberInfo, ['id', flight.advertiser]));
            return advertiser ? <FlightRow key={i + 1} flight={flight} advertiser={advertiser} /> : null;
          })}
        </WppTable>
      </div>
      <WppInlineMessage
        size="s"
        message={flightDesc}
        type="information"
      />
    </div>
  );
};

export default SingleDSPObjectTable;
