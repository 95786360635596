/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Map as ImmutableMap } from 'immutable';
import { WppActionButton, WppButton, WppGrid } from 'buildingBlocks';
import { AWG_WEIGHTS_MODAL_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { updateMetricWeights } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import { useWeightsModalContentContext } from './contexts/WeightsModalContentProvider';
import { useWeightsSectionContext } from './contexts/WeightsSectionProvider';
import { useAWGContext } from './contexts/AWGProvider';

const { footerStyles, modalFooterCancelBtn } = AWG_WEIGHTS_MODAL_STYLES;

const ModalFooter = () => {
  const { setFormulaMetrics } = useAWGContext();
  const {
    handleModalClose, sessionMetrics, setSessionMetrics,
    setToDefaultMetric, defaultOptTypeDimension, setSelectedDimension,
  } = useWeightsSectionContext();
  const { formErrors, setAttemptSave } = useWeightsModalContentContext();
  const { setValue } = useFormContext();

  const handleSave = () => {
    setAttemptSave(true);
    if (_.isEmpty(formErrors)) {
      const cleansedMetrics = sessionMetrics.map((metric) => {
        // handle edit specific metric but doesn't weight
        if (!metric.get('weighting').size) {
          return metric.set('dimension', defaultOptTypeDimension);
        }
        return metric;
      });
      const metrics = updateMetricWeights(cleansedMetrics);
      setFormulaMetrics(metrics);
      setValue('metricsConfig', metrics);
      handleModalClose();
    }
  };

  const handleReset = (_e) => {
    setAttemptSave(false);
    const defaultConfig = sessionMetrics.map((config) => config.merge({
      dimension: defaultOptTypeDimension,
      isWeighted: false,
      weighting: ImmutableMap(),
    }));
    setSessionMetrics(defaultConfig);
    setToDefaultMetric();
    setSelectedDimension(defaultOptTypeDimension);
  };

  return (
    <WppGrid style={footerStyles}>
      <div>
        <WppActionButton variant="secondary" onClick={handleReset}>Reset</WppActionButton>
      </div>
      <div>
        <WppButton variant="secondary" size="m" style={modalFooterCancelBtn} onClick={handleModalClose}>Cancel</WppButton>
        <WppButton variant="primary" size="m" onClick={handleSave}>Submit</WppButton>
      </div>
    </WppGrid>
  );
};

export default ModalFooter;
