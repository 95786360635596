import * as _ from 'lodash';
/**
 *
 * Global Constants
 * This file contains useful constants / enums that are useful across the project.
 *
 */

import createEnum from 'utils/Enum';

export const APP_NAME = 'Copilot';
export const RESULTS_LIMIT = 9999;

export const APP_DOMAIN = 'optimization.choreograph.com';

export enum AWGDimensions {
  insertionOrder = 'insertionOrder',
  dsp = 'dsp',
  lineItem = 'lineItem',
  pixel = 'pixel',
}

export const DSP = createEnum({
  APN: {
    id: 1,
    code: 'XNDR',
    displayName: 'Xandr',
    strategySaveConfirmText: 'Detached APN flights will be set to inactive',
  },
  TTD: {
    id: 2,
    code: 'TTD',
    displayName: 'The Trade Desk',
    strategySaveConfirmText: `${APP_NAME} site lists will be removed from detached TTD flights`,
  },
  DBM: {
    id: 3,
    code: 'DV360',
    displayName: 'DV360',
    strategySaveConfirmText: 'Detached DV360 flights will be set to inactive',
  },
  AMZN: {
    id: 4,
    code: 'AMZN',
    displayName: 'Amazon',
    strategySaveConfirmText: 'Detached Amazon flights will be set to inactive',
  },
  MULTIPLE: {
    id: 5,
    code: 'MULTIPLE',
    displayName: 'Demand-Side Platform',
    strategySaveConfirmText: 'Detached flights will be set to inactive',
  },
  WALMART: {
    id: 7,
    code: 'WMT',
    displayName: 'Walmart',
    strategySaveConfirmText: 'Detached Walmart flights will be set to inactive',
  },
} as const);

export const OPERATOR = {
  CONTAINS: 'contains',
  START_WITH: 'startsWith',
  END_WITH: 'endsWith',
  LESS_THAN: '<',
  LESS_OR_EQUAL: '<=',
  GREATER_THAN: '>',
  GREATER_OR_EQUAL: '>=',
  OR: 'or',
  NOT: '!',
} as const;

export const FLIGHT_EXTERNAL_TYPE = createEnum({
  apnCampaign: {
    id: 1,
    displayName: 'Campaign',
    wizardDisplayName: 'Campaign',
    wizardAbbreviation: 'CA',
  },
  apnLineItem: {
    id: 2,
    displayName: 'Augmented Line Item',
    wizardDisplayName: 'Line Item',
    wizardAbbreviation: 'LI',
  },
  apnProfile: { id: 3, displayName: 'Profile' },
  apnDomainList: { id: 4, displayName: 'Domain list' },
  apnCustomModel: { id: 5, displayName: 'Custom Model' },
  ttdBidList: { id: 8, displayName: 'Bid list' },
  ttdMegagon: {
    id: 9,
    displayName: 'Ad group',
    wizardDisplayName: 'Ad Group',
    wizardAbbreviation: 'AG',
  },
  dbmLineItem: {
    id: 10,
    displayName: 'DV360 Line Item',
    wizardDisplayName: 'Line Item',
    wizardAbbreviation: 'LI',
  },
  ttdCampaign: {
    id: 11,
    displayName: 'Campaign',
    wizardDisplayName: 'Campaign',
    wizardAbbreviation: 'CA',
  },
  dbmInsertionOrder: {
    id: 12,
    displayName: 'DV360 Insertion Order',
    wizardDisplayName: 'Insertion Order',
    wizardAbbreviation: 'IO',
  },
  apnInsertionOrder: {
    id: 13,
    displayName: 'Insertion Order',
    wizardDisplayName: 'Insertion Order',
    wizardAbbreviation: 'IO',
  },
  amznOrder: {
    id: 17,
    displayName: 'Order',
    wizardDisplayName: 'Order',
    wizardAbbreviation: 'OR',
  },
  amznLineItem: {
    id: 18,
    displayName: 'Line Item',
    wizardDisplayName: 'Line Item',
    wizardAbbreviation: 'LI',
  },
  amznCampaign: {
    id: 30,
    displayName: 'Campaign',
    wizardDisplayName: 'Campaign',
    wizardAbbreviation: 'CA',
  },
  amznAdGroup: {
    id: 29,
    displayName: 'Ad Group',
    wizardDisplayName: 'Ad Group',
    wizardAbbreviation: 'AG',
  },
  wmtBidList: {
    id: 25,
    displayName: 'Walmart Bid List',
  },
  wmtMegagon: {
    id: 26,
    displayName: 'Walmart Megagon Ad Group',
    wizardDisplayName: 'Walmart Megagon Ad Group',
    wizardAbbreviation: 'AG',
  },
  wmtCampaign: {
    id: 27,
    displayName: 'WMT Campaign',
    wizardDisplayName: 'WMT Campaign',
    wizardAbbreviation: 'CA',
  },
  wmtCampaignFlight: {
    id: 28,
    displayName: 'WMT Campaign Flight',
    wizardDisplayName: 'WMT Campaign Flight',
    wizardAbbreviation: 'CAF',
  },
} as const);

export const APN_CONSOLE_REVENUE_TYPE = createEnum({
  cpm: { id: 'cpm', displayName: 'CPM' },
  cpc: { id: 'cpc', displayName: 'CPC' },
  dcpm: { id: 'dcpm', displayName: 'Dynamic CPM' },
  vcpm: { id: 'vcpm', displayName: 'vCPM' },
  costPlus: { id: 'cost_plus', displayName: 'Cost Plus' },
  cpcv: { id: 'cpcv', displayName: 'CPCV' },
} as const);

export enum StratTypeIconType {
  fontAwesome = 'font-awesome',
  custom = 'custom',
}

export const STRATEGY_TYPE = createEnum({
  viewCalc: {
    active: false,
    id: 3,
    displayName: 'Viewability Calculator: Display',
    shortName: 'VCD',
    dsp: DSP.APN.id,
    help:
      'Filter and/or bid on inventory according to its predicted in-view rate. '
      + 'Visualize impact of viewability on clicks and conversions.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'eye' },
    displayPosition: 4,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc: 'Only display (not video) flights should be used with this Strategy type.',
    description: '',
    helpCenterLink: '',
  },
  predictorCampaign: {
    active: false,
    id: 4,
    displayName: 'Predictor',
    shortName: 'PRE',
    help: 'Use machine learning to predict probability of clicks or conversions.',
    dsp: DSP.APN.id,
    icon: { type: StratTypeIconType.fontAwesome, name: 'lab' },
    displayPosition: 5,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [],
    toolTipDesc: 'Only Campaigns (not ALIs) should be used with this Strategy type.',
    description: '',
    helpCenterLink: '',
  },
  segmentRecency: {
    active: false,
    id: 5,
    displayName: 'Segment Recency Old',
    shortName: 'SEG',
    dsp: DSP.APN.id,
    help: 'Price users according to when they joined a segment.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'street view' },
    displayPosition: 7,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [],
    toolTipDesc: '',
    description: '',
    helpCenterLink: '',
  },
  customTree: {
    active: true,
    id: 7,
    displayName: 'Custom Tree',
    shortName: 'CUS',
    dsp: DSP.APN.id,
    help: 'Enables Data Scientists to activate a custom bidding algorithm in Xandr Bonsai language.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'tree' },
    displayPosition: 9,
    canRevert: false,
    chickenBox: true,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc: '',
    description: `Create a custom model to optimize targeting and bidding on Xandr ${FLIGHT_EXTERNAL_TYPE.apnLineItem.wizardDisplayName}s.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/a/solutions/articles/47001125079',
  },
  predictorALI: {
    active: false,
    id: 8,
    displayName: 'Predictor: ALI',
    shortName: 'PRE',
    dsp: DSP.APN.id,
    help: 'Use machine learning to predict probability of clicks or conversions.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'lab' },
    displayPosition: 6,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc: 'Only ALIs (not Campaigns) should be used with this Strategy type.',
    description: '',
    helpCenterLink: '',
  },
  viewCalcVideo: {
    active: false,
    id: 9,
    displayName: 'Viewability Calculator: Video',
    shortName: 'VCV',
    dsp: DSP.APN.id,
    help:
      'Filter and/or bid on inventory according to its predicted in-view rate. '
      + 'Visualize impact of viewability on clicks and completions.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'record' },
    displayPosition: 3,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc: 'Only video (not display) flights should be used with this Strategy type.',
    description: '',
    helpCenterLink: '',
  },
  helios: {
    active: true,
    id: 10,
    displayName: 'Helios',
    shortName: 'HEL',
    dsp: DSP.APN.id,
    help: 'Adjusts bids across inventory and audience dimensions to drive goal performance.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'sun' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc:
      'Only flights of the same type can be used with this Strategy type. Select at least one flight to use this Strategy.',
    description: `Optimizes ${FLIGHT_EXTERNAL_TYPE.apnLineItem.wizardDisplayName} bidding across inventory and audience dimensions to drive goal performance.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125020-configuration-helios',
  },
  heliosSegmentRecency: {
    active: true,
    id: 11,
    displayName: 'Segment Recency',
    shortName: 'SEG',
    dsp: DSP.APN.id,
    help: 'Adjusts bids on users according to when they joined an audience segment to drive goal performance.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'street view' },
    displayPosition: 0,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnLineItem],
    toolTipDesc:
      'Only flights of the same type can be used with this Strategy type. Select at least one flight to use this Strategy.',
    description: `Optimizes ${FLIGHT_EXTERNAL_TYPE.apnLineItem.wizardDisplayName} bidding across retargeting segments to drive goal performance.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125064-configuration-segment-recency',
  },
  customBidList: {
    active: true,
    id: 13,
    displayName: 'Custom Bid List',
    shortName: 'CBL',
    dsp: DSP.TTD.id,
    help: 'Enables Data Scientists to activate a custom bidding algorithm in TTD Bonsai language.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'list' },
    displayPosition: 13,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.ttdMegagon],
    toolTipDesc: '',
    description: `Create a custom model to optimize targeting and bidding on TTD ${FLIGHT_EXTERNAL_TYPE.ttdMegagon.wizardDisplayName}s.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/a/solutions/categories/47000518900/folders/47000763846',
  },
  heliosBidList: {
    active: false,
    id: 14,
    displayName: 'Helios TTD',
    shortName: 'HEL TTD',
    dsp: DSP.TTD.id,
    help: 'Filter and/or bid on inventory according to performance, and adjust bids based on pacing.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'sun' },
    displayPosition: -2,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.ttdMegagon],
    toolTipDesc: '',
    beta: true,
    description: `Optimizes ${FLIGHT_EXTERNAL_TYPE.ttdMegagon.wizardDisplayName} bidding across inventory and audience dimensions to drive goal performance.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125020-configuration-helios',
  },
  heliosCustomScript: {
    active: false,
    id: 15,
    displayName: 'Helios DV360',
    shortName: 'HEL DV360',
    dsp: DSP.DBM.id,
    help: 'Score inventory according to performance.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'sun' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.dbmLineItem],
    toolTipDesc: 'Only Line Item type flights should be used with this Strategy type.',
    description: `Optimizes ${FLIGHT_EXTERNAL_TYPE.dbmLineItem.wizardDisplayName} bidding across inventory and audience dimensions to drive goal performance.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125020-configuration-helios',
  },
  dbmULBudgetOptimization: {
    active: false,
    id: 17,
    displayName: 'Budget Allocation',
    shortName: 'BA DV360',
    dsp: DSP.DBM.id,
    help: 'Optimize budget allocation to achieve performance and pacing goals',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder],
    toolTipDesc: 'Only an Insertion Order type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.dbmLineItem.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  apnBudgetOptimization: {
    active: true,
    id: 18,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO XNDR',
    dsp: DSP.APN.id,
    help: 'Optimize budgets, targeting and bidding to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder],
    toolTipDesc: 'Only an Insertion Order type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.apnLineItem.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  ttdBudgetOptimization: {
    active: true,
    id: 19,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO TTD',
    dsp: DSP.TTD.id,
    help: 'Optimize budgets, targeting and bidding to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.ttdCampaign],
    toolTipDesc: 'Only a Campaign type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.ttdMegagon.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  dbmBudgetOptimization: {
    active: true,
    id: 20,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO DV360',
    dsp: DSP.DBM.id,
    help: 'Optimize budgets, targeting and bidding to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder],
    toolTipDesc: 'Only an Insertion Order type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.dbmLineItem.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  dbmCustomSDF: {
    active: true,
    id: 21,
    displayName: 'Custom SDF',
    shortName: 'SDF DV360',
    dsp: DSP.DBM.id,
    help: 'Enable Data Scientists to use the Copilot API to adjust DV360 targeting, settings and bidding.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'list' },
    displayPosition: 21,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder],
    toolTipDesc: 'Only an Insertion Order type flight should be used with this Strategy type.',
    description: `Create a custom model to optimize targeting and bidding on DV360 ${FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.wizardDisplayName}s and ${FLIGHT_EXTERNAL_TYPE.dbmLineItem.displayName}s.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001181160-configuration-custom-sdf',
  },
  crossPlatformOptimization: {
    active: true,
    id: 25,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO Cross-Platform',
    dsp: DSP.MULTIPLE.id,
    help: 'Optimize cross-platform budgets to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.apnInsertionOrder, FLIGHT_EXTERNAL_TYPE.ttdCampaign, FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder, FLIGHT_EXTERNAL_TYPE.amznCampaign],
    toolTipDesc: 'Only objects associated with the brand or advertiser can be used with this Strategy type.',
    description: 'Optimize cross-platform budgets to drive goal performance within a delivery target.',
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  amznBudgetOptimization: {
    active: true,
    id: 26,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO AMZN',
    dsp: DSP.AMZN.id,
    help: 'Optimize budgets, targeting and bidding to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.amznCampaign],
    toolTipDesc: 'Only an Order type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.amznAdGroup.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
  wmtBudgetOptimization: {
    active: true,
    id: 27,
    displayName: 'Campaign Level Optimization',
    shortName: 'BO WMT',
    dsp: DSP.WALMART.id,
    help: 'Optimize budgets, targeting and bidding to drive goal performance within a delivery target.',
    icon: { type: StratTypeIconType.fontAwesome, name: 'dollar' },
    displayPosition: -1,
    canRevert: false,
    chickenBox: false,
    flightExternalTypes: [FLIGHT_EXTERNAL_TYPE.wmtCampaign],
    toolTipDesc: 'Only a Campaign type flight should be used with this Strategy type.',
    description: `Optimize ${FLIGHT_EXTERNAL_TYPE.wmtMegagon.wizardDisplayName} budgets, targeting and bidding to drive goal performance within a delivery target.`,
    helpCenterLink: 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization',
  },
} as const);

export const ALGORITHM_TYPE = createEnum({
  predictorCampaign: {
    id: STRATEGY_TYPE.predictorCampaign.id,
    name: 'predictor_bonsai',
  },
  predictorALI: {
    id: STRATEGY_TYPE.predictorALI.id,
    name: 'predictor_genie',
  },
  customTree: {
    id: STRATEGY_TYPE.customTree.id,
    name: 'custom_tree',
  },
  helios: {
    id: STRATEGY_TYPE.helios.id,
    name: 'helios_prospecting',
  },
  heliosSegmentRecency: {
    id: STRATEGY_TYPE.heliosSegmentRecency.id,
    name: 'helios_retargeting',
  },
  segmentRecency: {
    id: STRATEGY_TYPE.segmentRecency.id,
    name: 'segment_recency',
  },
  viewCalc: {
    id: STRATEGY_TYPE.viewCalc.id,
    name: 'view_calc_display',
  },
  viewCalcVideo: {
    id: STRATEGY_TYPE.viewCalcVideo.id,
    name: 'view_calc_video',
  },
  feedbackControl: {
    id: 13,
    name: 'feedback_control',
  },
  creativeOpt: {
    id: 14,
    name: 'creative_opt',
  },
  modelSelector: {
    id: 15,
    name: 'model_selector',
  },
  modelProbCalc: {
    id: 16,
    name: 'model_prob_calc',
  },
  customBidList: {
    id: 17,
    name: 'custom_bid_list',
  },
  heliosBidList: {
    id: 18,
    name: 'helios_bid_list',
  },
  customBandit: {
    id: 19,
    name: 'custom_bandit',
  },
  heliosCustomScript: {
    id: 20,
    name: 'helios_custom_script',
  },
  ttdBudgetOptimization: {
    id: 21,
    name: 'ttd_budget_optimization',
  },
  dbmBudgetOptimization: {
    id: 22,
    name: 'dbm_budget_optimization',
  },
  apnBudgetOptimization: {
    id: 23,
    name: 'apn_budget_optimization',
  },
  ttdTargetingPlus: {
    id: 24,
    name: 'ttd_targeting_plus',
  },
  dbmTargetingPlus: {
    id: 25,
    name: 'dbm_targeting_plus',
  },
  xndrTargetingPlus: {
    id: 26,
    name: 'xndr_targeting_plus',
  },
  dbmCustomSDF: {
    id: 27,
    name: 'dbm_custom_sdf',
  },
  crossPlatformOptimization: {
    id: 28,
    name: 'cross_platform_budget_optimization',
  },
  amznBudgetOptimization: {
    id: 29,
    name: 'amzn_budget_optimization',
  },
  wmtBudgetOptimization: {
    id: 30,
    name: 'wmt_budget_optimization',
  },
  wmtTargetingPlus: {
    id: 31,
    name: 'wmt_targeting_plus',
  },
} as const);

// possible values for the 'state' field in the airflow dag_run table
export const dagState = {
  success: 'success',
  failed: 'failed',
  running: 'running',
  skipped: 'skipped',
  queued: 'queued',
  unknown: 'unknown',
} as const;

export const roleType = {
  global: 0,
  member: 1,
} as const;

// TODO: the enums have widely inconsistent value notation - fix it
export const apiEnums = {
  admin: 'admin',
  advertiser: 'advertiser',
  advertiserBrand: 'advertiserBrand',
  afDagRun: 'afDagRun',
  airflow: 'airflow',
  algorithm: 'algorithm',
  algorithms: 'algorithms',
  algorithmType: 'algorithmType',
  amznCampaign: 'amzncampaign',
  amznOrderPixel: 'amznorderpixel',
  amznPixel: 'amznpixel',
  apnCampaign: 'apncampaign',
  apnInsertionOrder: 'apninsertionorder',
  apnLineItem: 'apnlineitem',
  apnLineItemFilterCache: 'lineitemfiltercache',
  apnSegment: 'apnsegment',
  apnStandardFeedAnalytics: 'apnStandardFeedAnalytics',
  appnexus: 'appnexus',
  auth: 'auth',
  brand: 'brand',
  brandFlightCandidate: 'brandFlightCandidate',
  businessModel: 'businessModel',
  businessUnit: 'businessUnit',
  browserTrackingDetail: 'browserTrackingDetail',
  chatbot: 'chatbot',
  country: 'country',
  currency: 'currency',
  defaultCurrency: 'defaultCurrency',
  currencyExchange: 'currencyExchange',
  developerApiToken: 'developerapitoken',
  dsp: 'dsp',
  dspService: 'dspservice',
  dbmLineItem: 'dbmlineitem',
  dbmInsertionOrder: 'dbminsertionorder',
  externalDataInfo: 'externalDataInfo',
  feeOption: 'feeOption',
  feeType: 'feeType',
  flight: 'flight',
  flightCandidate: 'flightCandidate',
  flights: 'flights',
  goal: 'goal',
  goalWeight: 'goalWeight',
  issues: 'issues',
  job: 'job',
  lambda: 'lambda',
  logindomain: 'logindomain',
  member: 'member',
  message: 'message',
  messageContext: 'messageContext',
  metadata: 'metadata',
  microservices: 'microservices',
  notification: 'notification',
  permission: 'permission',
  permissions: 'permissions',
  region: 'region',
  role: 'role',
  s3: 's3',
  search: 'search',
  stratCustomTreeLeafAnalytics: 'stratCustomTreeLeafAnalytics',
  stratDefaultWizardConfig: 'stratDefaultWizardConfig',
  strategy: 'strategy',
  strategyAnalytics: 'analytics',
  strategyAnalyticsCache: 'analyticsCache',
  strategyFlightRun: 'strategyFlightRun',
  strategyGoalAnalytics: 'strategyGoalAnalytics',
  strategyGoals: 'strategyGoals',
  strategyRecommendation: 'strategyrecommendation',
  strategyType: 'strategyType',
  strategyRevenueType: 'strategyRevenueType',
  stratHeliosLeafAnalytics: 'stratHeliosLeafAnalytics',
  stratHeliosLeafIdentifier: 'stratHeliosLeafIdentifier',
  stratSegRecLeafAnalytics: 'stratSegRecLeafAnalytics',
  stratSegRecLeafIdentifier: 'stratSegRecLeafIdentifier',
  viewTtdAdGroup: 'viewTtdadgroup',
  ttdCampaign: 'ttdcampaign',
  wmtCampaign: 'wmtcampaign',
  user: 'user',
  userrole: 'userrole',
  userRoleApprover: 'userroleapprover',
  userSettings: 'usersettings',
  viewApnCampaign: 'viewapncampaign',
  viewApnInsertionOrder: 'viewapninsertionorder',
  viewDbmLineItem: 'viewdbmlineitem',
  viewDbmLineItemFloodlightActivity: 'viewdbmlineitemfloodlightactivity',
  viewFlight: 'viewflight',
  viewStrategy: 'viewStrategy',
  viewStrategyFlightRun: 'viewStrategyFlightRun',
  viewUser: 'viewuser',
  starredUserStrategy: 'starredUserStrategy',
  viewStrategyFlightMember: 'viewstrategyflightmember',
  demoConfiguration: 'demoConfiguration',
} as const;

export const GOAL_VALUE_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  CURRENCY: 'CURRENCY',
  LABEL: 'LABEL',
  NONE: '',
} as const;

export enum GoalSuccessEvent {
  clicks = 'Clicks',
  conversions = 'Conversions',
  custom = 'Custom',
  videoViews = 'Video Views',
  audioListens = 'Audio Listens',
  impViews = 'Imp Views',
  impValue = 'Imp Value',
  impressions = 'Impressions',
  cost = 'Cost',
}

export const GOAL_TYPES = {
  cpa: {
    text: 'CPA',
    value: 'cpa',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPA',
    isPerformanceGoal: true,
    insightsLabel: 'Cost per Acquisition',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'CPA',
    strategyWizardLongText: 'Cost Per Acquisition',
    equation: 'revenue/conversions',
  },
  cpc: {
    text: 'CPC',
    value: 'cpc',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPC',
    isPerformanceGoal: true,
    insightsLabel: 'Cost per Click',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.clicks,
    strategyWizardAbbreviation: 'CPC',
    strategyWizardLongText: 'Cost Per Click',
    equation: 'revenue/clicks',
  },
  netCpc: {
    text: 'Net CPC',
    value: 'netCpc',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Net CPC',
    isPerformanceGoal: true,
    insightsLabel: 'Net Cost per Click',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.clicks,
    strategyWizardAbbreviation: 'Margin CPC',
    strategyWizardLongText: 'Net Cost Per Click',
    equation: 'cost/clicks',
  },
  conversionRate: {
    text: 'Conversion Rate',
    value: 'conversionRate',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'Conversion Rate',
    isPerformanceGoal: true,
    insightsLabel: 'Conversion Rate',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'CVR',
    strategyWizardLongText: 'Conversion Rate',
    equation: 'conversions/impressions',
  },
  ctr: {
    text: 'CTR',
    value: 'ctr',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'CTR',
    isPerformanceGoal: true,
    insightsLabel: 'Click Through Rate',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.clicks,
    strategyWizardAbbreviation: 'CTR',
    strategyWizardLongText: 'Click Through Rate',
    equation: 'clicks/impressions',
  },
  conversionRevenue: {
    text: 'ROAS',
    value: 'conversionRevenue',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'ROAS',
    isPerformanceGoal: true,
    insightsLabel: 'Return On Ad Spend',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'ROAS',
    strategyWizardLongText: 'Return On Ad Spend',
    equation: 'conversionRevenue/revenue',
  },
  engagementScore: {
    text: 'Engagement Score',
    value: 'engagementScore',
    valueType: GOAL_VALUE_TYPE.NONE,
    shortText: 'Engagement Score',
    isPerformanceGoal: true,
    insightsLabel: 'Engagement Score',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'Engagement Score',
    strategyWizardLongText: 'Engagement Score Per Cost',
    isCustom: true,
    equation: 'conversions/revenue',
  },
  exposureTime: {
    text: 'Exposure Time',
    value: 'exposureTime',
    valueType: GOAL_VALUE_TYPE.LABEL,
    label: 'sec',
    shortText: 'Exposure Time',
    isPerformanceGoal: true,
    insightsLabel: 'Exposure Time',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.impViews,
    strategyWizardAbbreviation: 'Exposure Time',
    strategyWizardLongText: 'Ad Exposure Time',
  },
  cpcv: {
    text: 'CPCV',
    value: 'cpcv',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPCV',
    isPerformanceGoal: true,
    insightsLabel: 'Cost per Completed View',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'CPCV',
    strategyWizardLongText: 'Cost Per Completed View',
    equation: 'revenue/videoCompletes',
  },
  netCpcv: {
    text: 'Net CPCV',
    value: 'netCpcv',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Net CPCV',
    isPerformanceGoal: true,
    insightsLabel: 'Net Cost per Completed View',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'Margin CPCV',
    strategyWizardLongText: 'Net Cost Per Completed View',
    equation: 'cost/videoCompletes',
  },
  netCpm: {
    text: 'Net CPM',
    value: 'netCpm',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Net CPM',
    isPerformanceGoal: false,
    insightsLabel: 'Net Cost per Thousand Impressions',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.impressions,
    strategyWizardAbbreviation: 'Margin CPM',
    strategyWizardLongText: 'Net Cost Per Thousand Impressions',
    equation: '(cost/impressions)*1000',
  },
  completionRate: {
    text: 'Completion Rate',
    value: 'completionRate',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'Completion Rate',
    isPerformanceGoal: true,
    insightsLabel: 'Completion Rate',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'VCR',
    strategyWizardLongText: 'Video Completion Rate',
    equation: 'trueviews/impressions',
  },
  cpcl: {
    text: 'CPCL',
    value: 'cpcl',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPCL',
    isPerformanceGoal: true,
    insightsLabel: 'Cost per Completed Listen',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.audioListens,
    strategyWizardAbbreviation: 'CPCL',
    strategyWizardLongText: 'Cost Per Completed Listen',
    equation: 'revenue/videoCompletes',
  },
  ivrImps: {
    text: 'In-View Rate (Imps)',
    value: 'ivrImps',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'In-View Rate (Imps)',
    isPerformanceGoal: false,
    insightsLabel: 'In-View Rate (Imps)',
    isBeta: false,
    successEvent: GoalSuccessEvent.impViews,
    strategyWizardAbbreviation: 'In-View Rate (Imps)',
    strategyWizardLongText: 'In View Rate (Impressions)',
    equation: 'viewableImpressions/impressions',
  },
  ivrMeasured: {
    text: 'In-View Rate',
    value: 'ivrMeasured',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'IVR (Measured)',
    isPerformanceGoal: false,
    insightsLabel: 'In-View Rate',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.impViews,
    strategyWizardAbbreviation: 'IVR',
    strategyWizardLongText: 'Viewable Impression Rate',
    equation: 'viewableImpressions/measuredImpressions',
  },
  vcpm: {
    text: 'vCPM',
    value: 'vcpm',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'VCPM',
    isPerformanceGoal: false,
    insightsLabel: 'Viewable CPM',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.impViews,
    strategyWizardAbbreviation: 'vCPM',
    strategyWizardLongText: 'Cost Per Viewable Impressions',
    equation: '(revenue/viewableImpressions)*1000',
  },
  margin: {
    text: 'Margin',
    value: 'margin',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Margin',
    isPerformanceGoal: false,
    insightsLabel: 'Net Buying Platform Cost',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.cost,
    strategyWizardAbbreviation: 'Margin',
    strategyWizardLongText: 'Net Buying Platform Cost',
    equation: '(revenue-cost)/revenue',
  },
  impValuePerCost: {
    text: 'Value / Cost',
    value: 'impValuePerCost',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Imp Value / Cost',
    isPerformanceGoal: false,
    insightsLabel: 'Value / Cost',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.impValue,
    strategyWizardAbbreviation: 'Imp Value / Cost',
    strategyWizardLongText: 'Impression Value Per Cost',
    isCustom: true,
    equation: 'impressionValue/revenue',
  },
  impValuePerImp: {
    text: 'Value / Impression',
    value: 'impValuePerImp',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'Imp Value / Impression',
    isPerformanceGoal: false,
    insightsLabel: 'Value / Impression',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.impValue,
    strategyWizardAbbreviation: 'Imp Value / Imp',
    strategyWizardLongText: 'Impression Value Rate',
    isCustom: true,
    equation: 'impressionValue/impressions',
  },
  cpm: {
    text: 'CPM',
    value: 'cpm',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPM',
    isPerformanceGoal: false,
    insightsLabel: 'CPM',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.impressions,
    strategyWizardAbbreviation: 'CPM',
    strategyWizardLongText: 'Cost Per Thousand Impressions',
    equation: '(revenue/impressions)*1000',
  },
  vcpcv: {
    text: 'vCPCV',
    value: 'vcpcv',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'vCPCV',
    isPerformanceGoal: false,
    insightsLabel: 'Viewable Cost per Completed View',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'vCPCV',
    strategyWizardLongText: 'Viewable Cost Per Completed View',
    equation: 'revenue/videoViewableCompletes',
  },
  cpvYoutube: {
    text: 'CPV (YouTube)',
    value: 'cpvYoutube',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Cost Per YouTube View',
    isPerformanceGoal: false,
    insightsLabel: 'Cost Per YouTube View',
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'CPV (YouTube)',
    strategyWizardLongText: 'Cost Per YouTube View',
    equation: 'revenue/trueviews',
  },
  vcrYoutube: {
    text: 'VCR (YouTube)',
    value: 'vcrYoutube',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'YouTube Completion Rate',
    isPerformanceGoal: true,
    insightsLabel: 'YouTube Completion Rate',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.videoViews,
    strategyWizardAbbreviation: 'VCR (YouTube)',
    strategyWizardLongText: 'YouTube Completion Rate',
    equation: 'trueviews/impressions',
  },
  costPerImpValue: {
    text: 'Cost / Imp Value',
    value: 'costPerImpValue',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Cost / Imp',
    isPerformanceGoal: false,
    insightsLabel: 'Cost / Imp',
    isBeta: true, // not currently supported for any strategy types
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.impValue,
    strategyWizardAbbreviation: 'Cost / Imp Value',
    strategyWizardLongText: 'Cost Per Impression Value',
    isCustom: true,
    equation: 'revenue/impressionValue',
  },
  cyodCostPerSuccessEvent: {
    text: 'Minimize Cost Per Success Event',
    value: 'cyodCostPerSuccessEvent',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'Revenue / Success Events',
    insightsLabel: 'Value / Cost',
    isPerformanceGoal: false,
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.custom,
    strategyWizardAbbreviation: 'Upload External Measurement',
    strategyWizardLongText: 'Events Measured by Third Party',
    isCustom: true,
    isNewAdvanced: true,
    equation: 'revenue/externalCustomValue',
  },
  cyodRateOfSuccessEvent: {
    text: 'Maximize Rate of Success Event',
    value: 'cyodRateOfSuccessEvent',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'Success Events / Impressions',
    insightsLabel: 'Value / Impression',
    isPerformanceGoal: false,
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.custom,
    strategyWizardAbbreviation: 'Upload External Measurement',
    strategyWizardLongText: 'Events Measured by Third Party',
    isCustom: true,
    isNewAdvanced: true,
    equation: 'externalCustomValue/impressions',
  },
  awgCreateYourOwn: {
    text: 'Create Your Own Goal',
    value: 'awgCreateYourOwn',
    valueType: GOAL_VALUE_TYPE.NONE,
    shortText: 'Custom Goal',
    insightsLabel: 'Custom Goal',
    isPerformanceGoal: true,
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.custom,
    strategyWizardAbbreviation: 'Create Any Goal',
    strategyWizardLongText: 'Create your own goal formula',
    isCustom: true,
    isNewAdvanced: true,
  },
  impactOutcome: {
    text: 'Cost Per Impact',
    value: 'impactOutcome',
    valueType: GOAL_VALUE_TYPE.NONE,
    shortText: 'Cost Per Impact',
    insightsLabel: 'Cost Per Impact',
    isPerformanceGoal: true,
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.impressions,
    strategyWizardAbbreviation: 'Cost Per Impact',
    strategyWizardLongText: 'Weight Impressions by Insertion Order',
    isCustom: true,
    isNewAdvanced: true,
    equation: '(revenue/impressions)*1000',
  },
  ncsNbCpa: {
    text: 'CPA (NCS New Buyer)',
    value: 'ncsNbCpa',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'CPA (NCS New Buyer)',
    insightsLabel: 'Cost Per Acquisition (NCS New Buyer)',
    isPerformanceGoal: true,
    isBeta: false,
    lowerIsBetter: true,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'CPA (NCS New Buyer)',
    strategyWizardLongText: 'Cost Per Acquisition (NCS New Buyer)',
  },
  ncsNbCvr: {
    text: 'Conversion Rate (NCS New Buyer)',
    value: 'ncsNbCvr',
    valueType: GOAL_VALUE_TYPE.PERCENTAGE,
    shortText: 'Conversion Rate (NCS New Buyer)',
    isPerformanceGoal: true,
    insightsLabel: 'Conversion Rate (NCS New Buyer)',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'CVR (NCS New Buyer)',
    strategyWizardLongText: 'Conversion Rate (NCS New Buyer)',
  },
  ncsNbRoas: {
    text: 'ROAS (NCS New Buyer)',
    value: 'ncsNbRoas',
    valueType: GOAL_VALUE_TYPE.CURRENCY,
    shortText: 'ROAS (NCS New Buyer)',
    isPerformanceGoal: true,
    insightsLabel: 'Return On Ad Spend',
    isBeta: false,
    lowerIsBetter: false,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'ROAS (NCS New Buyer)',
    strategyWizardLongText: 'Return On Ad Spend (NCS New Buyer)',
  },
  ncsNbEngagementScore: {
    text: 'Engagement Score (NCS New Buyer)',
    value: 'ncsNbEngagementScore',
    valueType: GOAL_VALUE_TYPE.NONE,
    shortText: 'Engagement Score (NCS New Buyer)',
    isPerformanceGoal: true,
    insightsLabel: 'Engagement Score (NCS New Buyer)',
    isBeta: false,
    lowerIsBetter: false,
    isCustom: true,
    successEvent: GoalSuccessEvent.conversions,
    strategyWizardAbbreviation: 'Engagement Score (NCS New Buyer)',
    strategyWizardLongText: 'Engagement Score Per Cost (NCS New Buyer)',
  },
} as const;

export const CYOD_GOAL_TYPES = [GOAL_TYPES.cyodCostPerSuccessEvent.value, GOAL_TYPES.cyodRateOfSuccessEvent.value];
export const CYOD_GOAL_TYPE_OBJS = [GOAL_TYPES.cyodCostPerSuccessEvent, GOAL_TYPES.cyodRateOfSuccessEvent];

export const CROSS_PLATFORM_ROAS_GOAL_NAME = 'roas';

export enum FeeOptions {
  fee = 1,
  noFee = 2,
}

export enum FeeTypes {
  cpm = 1,
  percentOfMediaCost = 2,
}

export enum StrategySource {
  webApp = 1,
  satellite = 2,
  marketplace = 3,
}

export const ADMIN_ROLE_ID = 100;
export const QA_GLOBAL_ROLE_ID = 99;

export const FLIGHT_DETACHMENT_TASK_ID = 'flight_detachment';

export const VIEWABILITY_RATE_STANDARD = {
  groupM: { id: 'groupM', label: 'GroupM', value: 'groupM' },
  iab: { id: 'iab', label: 'IAB', value: 'iab' },
} as const;

export const EXCLUDE_STRATEGY_TYPES = _.map(
  // @ts-ignore ts doesn't like our hand rolled enum class... active isn't on the function keys
  _.filter(STRATEGY_TYPE, (x, key) => !key.startsWith('get') && !x.active),
  'id',
);

export const VIEWABILITY_RATE_STANDARD_OPTIONS = _.values(VIEWABILITY_RATE_STANDARD);
export const DISPLAY_VIEW_CALC_STRATEGY_ID = STRATEGY_TYPE.viewCalc.id;
export const VIDEO_VIEW_CALC_STRATEGY_ID = STRATEGY_TYPE.viewCalcVideo.id;
export const PAGE_CHANGE_ACTION = '@PAGE_CHANGE';

export const BOOLEAN_OPERATORS = {
  AND: { text: 'AND', value: 'AND' },
  OR: { text: 'OR', value: 'OR' },
} as const;

export const LOADING_STATES = {
  loading: 'loading',
  notLoading: 'notLoading',
} as const;

export const HTTP_STATUS = {
  VALID: 200,
  ERROR: 500,
} as const;

export enum MetricsFormattingConstants {
  PERCENTAGE_NO_DECIMALS = '0%',
  PERCENTAGE_NO_DECIMALS_WITH_SIGN = '+0%',
  PERCENTAGE_TWO_DECIMALS = '0.00%',
  PERCENTAGE_THREE_DECIMALS = '0.000%',
  NO_DECIMALS = '0,0',
  ONE_DECIMAL = '0,0.0',
  TWO_DECIMALS = '0,0.00',
  THREE_DECIMALS = '0,0.000',
  FOUR_DECIMALS = '0,0.0000',
  ROUNDED_NO_DECIMALS = '0,0a',
  ROUNDED_ONE_DECIMAL = '0,0.0a',
  ROUNDED_TWO_DECIMALS = '0,0.00a',
  ROUNDED_THREE_DECIMALS = '0,0.000a',
  ROUNDED_FOUR_DECIMALS = '0,0.0000a',
}

export const GENERIC_ERROR_MSG = 'An unexpected error occurred, please try again. If it occurs again, reach out to us via copilot support.';

export enum ToolTipIdentifiers {
  strategyGoals = 'strategy-goals',
  customTreeStrategyGoals = 'custom-tree_strategy-goals',
  customTreeStrategyType = 'custom-tree_strategy-type',
  customTreeTreeText = 'custom-tree_tree-text',
  customTreeTreeFile = 'custom-tree_tree-file',
  customTreeCreateLeafPerformanceReport = 'custom-tree_create-leaf-performance-report',
  budgetOptStrategyGoals = 'budget-optimization_strategy-goals',
  budgetOptTTDBudget = 'budget-optimization_ttd-budget',
  budgetOptDV360Budget = 'budget-optimization_dv360-budget',
  budgetOptXandrBudget = 'budget-optimization_xandr-budget',
  heliosStrategyGoals = 'helios_strategy-goals',
  heliosBidding = 'helios_bidding',
  heliosEngScoreConfig = 'helios_engagement-score-configuration',
  heliosSegmentGroupConfig = 'helios_segment-group-configuration',
  heliosCustomBudget = 'helios_helios-custom-budget',
  heliosCreativeOpt = 'helios_creative-optimization',
  heliosBidFrequencyOpt = 'helios_bid-frequency-optimization',
  heliosScheduled = 'helios_scheduled',
  predictorStrategyGoals = 'predictor_strategy-goals',
  predictorBidding = 'predictor_bidding',
  predictorFeatureCombinations = 'predictor_feature-combinations',
  predictorSegmentsToEvaluate = 'predictor_segments-to-evaluate',
  predictorFeatureMapping = 'predictor_feature-mapping',
  predictorRemoveRetargetingBias = 'predictor_remove-retargeting-bias',
  predictorCustomConfig = 'predictor_custom-configuration',
  predictorApnGoalPrioritySettings = 'predictor_apn-goal-priority-settings',
  predictorScheduled = 'predictor_scheduled',
  segmentRecencyStrategyGoals = 'segment-recency_strategy-goals',
  segmentRecencyBidding = 'segment-recency_bidding',
  segmentRecencySegmentGroupConfig = 'segment-recency_segment-group-configuration',
  segmentRecencyInitialBidSettings = 'segment-recency_Initial Bid Settings',
  segmentRecencyScheduled = 'segment-recency_scheduled',
  viewCalcStrategyGoals = 'viewability-calculator_strategy-goals',
  viewCalcDataSource = 'viewability-calculator_data-source',
  viewCalcCalculatorThreshold = 'viewability-calculator_threshold',
  viewCalcBidding = 'viewability-calculator_bidding',
  customBidListStrategyGoals = 'custom-bid-list_strategy-goals',
  customBidListBidListJson = 'custom-bid-list_bid-list-json',
  customBidListBidListFile = 'custom-bid-list_bid-list-file',
}

export const TOOLTIPS = {
  [ToolTipIdentifiers.strategyGoals]: {
    content: 'Generic tip about strategy goals.',
  },
  [ToolTipIdentifiers.customTreeStrategyGoals]: {
    content: 'Specify goals to get relevant metrics on the Strategy Analytics page.',
    link: '47001125079-introduction-to-custom-tree',
  },
  [ToolTipIdentifiers.customTreeStrategyType]: {
    content: "Specify whether this strategy will generate bids explicitly or modify Xandr 'Optimize to...' bids.",
    link: '47001125079-introduction-to-custom-tree',
  },
  [ToolTipIdentifiers.customTreeTreeText]: {
    content: 'Code your own strategy in Bonsai.  Read more for full language spec and best-practices.',
    link: '47001125094-configuration-custom-tree',
  },
  [ToolTipIdentifiers.customTreeTreeFile]: {
    content: 'Upload a CSV file specifying the desired feature and bid combinations.',
    link: '47001125094-configuration-custom-tree',
  },
  [ToolTipIdentifiers.customTreeCreateLeafPerformanceReport]: {
    content: 'Select the flight and date range.',
    link: '47001125111-analytics-custom-tree',
  },
  [ToolTipIdentifiers.budgetOptStrategyGoals]: {
    content: 'Goals configure the strategy and determine metrics displayed on the Strategy Analytics page',
    link: '47001125069-configuration-budget-optimization',
  },
  [ToolTipIdentifiers.budgetOptTTDBudget]: {
    content: 'Copilot will automatically adjust daily budgets for ad groups within this campaign.',
    link: '47001125069-configuration-budget-optimization',
  },
  [ToolTipIdentifiers.budgetOptDV360Budget]: {
    content: 'Copilot will automatically adjust daily budgets for line items within this insertion order.',
    link: '47001125069-configuration-budget-optimization',
  },
  [ToolTipIdentifiers.budgetOptXandrBudget]: {
    content: 'Copilot will automatically adjust daily budgets for line items within this insertion order.',
    link: '47001125069-configuration-budget-optimization',
  },
  [ToolTipIdentifiers.heliosStrategyGoals]: {
    content: 'Goals configure the strategy and determine metrics displayed on the Strategy Analytics page',
    link: '47001112138-introduction-to-helios',
  },
  [ToolTipIdentifiers.heliosBidding]: {
    content: 'Configure the starting bid and bidding limits',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosEngScoreConfig]: {
    content: 'For the Engagement Score goal type, refer to the wiki for a template.',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosSegmentGroupConfig]: {
    content: `Select segments and organize them into groups to create the strategy's targeting criteria.
              Select your most important segment group first, as those bids will be prioritized.`,
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosCustomBudget]: {
    content: 'Input the goal number of viewable impressions for each future budget interval.',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosCreativeOpt]: {
    content: 'Enables creative optimization based on CPC.',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosBidFrequencyOpt]: {
    content: 'Enables bid frequency optimization.',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.heliosScheduled]: {
    content: 'Helios models will always automatically update.',
    link: '47001125020-configuration-helios',
  },
  [ToolTipIdentifiers.predictorStrategyGoals]: {
    content: 'Goals configure the strategy and determine metrics displayed on the Strategy Analytics page.',
    link: '47001125030-introduction-to-predictor',
  },
  [ToolTipIdentifiers.predictorBidding]: {
    content: `Min and Max set limits on Predictor's bidding. If buying inventory that has a floor price,
              set the min bid to 1.5x the floor price to avoid delivery issues.`,
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorFeatureCombinations]: {
    content: `Feature combinations tell Predictor that selected features are correlated,
              and learning from both features are potentially more predictive than learning from them separately.`,
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorSegmentsToEvaluate]: {
    content: `Select segments that you would like Predictor to include in the model.
              These can be any segments at the ALI or IO level.`,
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorFeatureMapping]: {
    content: `Feature maps tell Predictor that selected feature values are correlated, and learning from the groups of
              feature values are potentially more predictive than learning from them separately.`,
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorRemoveRetargetingBias]: {
    content: `Select retargeting segments that are **not** targeted by any of the flights (campaigns) on this Strategy,
              but have been targeted by other campaigns on the same IO.`,
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorCustomConfig]: {
    content: 'Select the type of ROAS optimization. If selecting Engagement Score, refer to the wiki for a template.',
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorApnGoalPrioritySettings]: {
    content: "Controls updates to the 'Goal Priority' setting on your flights.",
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.predictorScheduled]: {
    content: 'Predictor models will always automatically update.',
    link: '47001125031-configuration-predictor',
  },
  [ToolTipIdentifiers.segmentRecencyStrategyGoals]: {
    content: 'Goals configure the strategy and determine metrics displayed on the Strategy Analytics page.',
    link: '47001125062-introduction-to-segment-recency',
  },
  [ToolTipIdentifiers.segmentRecencyBidding]: {
    content: "Min and Max set limits on Segment Recency's bidding",
    link: '47001125064-configuration-segment-recency',
  },
  [ToolTipIdentifiers.segmentRecencySegmentGroupConfig]: {
    content: `Select segments and organize them into groups to create the strategy's targeting criteria.
              Select your most important segment group first, as those bids will be prioritized.`,
    link: '47001125064-configuration-segment-recency',
  },
  [ToolTipIdentifiers.segmentRecencyInitialBidSettings]: {
    content: `This graph is illustrates how the bids and attribution window are utilized to generate the initial bid
              curve. Copilot will adjust bids during the optimization process and this bid curve will change.`,
    link: '47001125064-configuration-segment-recency',
  },
  [ToolTipIdentifiers.segmentRecencyScheduled]: {
    content: 'Segment Recency models will always automatically update.',
    link: '47001125064-configuration-segment-recency',
  },
  [ToolTipIdentifiers.viewCalcStrategyGoals]: {
    content: 'Goals configure the strategy and control displayed metrics on the Strategy Analytics page.',
    link: '47001125112-introduction-to-viewability-calculator',
  },
  [ToolTipIdentifiers.viewCalcDataSource]: {
    content: 'Choose the data source used to preview optimization impact.',
    link: '47001125114-configuration-viewability-calculator',
  },
  [ToolTipIdentifiers.viewCalcCalculatorThreshold]: {
    content: 'You will not bid on impressions with a predicted in-view rate below this value.',
    link: '47001125114-configuration-viewability-calculator',
  },
  [ToolTipIdentifiers.viewCalcBidding]: {
    content: 'Choose the best bidding strategy for your goals and overall optimization approach.',
    link: '47001125114-configuration-viewability-calculator',
  },
  [ToolTipIdentifiers.customBidListStrategyGoals]: {
    content: 'Specify goals to get relevant metrics on the Strategy Analytics page.',
    link: '47001125074-introduction-to-custom-bid-list',
  },
  [ToolTipIdentifiers.customBidListBidListJson]: {
    content: 'View the Bid List JSON before validation.',
    link: '47001125075-configuration-custom-bid-list',
  },
  [ToolTipIdentifiers.customBidListBidListFile]: {
    content: 'Upload a .csv file specifying the desired Feature and bid combinations.',
    link: '47001125075-configuration-custom-bid-list',
  },
};

export const FLEX_CENTER = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const PUBLIC_S3_BUCKET_URL = 'https://copilot-public-content.s3.amazonaws.com';

export const BASE_URL_APN = 'https://invest.xandr.com' as const;
export const XANDR_MONETIZE_BASE_URL = 'https://monetize.xandr.com' as const;

export const FONT_FAMILY = "Gilroy,Gotham,'Helvetica Neue',Arial,Helvetica,sans-serif";

export enum RevenueType {
  cpc = 'cpc',
  cpcv = 'cpcv',
  cpm = 'cpm',
  margin = 'margin',
}

export const GOAL_TYPES_TO_STRATEGY_TYPES = {
  [GOAL_TYPES.ctr.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.cpc.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.cpa.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.cpcv.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.conversionRate.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.completionRate.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
  ],
  [GOAL_TYPES.cpm.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.vcpcv.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.vcpm.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.engagementScore.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
  ],
  [GOAL_TYPES.conversionRevenue.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.helios.id,
    STRATEGY_TYPE.heliosSegmentRecency.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
  ],
  [GOAL_TYPES.cpcl.value]: [
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
  ],
  [GOAL_TYPES.ivrMeasured.value]: [
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
    STRATEGY_TYPE.helios.id,
  ],
  [GOAL_TYPES.impValuePerCost.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.impValuePerImp.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.cyodCostPerSuccessEvent.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
  ],
  [GOAL_TYPES.cyodRateOfSuccessEvent.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
  ],
  [GOAL_TYPES.cpvYoutube.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.vcrYoutube.value]: [
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.netCpc.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.netCpcv.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.netCpm.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.margin.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
    STRATEGY_TYPE.dbmCustomSDF.id,
    STRATEGY_TYPE.customBidList.id,
    STRATEGY_TYPE.customTree.id,
  ],
  [GOAL_TYPES.awgCreateYourOwn.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.amznBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
  ],
  [GOAL_TYPES.impactOutcome.value]: [
    STRATEGY_TYPE.apnBudgetOptimization.id,
    STRATEGY_TYPE.ttdBudgetOptimization.id,
    STRATEGY_TYPE.wmtBudgetOptimization.id,
    STRATEGY_TYPE.dbmBudgetOptimization.id,
    STRATEGY_TYPE.crossPlatformOptimization.id,
  ],
  [GOAL_TYPES.ncsNbCpa.value]: [
    STRATEGY_TYPE.ttdBudgetOptimization.id,
  ],
  [GOAL_TYPES.ncsNbCvr.value]: [
    STRATEGY_TYPE.ttdBudgetOptimization.id,
  ],
  [GOAL_TYPES.ncsNbEngagementScore.value]: [
    STRATEGY_TYPE.ttdBudgetOptimization.id,
  ],
  [GOAL_TYPES.ncsNbRoas.value]: [
    STRATEGY_TYPE.ttdBudgetOptimization.id,
  ],
};

export const TRUNCATION_LIMIT = 60;

export enum FlightTypeLowerCase {
  insertionOrder = 'insertion order',
  campaign = 'campaign',
  order = 'order',
  lineItem = 'line item',
}

export const SELECT_ALL_OPTION = { name: 'Select All' };

export enum MessagePriorities {
  low = 2,
  medium = 4,
  high = 6,
}

export enum BusinessModels {
  nonDisclosed = 1,
  disclosed = 2,
  unbundled = 3,
}

export const IMG_ICONS_16_BASE_PATH = '/img/icons/16x16';

export const ACCESS_REVENUE_TYPE_PERMISSION = 'Access Revenue Types';

export const COPILOT_FOR_GROUPM = 'Copilot for GroupM';

export const goalsWithLongTextAsName: Array<any> = [GOAL_TYPES.impValuePerCost.value, GOAL_TYPES.impValuePerImp.value, GOAL_TYPES.costPerImpValue.value];

export const EDIT_STRATEGY = 'Edit Strategy';
export const VIEW_ANALYTICS = 'View Analytics';
export const DELETE_STRATEGY = 'Delete Strategy';
export const DOWNLOAD_REPORT = 'Download Report';
export const STRATEGY_FLIGHT_RUNS = 'Strategy Flight Runs';
export const MARK_AS_TEST = 'Mark as Test';
export const UNMARK_AS_TEST = 'Unmark as Test';

export const XANDR_DISPLAY_NAME = 'Xandr';

export enum AmznConversionEvents {
  addToCart = 'addToCart',
  addToList = 'addToList',
  addToShoppingCart = 'addToShoppingCart',
  addToWatchlist = 'addToWatchlist',
  checkout = 'checkout',
  combinedProductSales = 'combinedProductSales',
  combinedPurchases = 'combinedPurchases',
  combinedUnitsSold = 'combinedUnitsSold',
  contact = 'contact',
  freeTrialSubscriptionSignups = 'freeTrialSubscriptionSignups',
  lead = 'lead',
  mobileAppFirstStarts = 'mobileAppFirstStarts',
  newSubscribeAndSave = 'newSubscribeAndSave',
  newToBrandProductSales = 'newToBrandProductSales',
  offAmazonConversions = 'offAmazonConversions',
  offAmazonProductSales = 'offAmazonProductSales',
  offAmazonPurchases = 'offAmazonPurchases',
  offAmazonUnitsSold = 'offAmazonUnitsSold',
  other = 'other',
  paidSubscriptionSignups = 'paidSubscriptionSignups',
  search = 'search',
  signUp = 'signUp',
  subscribe = 'subscribe',
  subscriptionSignups = 'subscriptionSignups',
  totalAddToCart = 'totalAddToCart',
  totalDetailPageView = 'totalDetailPageView',
  totalNewToBrandDPVs = 'totalNewToBrandDPVs',
  totalNewToBrandPurchases = 'totalNewToBrandPurchases',
  totalNewToBrandSales = 'totalNewToBrandSales',
  totalNewToBrandUnitsSold = 'totalNewToBrandUnitsSold',
  totalPurchases = 'totalPurchases',
  totalSubscribeAndSave = 'totalSubscribeAndSave',
  totalUnitsSold = 'totalUnitsSold',
}
