import React from 'react';
import { WppTypography, WppActionButton, WppIconClose, WppSpinner } from 'buildingBlocks';
import ChatInput from './ChatInput';
import ChatMessages from './ChatMessages';
import CHATBOT_STYLES from '../styles';
import useChatbot from '../useChatbot';

const { chatbotWindow, header, loadingOverlay, betaLabel } = CHATBOT_STYLES;

const BetaLabel = () => (
  <WppTypography type="xs-body" style={betaLabel}>
    In Beta testing, expect potential inaccuracies.
  </WppTypography>
);

type ChatbotWindowProps = {
  chatOpen: boolean
  setChatOpen: (x: boolean) => void
};

const ChatbotWindow = (props: ChatbotWindowProps) => {
  const { chatOpen, setChatOpen } = props;
  const {
    input,
    handleInputChange,
    handleSubmit,
    messages,
    loading,
    error,
  } = useChatbot();

  return (
    <div style={{ ...chatbotWindow, display: chatOpen ? 'block' : 'none' }}>
      {loading && (
        <div style={loadingOverlay}>
          <WppSpinner size="m" />
        </div>
      )}
      <div style={header}>
        <WppTypography type="l-strong">
          Chatbot
        </WppTypography>
        <WppActionButton
          variant="secondary"
          onClick={() => setChatOpen(false)}
        >
          <WppIconClose />
        </WppActionButton>
      </div>
      <ChatMessages
        messages={messages}
        error={error}
        handleSubmit={handleSubmit}
      />
      <ChatInput
        input={input}
        handleInputChange={handleInputChange}
        loading={loading}
        error={error}
        handleSubmit={handleSubmit}
      />
      <BetaLabel />
    </div>
  );
};

export default ChatbotWindow;
