import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { dateTimeFormatter } from 'utils/dateTime';
import {
  WppGrid,
  WppTypography,
  WppTag,
  WppButton,
  WppIconChevron,
  WppBreadcrumb,
  WppModal,
} from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { jobStatusVariant, jobStatusText, jobStatusColor } from 'containers/Jobs/utils';
import { Permission } from 'utils/featureFlags';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { getPreviousPageLink } from 'containers/Dashboard/utils';
import DatumRow from 'components/DatumRow';
import TaskCard from 'components/TaskCard/index';
import { RootState } from 'reducers';
import { TASK_KEYS } from './constants';
import { fetchJob } from './actions';
import {
  modalButtonAlignment,
  detailsButtonMargin,
  breadCrumContainer,
  breadCrumBackLink,
  headerTagStyle,
  headerStyle,
} from './style';

const REQUIRED_PERMISSIONS = [Permission.manageJobs];

const JobDetail: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>(); // Get jobId from route params
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { job, unauthorized } = useSelector((state: RootState) => state.jobDetail);
  const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (jobId) {
      dispatch(fetchJob(jobId));
    }
  }, [dispatch, jobId]);

  const handleDetailsModalOpen = () => {
    setDetailsModalOpen(true);
  };

  const handleDetailsModalClose = () => {
    setDetailsModalOpen(false);
  };

  const data = [
    {
      label: 'Status',
      value: (
        <WppTag
          data-testid="jobColor"
          className={jobStatusVariant(job)}
          label={jobStatusText(job)}
          variant={jobStatusVariant(job)}
          categoricalColorIndex={jobStatusColor(job)}
        />
      ),
    },
    {
      label: 'Created',
      value: <WppTypography tag="p" type="s-body">{dateTimeFormatter.dateTime(job.createdAt, true)}</WppTypography>,
    },
    {
      label: 'Updated',
      value: <WppTypography tag="p" type="s-body">{dateTimeFormatter.dateTime(job.updatedAt, true)}</WppTypography>,
    },
    {
      label: 'UUID',
      value: <WppTypography tag="p" type="s-body">{job.uuid}</WppTypography>,
    },
    {
      label: 'Member',
      value: <WppTypography tag="p" type="s-body">{_.get(job, 'member.displayName')}</WppTypography>,
    },
  ];

  const handleRouteChange = () => {
    navigate(getPreviousPageLink(location, '/jobs'));
  };

  return (
    <WppPermissionPageTemplate
      name={`Job detail - ${jobId}`}
      permissions={REQUIRED_PERMISSIONS}
      unauthorized={unauthorized}
      unauthorizedProps={{
        message: `You are not authorized to see job ${jobId}.`,
        button: {
          url: '/jobs',
          content: 'Go back to jobs listing page',
        },
      }}
      withoutLayout
      customHeader={(
        <div>
          <div style={breadCrumContainer}>
            <WppIconChevron direction="left" size="s" />
            <WppBreadcrumb
              data-testid="breadCrumb"
              items={[{
                label: 'Back to Jobs',
                path: '/jobs',
              }]}
              onWppChange={handleRouteChange}
              style={breadCrumBackLink}
            />
          </div>
          <div style={headerStyle}>
            <WppTypography tag="p" type="3xl-heading">{jobId}</WppTypography>
            <WppTag label={job.type} variant="neutral" style={headerTagStyle} />
          </div>
        </div>
      )}
    >
      <WppGrid container fullWidth>
        <WppGrid item all={24} style={wppBodyContainer}>
          {job && (
            <>
              {_.map(data, (datum) => (
                <DatumRow key={datum.label} {...datum} />
              ))}
              <WppButton variant="secondary" size="s" onClick={handleDetailsModalOpen} style={detailsButtonMargin}>Details</WppButton>
              <WppModal
                size="m"
                open={detailsModalOpen}
                onWppModalClose={handleDetailsModalClose}
              >
                <WppTypography type="xl-heading" slot="header">Job Details for {job.type}</WppTypography>
                <WppTypography type="s-body" slot="body">{job.statusDetail}</WppTypography>
                <div slot="actions" style={modalButtonAlignment}>
                  <WppButton variant="primary" size="s" onClick={handleDetailsModalClose}>Done</WppButton>
                </div>
              </WppModal>
            </>
          )}
        </WppGrid>
        <WppGrid item all={24}>
          {!_.isEmpty(job) && _.map(TASK_KEYS, (key) => (job[key] ? <TaskCard key={key} type={key} task={job[key]} /> : null))}
        </WppGrid>
      </WppGrid>
    </WppPermissionPageTemplate>
  );
};

export default JobDetail;
