import { COPILOT_LAYOUT } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;

export const memberLabel = {
  marginRight: '5%',
  fontWeight: '400',
};

export const tableHeader = {
  backgroundColor: '#fff',
};

export const noBorderRadius = {
  borderRadius: 0,
};

export const jobsTable = {
  marginTop: '1%',
  borderRadius: 0,
};

export const timeZoneDropDown = {
  width: '171px',
  display: 'inline-block',
};

export const filtersRow = {
  display: 'flex',
  gap: SPACING[16],
  marginBottom: SPACING[20],
};

export const filtersButtonRow = {
  display: 'flex',
  gap: SPACING[16],
  justifyContent: 'flex-end',
};

export const containerLayout = {
  marginBottom: '0px',
};
