import React from 'react';
import { TextareaInputChangeEventDetail } from '@wppopen/components-library';
import { WppTextareaInputCustomEvent } from '@wppopen/components-library/dist/types/components';
import { Form, WppGrid, WppTextareaInput } from 'buildingBlocks';
import RequestAccessButton from './RequestAccessButton';
import { userFormRowPadding } from '../style';

const REQUEST_SECTION_TEXT_MAX_LENGTH = 280;

type RequestAccessSectionProps = {
  showRequestNotes: boolean
  showRequestAccessButton: boolean
  onChangeRequestNotes: (e: WppTextareaInputCustomEvent<TextareaInputChangeEventDetail>) => void
  handleAccessRequestSubmit: (e: React.MouseEvent<HTMLWppButtonElement>) => void
  approver: string
  requestNotes: string
};

const RequestAccessSection = ({
  requestNotes, showRequestNotes, showRequestAccessButton,
  onChangeRequestNotes, handleAccessRequestSubmit, approver,
}: RequestAccessSectionProps) => (
  <>
    {showRequestNotes && (
      <WppGrid container fullWidth>
        <WppGrid item all={12} style={userFormRowPadding}>
          <Form.Field>
            <WppTextareaInput
              name="requestNotes"
              value={requestNotes}
              onWppChange={onChangeRequestNotes}
              maxMessageLength={REQUEST_SECTION_TEXT_MAX_LENGTH}
              labelConfig={{
                text: 'Request Notes',
              }}
              required
            />
          </Form.Field>
        </WppGrid>
      </WppGrid>
    )}
    {showRequestAccessButton && (
      <RequestAccessButton disabled={!approver} handleAccessRequestSubmit={handleAccessRequestSubmit} />
    )}
  </>
);

export default RequestAccessSection;
