import _ from 'lodash';
import React from 'react';
import { WppGrid, WppTable, WppTableHeader, WppTableHeaderRow, WppTableHeaderCell, WppTypography } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import type { TimeZoneValue } from 'containers/Jobs/types';
import { Range } from 'utils/types';
import { COLUMNS } from 'containers/Jobs/constants';
import type { Router } from 'utils/withRouter';
import { containerLayout } from './style';
import Body from './Body';
import Pagination from './Pagination';

interface DataTableProps {
  router: Router
  selectedTimezone: TimeZoneValue
  setRangeDispatch: (range: Range) => void
}

const DataTable = (props: DataTableProps) => (
  <WppGrid container fullWidth style={{ ...wppBodyContainer, ...containerLayout }}>
    <WppGrid item all={24}>
      <WppTable>
        <WppTableHeader>
          <WppTableHeaderRow>
            {_.map(COLUMNS, (column) => (
              <WppTableHeaderCell
                key={column.value}
              >
                <WppTypography tag="p" type="s-strong">{column.text}</WppTypography>
              </WppTableHeaderCell>
            ))}
          </WppTableHeaderRow>
        </WppTableHeader>
        <Body {...props} />
      </WppTable>
    </WppGrid>
    <WppGrid item all={24}>
      <Pagination setRangeDispatch={props.setRangeDispatch} />
    </WppGrid>
  </WppGrid>
);

export default DataTable;
export {
  Pagination,
  Body,
};
