import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { WppBreadcrumb, WppIconChevron, WppGrid } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import ErrorBoundary from 'components/ErrorBoundary';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { Permission } from 'utils/featureFlags';
import { Router } from 'utils/withRouter';
import { StrategyConfig } from 'utils/types';
import { QSParams, StrategyWizardResponse } from '../hooks';
import StrategyWizardHeader from './StrategyWizardHeader';
import { PAGE_NAME } from '../StrategyWizard';
import CreateNewStrategyRoutes from './CreateNewStrategyRoutes';
import EditExistingStrategyRoutes from './EditExistingStrategyRoutes';
import StrategyWizardProgress from './StrategyWizardProgress';
import StrategyWizardRedirector from './StrategyWizardRedirector';
import { CLEAR } from '../constants';
import { AttachFlightsInfoType, WizardFormValues } from '../types';
import { getValidatorPerStrategyType } from '../steps/StrategyConfiguration/validate';
import { getInitialWizardFormFieldsUIValues } from '../utils';
import ConfirmationPopup from './ConfirmationPopup';
import { useStrategyWizardContext } from '../contexts/StrategyWizardProvider';

const REQUIRED_PERMISSIONS = [Permission.accessStrategyWizard];

type Props = {
  step: number
  wizardFormValues: WizardFormValues
  qsParams: QSParams
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  strategyTypeId: number | undefined
  strategyConfig?: StrategyConfig
  strategyId?: number
  strategyState: StrategyWizardResponse
  canAccessCrossPlatform: boolean
  router: Router
};

const StrategyWizardRoutes = (props: Props) => {
  const {
    wizardFormValues,
    strategyId,
    step,
    strategyConfig,
    qsParams,
    attachFlightsInfo,
    setAttachFlightsInfo,
    strategyState,
    canAccessCrossPlatform,
    router,
    strategyTypeId,
  } = props;
  const { location } = router;
  const { dispatch } = useStrategyWizardContext();
  const strategyName = _.get(wizardFormValues, 'strategyConfirmationStep.name') as string;
  const wizardFormValuesForRedirector = (_.get(wizardFormValues, 'attachFlightsStep.member') || _.get(wizardFormValues, 'attachFlightsStep.brand')) ? wizardFormValues : getInitialWizardFormFieldsUIValues(strategyState) as WizardFormValues;
  const moduleFormValues = _.get(wizardFormValues, 'strategyConfigurationStep');
  // check if previously saved values have form errors
  const formErrors = (strategyTypeId && strategyId && !_.isNil(moduleFormValues)) ? _.get(getValidatorPerStrategyType(strategyTypeId)(moduleFormValues), 'errors') : {};
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const getFormErrors = (errors: {}) => {
    if (_.isEmpty(errors)) {
      return false;
    }
    return true;
  };
  const hasFormErrors = getFormErrors(formErrors);

  // ensure the module form state and attachFlights state are cleared when navigating away from the wizard
  useEffect(() => {
    if (!location.pathname.includes('wizard')) {
      dispatch({ type: CLEAR });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const hasWizardFormValues = !_.isEmpty(wizardFormValues);
  const cancelBtnPath = document.referrer === `${window.location.origin}/strategies/${strategyId}` ? `/strategies/${strategyId}` : '/';
  return (
    <ErrorBoundary>
      {hasWizardFormValues && (
        <StrategyWizardRedirector
          strategyId={strategyId}
          step={step}
          wizardFormValues={wizardFormValuesForRedirector}
          hasFormErrors={hasFormErrors}
        >
          <WppPermissionPageTemplate
            name={PAGE_NAME}
            permissions={REQUIRED_PERMISSIONS}
            allPermissions={false}
            customHeader={(
              <div>
                <div style={strategyWizardStyles.breadCrumContainer}>
                  <WppIconChevron direction="left" size="s" />
                  <WppBreadcrumb
                    items={[{
                      label: 'Back',
                      path: '/',
                    }]}
                    onWppChange={() => {
                      togglePopup();
                    }}
                    style={strategyWizardStyles.breadCrumBackLink}
                  />
                </div>
                <StrategyWizardHeader strategyName={strategyName} isNewStrategy={!strategyId} />
              </div>
            )}
            withoutLayout
            member={_.get(wizardFormValues, 'member')}
          >
            <WppGrid container fullWidth all={24}>
              <WppGrid item all={3}>
                <StrategyWizardProgress
                  step={step}
                  wizardFormValues={wizardFormValues}
                  router={router}
                />
              </WppGrid>
              <WppGrid item all={21}>
                {
                  !strategyId
                    ? (
                      <CreateNewStrategyRoutes
                        qsParams={qsParams}
                        attachFlightsInfo={attachFlightsInfo}
                        setAttachFlightsInfo={setAttachFlightsInfo}
                        strategyState={strategyState}
                        canAccessCrossPlatform={canAccessCrossPlatform}
                        router={router}
                      />
                    )
                    : (
                      <EditExistingStrategyRoutes
                        strategyConfig={strategyConfig}
                        strategyId={strategyId}
                        qsParams={qsParams}
                        strategyTypeId={strategyTypeId}
                        attachFlightsInfo={attachFlightsInfo}
                        setAttachFlightsInfo={setAttachFlightsInfo}
                        strategyState={strategyState}
                        canAccessCrossPlatform={canAccessCrossPlatform}
                        router={router}
                      />
                    )
                }
              </WppGrid>
            </WppGrid>
            <ConfirmationPopup
              isOpen={isOpen}
              togglePopup={togglePopup}
              cancelBtnPath={cancelBtnPath}
            />
          </WppPermissionPageTemplate>
        </StrategyWizardRedirector>
      )}
    </ErrorBoundary>
  );
};

export default StrategyWizardRoutes;
