import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import Dropzone, { ImageFile } from 'react-dropzone';
import { WppGrid, WppDivider, WppIconFile, WppTypography, WppButton, WppInlineMessage } from 'buildingBlocks';
import dropzoneStyle from 'components/BrowseDragDropPasteInputTextZone/style';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import FormSectionHeader from 'components/FormSectionHeader';
import { STRATEGY_TYPE } from 'constantsBase';
import { generateBidLists, generateFourSpaceJsonString, handleBidListValidation } from 'containers/StrategyWizard/ConfigurationByStrategyType/CustomBidList/utils';
import { useStrategyWizardContext } from 'containers/StrategyWizard/contexts/StrategyWizardProvider';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { CustomBidListForm } from 'containers/StrategyWizard/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import {
  ACCEPTED_TYPES,
  DEFAULT_BID_LIST_STRING,
  DEFAULT_VC_BID_LIST_STRING,
  BidListStatus,
  RESET_CSV_VALIDATION,
  PARSE_JSON_FAILED,
  CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS,
  SUCCESSFULLY_PARSED_CSV_DATA,
  PARSE_CSV_DATA_FAILED,
  BidList,
  INITIALIZE_EXISTING_STRATEGY,
  BID_LIST_CSV_INPUT,
  BID_LIST_STRING,
  VOLUME_CONTROL_BID_LIST_STRING,
  VALIDATION_ERROR,
  PageStatus,
  BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED,
  RESET_BID_LISTS_VOLUME_CONTROL_STATUS,
  VALIDATE_BID_LISTS_VOLUME_CONTROL,
  VolumeControlStatus,
} from './constants';
import { INITIAL_STATE, reducer, TranslationErrorsByField } from './context';
import Tabs from './Tabs';

const CustomBidList = () => {
  const [reader, setReader] = useState<FileReader>(new FileReader());
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {
    pageStatus,
    cachedCsvInputsAndRelatedBidLists,
    csvValidationError,
    bidListErrors,
    volumeControlErrors,
    noBidListGeneratedWarning,
    noVcBidListGeneratedWarning,
    isExistingStrategy,
  } = state;
  const [translationErrorsByField, setTranslationErrorsByField] = useState<TranslationErrorsByField>({});

  const {
    wizardFormValues: {
      attachFlightsStep: { member, advertiser },
      strategyConfigurationStep,
    },
  } = useStrategyWizardContext();
  const initialValues = strategyConfigurationStep as CustomBidListForm;
  const bidListCsvInput = useWatch({ name: BID_LIST_CSV_INPUT });
  const bidListString = useWatch({ name: BID_LIST_STRING });
  const volumeControlBidListString = useWatch({ name: VOLUME_CONTROL_BID_LIST_STRING });
  const pageValidation = useWatch({ name: 'pageValidator' });
  const bidListErrorsArray = useWatch({ name: 'bidListErrorsArray' });
  const volumeControlErrorsArray = useWatch({ name: 'volumeControlErrorsArray' });
  const treeFileInput = useWatch({ name: 'bidListCsvInput' });
  const csvInputValue = !!treeFileInput;
  const { control, setValue, trigger } = useFormContext();

  const bidListStatus = _.get(pageStatus, 'bidListStatus');
  const isBidListValidating = bidListStatus === BidListStatus.BidListsValidating;
  const isBidListGenerating = pageStatus.bidListStatus === PageStatus.BidListsGenerating;

  useMount(() => {
    if (_.get(initialValues, BID_LIST_CSV_INPUT)) {
      const bidLists = _.trim(initialValues.bidListString);
      const volumeControls = _.trim(initialValues.volumeControlBidListString);
      if (bidLists !== DEFAULT_BID_LIST_STRING || volumeControls !== DEFAULT_VC_BID_LIST_STRING) {
        const trimmedCsvInput = _.trim(initialValues.bidListCsvInput);
        dispatch({
          type: INITIALIZE_EXISTING_STRATEGY,
          payload: { bidListString: bidLists, volumeControlBidListString: volumeControls, trimmedCsvInput },
        });
      }
    }
  });

  useEffect(() => {
    // setting up values after initial render when user has already saved custom bid list values
    if (_.get(initialValues, BID_LIST_CSV_INPUT) && isExistingStrategy) {
      setValue(BID_LIST_CSV_INPUT, _.trim(initialValues.bidListCsvInput));
      setValue(BID_LIST_STRING, _.trim(initialValues.bidListString));
      setValue(VOLUME_CONTROL_BID_LIST_STRING, _.trim(initialValues.volumeControlBidListString));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExistingStrategy]);

  useEffect(() => {
    if (!reader) {
      setReader(new FileReader());
    }
  }, [reader]);

  useEffect(() => {
    if (bidListCsvInput === '') {
      dispatch({ type: RESET_BID_LISTS_VOLUME_CONTROL_STATUS });
    } else {
      const cachedBidListsObject = cachedCsvInputsAndRelatedBidLists.orderedMap.get(bidListCsvInput);

      if (cachedBidListsObject) {
        const cachedBidListString = cachedBidListsObject.bidListString;
        const cachedVolumeControlBidListString = cachedBidListsObject.volumeControlBidListString;

        setValue(BID_LIST_STRING, cachedBidListString);
        setValue(VOLUME_CONTROL_BID_LIST_STRING, cachedVolumeControlBidListString);

        dispatch({
          type: CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS,
          payload: { bidListString: cachedBidListString, volumeControlBidListString: cachedVolumeControlBidListString },
        });
      } else {
        setValue(BID_LIST_STRING, '');
        setValue(VOLUME_CONTROL_BID_LIST_STRING, '');
        dispatch({ type: RESET_BID_LISTS_VOLUME_CONTROL_STATUS });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidListCsvInput]);

  useEffect(() => {
    setValue('pageValidator', {
      bidListStatus: pageStatus.bidListStatus,
      volumeControlStatus: pageStatus.volumeControlStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageStatus]);

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValidation, bidListErrorsArray, volumeControlErrorsArray]);

  useEffect(() => {
    setValue('bidListErrorsArray', bidListErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidListErrors]);

  useEffect(() => {
    setValue('volumeControlErrorsArray', volumeControlErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeControlErrors]);

  const onDrop = (accepted: Array<ImageFile>) => {
    const file = _.first(accepted);
    if (_.isEmpty(accepted) || ACCEPTED_TYPES.has(file.type)) {
      reader.onload = () => {
        const resultString = reader.result;
        setValue(BID_LIST_CSV_INPUT, resultString as string);

        dispatch({ type: RESET_CSV_VALIDATION });
      };
    }

    reader.onabort = () => console.log('file reading was aborted'); // eslint-disable-line no-console
    reader.onerror = () => console.log('file reading has failed'); // eslint-disable-line no-console
    reader.readAsText(file, 'UTF-8');
  };

  const generateBidList = async () => {
    const trimmedCsvInput = _.trim(bidListCsvInput);
    const memberExtId = member.externalId;
    const advertiserExtId = advertiser.externalId;

    try {
      const bidListResp = await generateBidLists(trimmedCsvInput, memberExtId, advertiserExtId);
      const {
        bid_list: bidList,
        volume_control_bid_list: volumeControlBidList,
        translation_errors_by_field: translationErrors,
      } = bidListResp;

      if (bidList.length === 0 || volumeControlBidList.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw { message: 'There was an issue parsing the Bid List file' };
      }

      setValue(BID_LIST_CSV_INPUT, trimmedCsvInput);
      setValue(BID_LIST_STRING, generateFourSpaceJsonString(bidList));
      setValue(VOLUME_CONTROL_BID_LIST_STRING, generateFourSpaceJsonString(volumeControlBidList));
      setTranslationErrorsByField(translationErrors);

      dispatch({
        type: SUCCESSFULLY_PARSED_CSV_DATA,
        payload: { trimmedCsvInput, bidListString, volumeControlBidListString, translationErrorsByField },
      });
    } catch (err) {
      console.log({ err }); // eslint-disable-line no-console
      const errorMessage = _.get(err, 'message', 'Error generating bid list');
      dispatch({
        type: PARSE_CSV_DATA_FAILED,
        payload: { csvValidationError: errorMessage },
      });
    }
  };

  const onBidListChange = (e) => {
    setValue(BID_LIST_STRING, e);

    dispatch({
      type: CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS,
      payload: { bidListString: e, volumeControlBidListString },
    });
  };

  const onVolumeControlBidListChange = (e) => {
    setValue(VOLUME_CONTROL_BID_LIST_STRING, e);

    dispatch({
      type: CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS,
      payload: { bidListString, volumeControlBidListString: e },
    });
  };

  const onValidateBidListClick = async () => {
    dispatch({
      type: VALIDATE_BID_LISTS_VOLUME_CONTROL,
      payload: null,
    });

    const memberExtId = member.externalId;
    const advertiserExtId = advertiser.externalId;

    const errors = { bidListJsonParseError: null, volumeControlJsonParseError: null };
    let bidList: null | BidList;
    let volumeControlBidList: null | BidList;

    try {
      bidList = JSON.parse(bidListString);
    } catch (e) {
      errors.bidListJsonParseError = `Error parsing Bid List: ${e.message}`;
    }

    try {
      volumeControlBidList = JSON.parse(volumeControlBidListString);
    } catch (e) {
      errors.volumeControlJsonParseError = `Error parsing Volume Control Bid List: ${e.message}`;
    }

    if (_.every(errors, _.isNil)) {
      try {
        const validationResponsePayload = await handleBidListValidation({
          payload: {
            bidList,
            bidListString,
            volumeControlBidList,
            volumeControlBidListString,
            memberExtId,
            advertiserExtId,
          },
        });

        dispatch({
          type: BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED,
          payload: validationResponsePayload,
        });
      } catch (err) {
        console.log({ err }); // eslint-disable-line no-console
        const errorMessage = _.get(err, 'message', 'Error validating bid list(s).');
        dispatch({
          type: VALIDATION_ERROR,
          payload: { bidListValidationError: errorMessage },
        });
      }
    } else {
      if (!errors.bidListJsonParseError && errors.volumeControlJsonParseError) {
        errors.bidListJsonParseError = 'Not yet verified. Please address the Volume Control errors.';
      }
      if (!errors.volumeControlJsonParseError && errors.bidListJsonParseError) {
        errors.volumeControlJsonParseError = 'Not yet verified. Please address the Bid List errors.';
      }

      dispatch({
        type: PARSE_JSON_FAILED,
        payload: {
          bidListJsonParseError: errors.bidListJsonParseError,
          volumeControlJsonParseError: errors.volumeControlJsonParseError,
        },
      });
    }
  };

  const displayName = STRATEGY_TYPE.customBidList.displayName;
  const hasCsvInputText = !!bidListCsvInput;

  return (
    <WppGrid container fullWidth style={strategyWizardStyles.containerStyle}>
      <WppGrid item all={11}>
        <FormSectionHeader
          title="Bid List File"
          help="Upload a CSV file specifying the desired feature and bid factor combinations"
          group={displayName}
          bottom={16}
        />
        <div style={{ position: 'relative' }}>
          <Controller
            name="bidListCsvInput"
            control={control}
            render={({ field }) => (
              <CustomTextArea
                name="bidListCsvInput"
                disabled={(
                  pageStatus.bidListStatus === PageStatus.BidListsGenerating
                  || pageStatus.volumeControlStatus === PageStatus.VolumeControlGenerating
                  || pageStatus.bidListStatus === BidListStatus.BidListsValidating
                  || pageStatus.volumeControlStatus === VolumeControlStatus.VolumeControlValidating
                )}
                onChange={field.onChange}
                height={544}
                mode="python"
                csvInputValue={csvInputValue}
                {..._.omit(field, 'ref')}
              />
            )}
          />
          <Dropzone
            onDrop={onDrop}
            multiple={false}
            style={hasCsvInputText
              ? { ...dropzoneStyle.container, border: 0, opacity: 0 }
              : { ...dropzoneStyle.container, opacity: 1 }}
          >
            <div style={dropzoneStyle.dropZoneUpload}>
              <WppIconFile slot="icon-start" />
              <WppTypography tag="p" type="xs-body">
                <WppTypography tag="span"><a style={dropzoneStyle.browseText}>Choose a file</a></WppTypography> to upload or drag it here
              </WppTypography>
              <WppTypography type="xs-body">
                Alternatively, type or paste into this field
              </WppTypography>
            </div>
          </Dropzone>
        </div>
        <div style={strategyWizardStyles.configureCustomStyle}>
          <Controller
            name="generateBidLists"
            control={control}
            render={({ field, ...restFormProps }) => (
              <WppButton
                size="m"
                variant="secondary"
                className="custom-configure-tree-btn"
                loading={isBidListGenerating}
                onClick={generateBidList}
                {..._.omit({ ...field, ...restFormProps }, ['formState', 'fieldState'])}
                disabled={isBidListValidating || !hasCsvInputText}
                style={isBidListValidating ? { ...strategyWizardStyles.customTreeBtn, pointerEvents: 'none' } : strategyWizardStyles.customTreeBtn}
              >
                Generate Bid Lists
              </WppButton>
            )}
          />
        </div>
        {csvValidationError && <WppInlineMessage size="s" message={csvValidationError} type="error" />}
      </WppGrid>
      <WppGrid
        item
        all={2}
        style={strategyWizardStyles.customVerticleDivider}
      >
        <WppDivider />
      </WppGrid>
      <WppGrid item all={11}>
        <FormSectionHeader
          title="Bid List Json"
          help="View the Bid List JSON before validation"
          group={displayName}
          bottom={16}
        />
        <Tabs
          onValidateBidListClick={onValidateBidListClick}
          onBidListChange={onBidListChange}
          onVolumeControlBidListChange={onVolumeControlBidListChange}
          bidListString={bidListString || DEFAULT_BID_LIST_STRING}
          volumeControlBidListString={volumeControlBidListString || DEFAULT_VC_BID_LIST_STRING}
          pageStatus={pageStatus}
          bidListErrors={bidListErrors}
          volumeControlErrors={volumeControlErrors}
          noBidListGeneratedWarning={noBidListGeneratedWarning}
          noVcBidListGeneratedWarning={noVcBidListGeneratedWarning}
          translationErrorsByField={translationErrorsByField}
        />
      </WppGrid>
    </WppGrid>
  );
};
export default CustomBidList;
