import _ from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, TextArea, WppTypography, WppGrid, WppInlineMessage } from 'buildingBlocks';
import { FlightSyncForm, MAX_EXTERNAL_IDS_ALLOWED } from '../constants';
import FLIGHT_SYNC_STYLES from '../styles';

const { idFieldForm, idField, uploadMessage } = FLIGHT_SYNC_STYLES;

type ExternalIdsFieldProps = {
  textArea: string
  setTextArea: (x: string) => void
};

const ExternalIdsField = (props: ExternalIdsFieldProps) => {
  const { textArea, setTextArea } = props;
  const { control } = useFormContext<FlightSyncForm>();

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>, onChange: Function) => {
    const textField = e.target.value;
    // only allow numbers, spaces, and commas
    const validInput = textField.replace(/[^0-9,\s]/, '');
    setTextArea(validInput);
    const inputIds = _.filter(_.split(validInput, /\W+/), _.negate(_.isEmpty));
    onChange(inputIds);
  };

  return (
    <WppGrid container fullWidth>
      <WppGrid item all={24}>
        <WppTypography type="s-strong" style={FLIGHT_SYNC_STYLES.labelStyles}>External IDs</WppTypography>
        <Controller
          name="externalIds"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <Form style={idFieldForm}>
              <TextArea
                rows={2}
                placeholder="Enter insertion order external IDs delineated by a space or comma. (ie: io_id_1, io_id_2, etc.)"
                style={idField}
                value={textArea}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onTextChange(e, onChange)}
              />
            </Form>
          )}
        />
        <p style={uploadMessage}>
          <WppInlineMessage
            size="s"
            message={`Only ${MAX_EXTERNAL_IDS_ALLOWED} IDs are allowed to be synced at a time. If you need to attach more please work in batches.`}
            type="information"
          />
        </p>
      </WppGrid>
    </WppGrid>
  );
};

export default ExternalIdsField;
